<template>
  <div class="product-price price">
    <price-display :prices="prices" />
    <span
      v-if="hasNote"
      class="product-price__tax-note product-price__tax-note_aud gst-note gst-note--aud"
    >
      Incl. GST
    </span>
  </div>
</template>

<script>
import PriceDisplay from '~/components/prices/price-display';

export default {
  components: {
    PriceDisplay,
  },

  props: {
    prices: {
      type: Array,
      required: true,
    },
    showTaxNote: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasNote() {
      return this.showTaxNote && this.$store.getters['currency/currencyHasTaxNote'];
    },
  },
};
</script>
