export default class Helpers {
  slugify(text) {
    return text.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
  }

  getDocumentWidth() {
    var max = 0;
    try {
      max = Math.max(document.documentElement["clientWidth"], document.body["scrollWidth"], document.documentElement["scrollWidth"], document.body["offsetWidth"], document.documentElement["offsetWidth"]);
    } catch(e) {}
    return max; 
  }

  getDocumentHeight() {
    var max = 0;
    try {
      max = Math.max(document.documentElement["clientHeight"], document.body["scrollHeight"], document.documentElement["scrollHeight"], document.body["offsetHeight"], document.documentElement["offsetHeight"]);
    } catch(e) {}
    return max; 
  }

  // getDocumentWidth() {
  //   var doc = window.document;
  //   var selects = [
  //     doc.documentElement["clientWidth"], 
  //     doc.documentElement["scrollWidth"], 
  //     doc.documentElement["offsetWidth"],
  //   ];

  //   if(!!doc.body) {
  //     selects = selects.concat([
  //       doc.body["scrollWidth"], 
  //       doc.body["offsetWidth"], 
  //     ])
  //   }

  //   return Math.max(...selects);
  // }

  // getDocumentHeight() {
  //   var doc = window.document;
  //   var selects = [
  //     doc.documentElement["clientHeight"], 
  //     doc.documentElement["scrollHeight"], 
  //     doc.documentElement["offsetHeight"],
  //   ];

  //   if(!!doc.body) {
  //     selects = selects.concat([
  //       doc.body["scrollHeight"], 
  //       doc.body["offsetHeight"], 
  //     ])
  //   }

  //   return Math.max(...selects);
  // }
} 