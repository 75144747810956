export default {
  formatCups:(state) => {
    if(!state.sizing.band) return [];

    var cups = _.chain(state.sizing.all_cups)
      .filter((v) => { 
        return v.band[0] == parseInt(state.sizing.band) || v.band[1] == parseInt(state.sizing.band)
      })
      .map((v) => {
        return {
          size:v.size,
          measurements:v[state.sizing.system]
        }
      })
      .value();

    return cups;
  },
  showNextClass: (state) => {
    var active = (state.sizing.step < 3);

    if(state.sizing.step == 1 && !state.sizing.band) active = false;
    if(state.sizing.step == 2 && !state.sizing.cup) active = false;

    return active ? 'active' : '';
  },
  showPrevClass: (state) => {
    var active = (state.sizing.step > 0);

    return active ? 'active' : '';
  },
  showFit: (state) => {
    return `${state.sizing.band}${state.sizing.cup}`;
  },
  showLink: (state,view) => {
    var fit = view.sizing.showFit(state);
    return `/products?keywords=${fit}`;
  },
  showClass: (state) => {
    return (state.sizing.step == 3) ? 'content-result' : '';
  }
}