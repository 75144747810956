import Base from '../base';

export default class Default extends Base {
  init() {
    if(this.d.body_id !== 'checkout-complete') return;
    radio('vue/loaded').subscribe(() => { this.setup(); });
  }

  setup() {
    var url        = '/feed/instagram'

    //Call url
    $.ajax({
      url : url,
      data : {},
      dataType : 'json',
      type : 'GET',
      success : (data) => {
        var media = _.chain(data.user.media.nodes)
        .slice(0,3)
        .map((v) => {
          return {
            dimensions: v.dimensions,
            image: v.display_src,
            caption: v.caption,
            url: '//instagram.com/p/' + v.code,
            date: moment.unix(v.date).format('MMMM D')
          }
        })
        .value();

        this.s.app.instagram = _.slice(media, 0, 1);
      },
      error : (err) => {
        console.log(err);
      }
    });
  }
} 