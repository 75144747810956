var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.component,{tag:"component",staticClass:"ProductAdd",class:{
    adding: _vm.adding,
    available: _vm.available,
    preorder: _vm.preorder,
    error: _vm.error,
    unpurchasable: _vm.unpurchasable,
  },attrs:{"type":"submit"}},[_c('transition',{attrs:{"name":_vm.textTransitionName,"mode":_vm.textTransitionMode}},[_c('span',{key:_vm.text},[_vm._v(_vm._s(_vm.text))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }