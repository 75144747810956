import _ from 'lodash'
import radio from 'radio'
import Base from '../base'

function formatAddressErrors(errorsObject) {
  // Keys in frontend don't match
  const dictionary = {
    bill_address: 'address_billing',
    ship_address: 'address_shipping',
    id: 'id',
    firstname: 'first_name',
    lastname: 'last_name',
    address1: 'address1',
    address2: 'address2',
    state: 'region',
    city: 'city',
    country_id: 'country',
    zipcode: 'zip',
    phone: 'phone',
  }

  return Object.keys(errorsObject).map((key) => {
    const [section, field] = key.split('.').map((k) => dictionary[k] || k)
    const errors = errorsObject[key]

    return { section, field, errors }
  })
}

export default class Default extends Base {
  init() {
    if (this.d.body_id !== 'checkout-edit') return

    // Easy access to cart utility
    this.checkout = this.u.spree.checkout
    this.payment_validations = this.u.checkout_validations.payment

    radio('vue/loaded').subscribe(() => {
      this.setup()
    })

    radio('checkout/goto').subscribe((d) => {
      this.goto(d)
    })
    radio('checkout/address/submit').subscribe(() => {
      this.setAddress()
    })
    radio('checkout/delivery/submit').subscribe(() => {
      this.setDelivery()
    })
    radio('checkout/payment/submit').subscribe(() => {
      this.setPayment()
    })
    radio('checkout/payment/paypal').subscribe((d) => {
      this.setPaypal(d)
    })
    radio('checkout/payment/afterpay').subscribe((d) => {
      this.setAfterpay(d)
    })
    radio('checkout/payment/validate').subscribe((d) =>
      this.ccPotentialValidation(d)
    )
    radio('checkout/confirm/submit').subscribe(() => {
      this.confirm()
    })
    radio('checkout/onUpdate').subscribe((d) => {
      this.onUpdate(d)
    })
  }

  goto(d) {
    // Lets us go back and forth without changing anything,
    // but not forward past the current step
    const cur_state_idx = this.d.order.checkout_steps.indexOf(
      this.d.order.state
    )
    const new_state_idx = this.d.order.checkout_steps.indexOf(d.state)

    if (new_state_idx < 0) return
    if (new_state_idx > cur_state_idx) return

    this.s.checkout.loading = true
    this.s.checkout.state = d.state

    setTimeout(() => {
      this.s.checkout.loading = false
    }, 1000)
  }

  setup() {
    // Load countries and states
    this.loadCountries()
    this.loadStates()
    this.loadOrder()
  }

  loadCountries() {
    this.checkout.countries({
      success: (d) => {
        this.d.countries = d.countries
      },
    })
  }

  loadStates() {
    this.checkout.states({
      success: (d) => {
        this.d.states = d.states
      },
    })
  }

  loadOrder() {
    this.checkout.order({
      success: (d) => {
        radio('checkout/onUpdate').broadcast({ success: true, order: d })
      },
    })
  }

  getAddresses() {
    const { bill_address } = this.d.order
    const { ship_address } = this.d.order
    if (bill_address) this.getAddress(bill_address, 'billing')
    if (ship_address) this.getAddress(ship_address, 'shipping')
  }

  getAddress(address, type) {
    const address_state = this.s.checkout[`address_${type}`]

    const t = {
      id: 'id',
      firstname: 'first_name',
      lastname: 'last_name',
      address1: 'address1',
      address2: 'address2',
      city: 'city',
      country_id: 'country',
      zipcode: 'zip',
      phone: 'phone',
    }

    _.each(address, (v, k) => {
      // Todo: Translate to from names of each attribute with function
      const key = t[k]

      if (address_state[key]) address_state[key][0] = v
    })

    if (address.state_id) {
      address_state.region[0] = address.state_id
    } else {
      address_state.region[0] = address.state_name
    }
  }

  getShipments() {
    // See if shipments exists on the order
    if (!this.d.order.shipments || this.d.order.shipments.length == 0) return

    // If it does, create shipping_rates in state
    // Then, select first shipping rate by default
    _.each(this.d.order.shipments, (s, s_idx) => {
      if (s.shipping_rates.length === 0) return
      const sr =
        s.shipping_rates.find((rate) => rate.selected) || s.shipping_rates[0]

      this.s.checkout.shipping_rates[s_idx] = [s.id, sr.id]
    })
  }

  getGiftwrapped() {
    this.s.checkout.giftwrapped = !!this.d.order.giftwrapped
  }

  getPaymentMethods() {
    // See if payment methods exists on the order
    if (!this.d.order.payment_methods) return
    const available_methods = this.v.checkout.formatPaymentMethods(
      this.d,
      this.s,
      this.v
    )
    if (available_methods.length == 0) return

    // Preselect the first one
    this.s.checkout.selected_payment_type = available_methods[0].method_type
  }

  getOrderDefaults() {
    return _.clone({
      id: this.d.cart.number,
      user_id: this.d.cart.user_id,
      order_token: this.d.cart.token,
      order: {
        currency: this.d.cart.currency,
      },
    })
  }

  getPaymentDefaults() {
    return _.clone({
      id: this.d.cart.number,
      user_id: this.d.cart.user_id,
      order_token: this.d.cart.token,
      order: {
        payments_attributes: [],
        currency: this.d.cart.currency,
      },
    })
  }

  setAddress() {
    this.s.checkout.loading = true
    const { getStates } = this.v.checkout // (data, state, name)
    const shipping_states = getStates(this.d, this.s, 'shipping')
    const billing_states = getStates(this.d, this.s, 'billing')
    const data = this.getOrderDefaults()

    data.order = _.merge(data.order, {
      ship_address_attributes: this.formatAddress(
        this.s.checkout.address_shipping,
        shipping_states
      ),
      bill_address_attributes: this.formatAddress(
        this.s.checkout.address_billing,
        billing_states
      ),
      use_billing: 0,
    })

    // Copy shipping address to billing if use shipping is checked
    if (this.s.checkout.shipping_as_billing) {
      data.order.bill_address_attributes = data.order.ship_address_attributes
    }

    this.checkout.address({
      data,
      success: (d) => {
        radio('checkout/onUpdate').broadcast({ success: true, order: d })
      },
      error: (d) => {
        const { errors } = d.responseJSON
        const source = errors.base ? 'base' : 'address'
        this.assignAddressErrors(errors)
        radio('checkout/onUpdate').broadcast({
          success: false,
          errors,
          source,
        })
      },
    })
  }

  assignAddressErrors(errorsObject) {
    const formatted_errors = formatAddressErrors(errorsObject)
    formatted_errors.forEach(({ section, field, errors }) => {
      if (!section || !field) return
      this.s.checkout[section][field][1] = errors
    })
  }

  formatAddress(address_object, states_object) {
    const r = {
      first_name: address_object.first_name[0],
      last_name: address_object.last_name[0],
      address1: address_object.address1[0],
      address2: address_object.address2[0],
      city: address_object.city[0],
      country_id: address_object.country[0],
      zipcode: address_object.zip[0],
      phone: address_object.phone[0],
      // id: address_object.id,
    }

    // if(address_object.id[0]) r.id = address_object.id[0];

    if (states_object.length > 0) {
      r.state_id = address_object.region[0]
    } else {
      r.state_name = address_object.region[0]
    }

    return r
  }

  setDelivery() {
    this.s.checkout.loading = true

    const data = this.getOrderDefaults()
    data.order = {
      shipments_attributes: [],
      giftwrapped: false,
    }

    // Select shipment ids
    _.each(this.s.checkout.shipping_rates, (sr) => {
      const shipment_id = sr[0]
      const rate_id = sr[1]

      data.order.shipments_attributes.push({
        selected_shipping_rate_id: rate_id,
        id: shipment_id,
      })
    })

    // Select giftwrapped
    data.order.giftwrapped = this.s.checkout.giftwrapped

    this.checkout.delivery({
      data,
      success: (d) => {
        this.d.cart = d
        radio('checkout/onUpdate').broadcast({ success: true, order: d })
      },
      error: (d) => {
        const { errors } = d.responseJSON
        radio('checkout/onUpdate').broadcast({ success: false, errors })
      },
    })
  }

  setCoupon() {
    const data = this.getOrderDefaults()
    const couponInput = document.querySelector('input[name="coupon"]')

    data.coupon_code = this.s.checkout.coupon_code[0]

    // Disable applying coupon if there are already payments
    const payments = _.filter(this.d.order.payments, (v) => {
      return v.state === 'checkout' || v.state === 'completed'
    })
    if (payments.length) {
      radio('checkout/onUpdate').broadcast({
        success: false,
        error: 'Sorry you cannot apply a coupon after a partial payment',
      })
    }

    this.checkout.coupon({
      data,
      success: (d) => {
        this.s.checkout.coupon_code[0] = ''
        this.s.checkout.coupon_code[1] = 'Coupon code successfully applied'

        setTimeout(() => {
          this.s.checkout.coupon_code[1] = ''
        }, 3000)

        radio('checkout/onUpdate').broadcast({ success: true, order: d })
      },
      error: (d) => {
        this.s.checkout.coupon_code[0] = ''
        this.s.checkout.coupon_code[1] = ''
        radio('checkout/onUpdate').broadcast({
          success: false,
          error: d.responseJSON.error,
        })
      },
    })

    this.s.checkout.coupon_code[0] = ''
    couponInput.value = ''
  }

  ccValidation(d) {
    const { form } = this.s.checkout.payment
    let all_valid = true

    _.each(this.payment_validations, (v) => {
      const { val } = form[v.name]
      const valid = v.validate(val)

      // Update the error if there is one
      this.s.checkout.payment.form[v.name].err = valid ? '' : v.error

      // Disable submission
      if (!valid) all_valid = false
    })

    // Handle expiry dates
    if (all_valid) {
      // Our expiry value needs to be quite custom for it to work on craft
      const validation = this.u.ccV.expirationDate(form.expiry.val)
      const { month } = validation
      const { year } = validation

      this.s.checkout.payment.form.month.valCalc = month

      if (year.length == 2) {
        // Manually add millenia to start of number
        this.s.checkout.payment.form.expiry.valCalc = `${month} / 20${year}`
        this.s.checkout.payment.form.year.valCalc = `20${year}`
        // this.s.checkout.payment.form['expiry'].err = "Please enter the year as a 4 digit number";
        // all_valid = false;
      } else {
        this.s.checkout.payment.form.expiry.valCalc = `${month} / ${year}`
        this.s.checkout.payment.form.year.valCalc = year
      }
    }

    return all_valid
  }

  ccPotentialValidation(d) {
    // Ignore backspaces
    if (
      d.event &&
      (d.event.keyCode == 8 ||
        d.event.keyCode == 9 ||
        d.event.key === 'Backspace')
    )
      return

    // Now validate
    const { form } = this.s.checkout.payment
    const v = _.find(this.payment_validations, { name: d.name })
    const { val } = form[v.name]
    const valid = v.validatePotential(val)

    // Update the error if there is one
    this.s.checkout.payment.form[v.name].err = valid ? '' : v.error

    if (d.name == 'number') {
      // Also set card type while we are here
      this.s.checkout.payment.card_type = ''

      if (this.s.checkout.payment.form.number.err == '') {
        const { card } = this.u.ccV.number(
          this.s.checkout.payment.form.number.val
        )

        if (card) this.s.checkout.payment.card_type = card.niceType
      }
    }

    if (d.name === 'expiry') {
      // keyCode 8 = backspace
      // https://stackoverflow.com/questions/45259196/javascript-regex-credit-card-expiry-date-auto-format

      d.event.target.value = d.event.target.value
        .replace(
          /^([1-9]\/|[2-9])$/g,
          '0$1/' // 3 > 03/
        )
        .replace(
          /^(0[1-9]|1[0-2])$/g,
          '$1/' // 11 > 11/
        )
        .replace(
          /^1([3-9])$/g,
          '01/$1' // 13 > 01/3
        )
        .replace(
          /^0\/|0+$/g,
          '0' // 0/ > 0 and 00 > 0
        )
        .replace(
          /[^\d|^\/]*/g,
          '' // To allow only digits and `/`
        )
        .replace(
          /\/\//g,
          '/' // Prevent entering more than 1 `/`
        )
    }
  }

  setPayment() {
    this.s.checkout.loading = true

    // Coupon code
    const coupon = this.s.checkout.coupon_code[0]
    if (coupon != '') return this.setCoupon()

    // Payment method
    let paymentFunction = false

    if (this.s.checkout.selected_payment_type == 'giftcard')
      paymentFunction = this.setPaymentGiftcard
    if (this.s.checkout.selected_payment_type == 'stripe')
      paymentFunction = this.setPaymentStripe
    if (this.s.checkout.selected_payment_type == 'gateway')
      paymentFunction = this.setPaymentGateway

    if (!paymentFunction) return

    paymentFunction
      .apply(this)
      .then((d) => {
        // Don't continue unless it's valid
        if (!d.valid) {
          this.s.checkout.loading = false
          return
        }

        this.checkout.payment({
          data: d.data,
          success: (_d) => {
            radio('checkout/onUpdate').broadcast({ success: true, order: _d })
          },
          error: (d) => {
            let error = ''
            let errors = []

            if (d.responseJSON.error) {
              error = d.responseJSON.error
            }

            if (d.responseJSON.errors && d.responseJSON.errors.length) {
              errors = d.responseJSON.errors
            }

            radio('checkout/onUpdate').broadcast({
              success: false,
              error,
              errors,
            })
          },
        })
      })
      .catch((e) => {
        radio('checkout/onUpdate').broadcast({
          success: false,
          error: e.result.error.message || e,
        })
      })
  }

  setPaypal(d) {
    // Set coupon instead if code isnt blank
    const coupon = this.s.checkout.coupon_code[0]
    if (coupon != '') return this.setCoupon()

    window.location = d.path
  }

  setAfterpay(d) {
    // Set coupon instead if code isnt blank
    const coupon = this.s.checkout.coupon_code[0]
    if (coupon != '') return this.setCoupon()

    // window.location = d.path;
    this.u.ajax.post.send({
      url: '/afterpay',
      data: {
        payment_method_id: d.payment_method_id,
      },
      callback(_d) {
        // Redirect to Afterpay
        // TODO: Replace following with the gateway country setting
        const confirm_url = encodeURI(
          `${window.location.origin}/afterpay/success`
        )
        
        const cancel_url = encodeURI(
          `${window.location.origin}/afterpay/cancel`
        )

        window.location = `${d.redirect_url}/checkout/?token=${_d.token}&redirectConfirmUrl=${confirm_url}&redirectCancelUrl=${cancel_url}&countryCode=NZ`
      },
    })
  }

  setPaymentGiftcard() {
    const data = this.getPaymentDefaults()
    const code = this.s.checkout.giftcard[0]
    return new Promise((resolve, reject) => {
      const payment_id = _.find(this.d.order.payment_methods, {
        method_type: 'giftcard',
      }).id
      const payment_source = { [payment_id]: { code } }

      data.order.payments_attributes.push({ payment_method_id: payment_id })
      data.payment_source = payment_source

      resolve({
        data,
        valid: true,
      })
    })
  }

  setPaymentGateway() {
    return new Promise((resolve, reject) => {
      const data = this.getPaymentDefaults()
      const { form } = this.s.checkout.payment
      const valid = this.ccValidation()

      if (!valid) {
        resolve({ valid: false })
        return
      }

      const payment_id = _.find(this.d.order.payment_methods, {
        method_type: 'gateway',
      }).id

      const payment_source = {
        [payment_id]: {
          name: `${form.firstName.val} ${form.lastName.val}`,
          number: form.number.val,
          expiry: form.expiry.valCalc,
          verification_value: form.cvv.val,
        },
      }

      data.order.payments_attributes.push({ payment_method_id: payment_id })
      data.payment_source = payment_source

      resolve({
        data,
        valid: true,
      })
    })
  }

  setPaymentStripe() {
    return new Promise((resolve, reject) => {
      const data = this.getPaymentDefaults()
      const { form } = this.s.checkout.payment
      const valid = this.ccValidation()

      if (!valid) {
        resolve({ valid: false })
        return
      }

      // Get stripe token usind data
      const available_methods = this.v.checkout.formatPaymentMethods(
        this.d,
        this.s,
        this.v
      )
      if (available_methods.length == 0)
        new Error('No payment methods in currency')

      const stripe_method = _.find(available_methods, { method_type: 'stripe' })
      if (!stripe_method) new Error('No stripe payment method in this currency')

      const payment_id = stripe_method.id
      const { pub_key } = stripe_method

      this.u.stripe
        .getToken(pub_key, form)
        .then((d) => {
          const card = d.result
          const payment_source = {
            [payment_id]: {
              name: `${form.firstName.val} ${form.lastName.val}`,
              gateway_payment_profile_id: card.id,
              last_digits: card.card.last4,
              month: card.card.month,
              year: card.card.year,
            },
          }

          data.order.payments_attributes.push({ payment_method_id: payment_id })
          data.payment_source = payment_source

          resolve({ data, valid: true })
        })
        .catch((d) => {
          console.error(d.message)
          radio('checkout/onUpdate').broadcast({
            success: false,
            error: d.result.error.message,
          })

          reject(d)
        })
    })
  }

  confirm() {
    this.s.checkout.loading = true
    const data = {
      id: this.d.cart.number,
      user_id: this.d.cart.user_id,
      order_token: this.d.cart.token,
    }

    this.checkout.confirm({
      data,
      success: (d) => {
        if (d.number) {
          this.redirectToOrder(d.number)
        } else {
          this.s.checkout.loading = false
        }
      },
      error: (_d) => {
        const { errors } = _d.responseJSON
        radio('checkout/onUpdate').broadcast({ success: false, errors })
      },
    })
  }

  onUpdate(d) {
    const { success } = d
    const { order } = d
    const { error } = d
    const { errors } = d
    const source = d.source || ''

    // Redirect on completion
    if (order && order.state == 'complete' && order.number) {
      this.redirectToOrder(order.number)
      return
    }

    if (success) {
      this.d.order = order
      if (order.state) this.s.checkout.state = order.state
      this.getAddresses()
      this.getShipments()
      this.getGiftwrapped()
      this.getPaymentMethods()
    } else {
      if (errors && Object.keys(errors).length > 0) {
        this.main.vm.$store.commit(
          'overlay/SET_OVERLAY_MESSAGE',
          this.formatErrors(errors)
        )
      } else if (error) {
        this.main.vm.$store.commit('overlay/SET_OVERLAY_MESSAGE', error)
      }

      if (source !== 'address')
        this.main.vm.$store.commit('overlay/SET_OVERLAY', 'error')
    }

    this.s.checkout.loading = false
    this.main.vm.$store.dispatch('cart/refresh')
  }

  redirectToOrder(orderNumber) {
    window.location = `/orders/${orderNumber}?post-checkout=1`
  }

  formatErrors(errors) {
    // Split key
    return _.map(errors, (v, k) => {
      let heading = ''
      const str = k.split('.')

      heading = this.humanize(str[0])
      if (str.length > 1) heading += ` - ${this.humanize(str[1])}`

      let error = _.map(v, (v) => this.humanize(v)).join('</li><li>')

      if (
        this.s.checkout.state === 'address' &&
        error ===
          'We are unable to calculate shipping rates for the selected items.'
      ) {
        heading = 'Please check currency.'
        error = `
            NZD for shipping to New Zealand<br>
            AUD for shipping to Australia<br>
            USD for shipping to USA and rest of world
          `
      }

      return `<div class="errors"><h3>${heading}</h3><ul><li>${error}</li></ul></div>`
    }).join('')
  }

  humanize(str) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase()
      })
  }
}
