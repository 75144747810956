export default class Default {
  constructor(main) {
    this.main = main;
    this.u = main.u;

    this.el = {};

    this.s  = main.state;
    this.d  = main.data;
    this.v  = main.view;
    this.m  = main.mutator;
    this.e  = main.event;
    this.ls = main.store;

    this.init();
  }

  init() {

  }
} 