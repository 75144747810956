export default class Default {
  constructor(main, spree) {
    this.u = main.utils;
    this.c = spree;

    //Default ajax settings
    this._defaults = spree._defaults;
  }
 
  set(data) {
    return new Promise((res,rej) => {
      this.c.ajax({
        url: '/feed/location/update.json',
        data: data.data,
        callback : (d) => {
          res(d);
        },
        error : (d) => {
          rej(d);
        },
      });
    });
  }
 
  get(data) {
    return new Promise((res,rej) => {
      this.c.ajax({
        type: 'get',
        url: '/feed/location/current.json',
        // data: data.data,
        callback : (d) => {
          res(d);
        },
        error : (d) => {
          rej(d);
        },
      });
    })
  }
}