export default {
  name: 'AudioPlayer',

  components: {
    // VuePlyr
  },

  data() {
    return {
      options: {
        controls: ['play', 'progress', 'current-time', 'duration', 'mute', 'volume'],
      },
    };
  },

  props: {
    src: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },
  },


  template: /* html */ `
    <div class="ctf-audio-player">
      <figure class="ctf-audio-player__figure">
        <figcaption
          v-if="!!title || !!description"
          class="ctf-audio-player__caption"
        >
          <h2
            v-if="title"
            class="ctf-audio-player__title"
          >
            {{ title }}
          </h2>
          <p
            v-if="description"
            class="ctf-audio-player__description"
          >
            {{ description }}
          </p>
        </figcaption>
        <vue-plyr :options="options">
          <audio>
            <source
              :src="src"
              type="audio/mp3"
            />
          </audio>
        </vue-plyr>
      </figure>
    </div>
  `,
};
