export const SET_BASE_CURRENCY = 'SET_BASE_CURRENCY'
export const SET_DISPLAY_CURRENCY = 'SET_DISPLAY_CURRENCY'
export const SET_DISPLAY_CURRENCY_DATA = 'SET_DISPLAY_CURRENCY_DATA'

export const SET_OVERLAY = 'SET_OVERLAY'
export const SET_OVERLAY_MESSAGE = 'SET_OVERLAY_MESSAGE'

export const SET_CART_DATA = 'SET_CART_DATA'
export const SET_CART_RELATED_DATA = 'SET_CART_RELATED_DATA'
export const SET_CART_SPECIAL_INSTRUCTIONS = 'SET_CART_SPECIAL_INSTRUCTIONS'
export const SET_CART_STATUS = 'SET_CART_STATUS'
