import Home from './page/home';
import Help from './page/help';
import Faq from './page/faq';
import Stores from './page/stores';

export default class Page {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    this.home   = new Home(main,utils);
    this.help   = new Help(main,utils);
    this.faq    = new Faq(main,utils);
    this.stores = new Stores(main,utils);
  }
} 