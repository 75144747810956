export default {
  step: 0,
  system: 'metric',
  band: false,
  cup: false,
  fit: false,
  link: false,
  has_next: false,
  has_prev: false,
  next_class: false,
  prev_class: false,
  content_class: false,
  tip_active: false,
  $slider: -1,
  bands: [
    { metric: ['8', '67 to 74cm'], imperial: ['30', '26" to 29"'] },
    { metric: ['10', '73 to 80cm'], imperial: ['32', '29" to 31"'] },
    { metric: ['12', '79 to 86cm'], imperial: ['34', '31" to 34"'] },
    { metric: ['14', '85 to 92cm'], imperial: ['36', '33" to 36"'] },
    { metric: ['16', '91 to 98cm'], imperial: ['38', '36" to 39"'] },
  ],
  cups: {},
  all_cups: [
    {band: ['8', '30'], size: 'A', metric: '76 to 79cm', imperial: '30" to 31"', },
    {band: ['8', '30'], size: 'B', metric: '78 to 81cm', imperial: '31" to 32"', },
    {band: ['8', '30'], size: 'C', metric: '81 to 84cm', imperial: '32" to 33"', },
    {band: ['8', '30'], size: 'D', metric: '83 to 86cm', imperial: '33" to 34"', },
    {band: ['8', '30'], size: 'DD',metric: '86 to 89cm', imperial: '34" to 35"', },
    {band: ['8', '30'], size: 'E', metric: '88 to 91cm', imperial: '35" to 36"', },
    {band: ['8', '30'], size: 'F', metric: '91 to 94cm', imperial: '36" to 37"', },
    {band: ['8', '30'], size: 'G', metric: '93 to 96cm', imperial: '37" to 38"', },

    {band: ['10', '32'], size: 'A', metric: '82 to 85cm', imperial: '32" to 33"', },
    {band: ['10', '32'], size: 'B', metric: '84 to 87cm', imperial: '33" to 34"', },
    {band: ['10', '32'], size: 'C', metric: '87 to 90cm', imperial: '34" to 35"', },
    {band: ['10', '32'], size: 'D', metric: '89 to 92cm', imperial: '35" to 36"', },
    {band: ['10', '32'], size: 'DD',metric: '92 to 95cm', imperial: '36" to 37"', },
    {band: ['10', '32'], size: 'E', metric: '94 to 97cm', imperial: '37" to 38"', },
    {band: ['10', '32'], size: 'F', metric: '97 to 100cm', imperial: '38" to 39"', },
    {band: ['10', '32'], size: 'G', metric: '99 to 102cm', imperial: '39" to 40"', },

    {band: ['12', '34'], size: 'A', metric: '88 to 91cm', imperial: '34" to 36"', },
    {band: ['12', '34'], size: 'B', metric: '90 to 93cm', imperial: '35" to 37"', },
    {band: ['12', '34'], size: 'C', metric: '93 to 96cm', imperial: '36" to 38"', },
    {band: ['12', '34'], size: 'D', metric: '95 to 98cm', imperial: '37" to 39"', },
    {band: ['12', '34'], size: 'DD', metric: '98 to 101cm', imperial: '38" to 40"', },
    {band: ['12', '34'], size: 'E', metric: '100 to 103cm', imperial: '39" to 41"', },
    {band: ['12', '34'], size: 'F', metric: '103 to 106cm', imperial: '40" to 42"', },
    {band: ['12', '34'], size: 'G', metric: '105 to 108cm', imperial: '41" to 43"', },

    {band: ['14', '36'], size: 'A', metric: '94 to 97cm',    imperial: '37" to 38"', },
    {band: ['14', '36'], size: 'B', metric: '96 to 99cm',    imperial: '38" to 39"  ', },
    {band: ['14', '36'], size: 'C', metric: '99 to 102cm',   imperial: '39" to 40"', },
    {band: ['14', '36'], size: 'D', metric: '101 to 104cm',  imperial: '40" to 41"', },
    {band: ['14', '36'], size: 'DD', metric: '104 to 107cm', imperial: '41" to 42"', },
    {band: ['14', '36'], size: 'E', metric: '106 to 109cm',  imperial: '42" to 43"', },
    {band: ['14', '36'], size: 'F', metric: '109 to 112cm',  imperial: '43" to 44"', },
    
    {band: ['16', '38'], size: 'A', metric: '100 to 103cm',  imperial: '39" to 40"', },
    {band: ['16', '38'], size: 'B', metric: '102 to 105cm',  imperial: '40" to 41"', },
    {band: ['16', '38'], size: 'C', metric: '105 to 108cm',  imperial: '41" to 42"', },
    {band: ['16', '38'], size: 'D', metric: '107 to 110cm',  imperial: '42" to 43"', },
    {band: ['16', '38'], size: 'DD', metric: '110 to 113cm', imperial: '43" to 44"', },
    {band: ['16', '38'], size: 'E', metric: '112 to 115cm',  imperial: '44" to 45"', },
  ],
  cover_images: [
    'spree/frontend/content/sizing/step0.jpg',
    'spree/frontend/content/sizing/step1.jpg',
    'spree/frontend/content/sizing/step2.jpg',
    'spree/frontend/content/sizing/step3.jpg',
  ],
};
 
