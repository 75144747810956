<template>
  <span class="break-lines" v-html="replacedText" />
</template>

<script>
// interface LineBreak {
//   replace: RegExp
//   replacement: string
// }

export default {
  props: {
    lines: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },

  computed: {
    replacedText() {
      if (!this.lines.length) return this.text
      return this.lines.reduce(
        (text, [replace, replacement]) => text.replace(replace, replacement),
        this.text
      )
    },
  },
}
</script>

<style>
.break-lines {
  display: inline-block;
  white-space: pre-line;
}
</style>
