export default {
  showSuggested: (state, idx) => {
    var selected   = state.maternity_chart.selected_size;
    var suggested  = state.maternity_chart.conversion[idx][selected];
    var string = (suggested) ? suggested : '';

    return `Suggested Size: <span>${string}</span>`;
  },
  isAvailable: (state) => {
    var selected    = state.maternity_chart.selected_size;
    var conversions = state.maternity_chart.conversion;

    //Unless initialized
    if(selected == -1) return -1;

    //Find unavailable conversion
    return !_.find(conversions, (v,i) => {
      return v[selected] == '';
    });
  }
}