<template functional>
  <button
    class="location-select"
    :class="[
      props.active && 'location-select--active',
      data.staticClass && `${data.staticClass}`,
      (data.staticClass && props.active) && `${className}--active`,
    ]"
    @click.prevent="listeners.click"
  >
    <span class="location-select__text">
      <template v-if="props.name === 'GB'">
        UK
      </template>
      <template v-else-if="props.name === 'US'">
        USA
      </template>
      <template v-else>
        {{ props.name }}
      </template>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    iso: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.location-select {
  font-size: inherit;

  &:active,
  &:focus {
    outline: none;
  }

  &__text {
    border-bottom: 1px solid transparent;
  }

  &:active &,
  &:focus & {
    &__text {
      border-color: currentColor;
    }
  }
}
</style>
