<template>
  <div class="product-selection-message">
    <div v-show="show" class="product-selection-message__inner">
      <h3 class="display-s caps">{{ title }}</h3>
      <a :href="`/products?keywords=${selectedSize}`"
        >See our styles that come in {{ selectedSize }}</a
      >
    </div>
  </div>
</template>

<script>
import { variantHasOrderableStock } from '../../../utils/helpers'
export default {
  props: {
    selectedSize: {
      type: String,
      default: '',
    },
    selectedVariant: {
      type: Object,
      default: null,
    },
    warehouse: {
      type: String,
      required: true,
    },
  },

  computed: {
    show() {
      return this.unpurchasable || this.unavailable
    },
    title() {
      return this.unpurchasable ? 'Unavailable' : 'Sold Out'
    },
    unavailable() {
      return (
        this.selectedVariant &&
        !variantHasOrderableStock(this.warehouse)(this.selectedVariant)
      )
    },
    unpurchasable() {
      return this.selectedSize && !this.selectedVariant
    },
  },
}
</script>

<style lang="scss" scoped>
.product-selection-message {
  min-height: 45px;
}

a {
  color: var(--color-muted);

  &:after {
    content: var(--arrow-right);
    margin-left: 1ch;
  }
}
</style>
