
import Checkout from './checkout';
import Location from './location';

export default class Default {
  constructor(utils) {
    this._defaults = {
      type : 'GET',
      dataType: 'json',
      contentType: 'application/json',
      headers : {
        //'X-API-Token' : utils._api_key
        'X-CSRF-Token' : utils.m.data.csrf_token
      },
      data: {
        authenticity_token: utils.m.data.csrf_token
      },
      callback : (d) => {
        console.log('spree:callback', d);
      },
      success : (d) => {
        console.log('spree:success', d);
      },
      error : (d) => {
        console.log('spree:error', d);
      }
    };

    this.checkout = new Checkout(utils, this);
    this.location = new Location(utils, this);
  }

  ajax(data) {
    var settings = $.extend(this._defaults, {
      dataType : 'json',
      method   : (typeof(data.type) != 'undefined') ? data.type : 'post',
      url      : data.url,
      data     : (data.data) ? JSON.stringify(data.data) : null,      
      success : (d) => {
        if(typeof(data.callback) == 'function') data.callback(d);
      }
    });

    if(data.error) settings.error = (d) => data.error(d);
    
    $.ajax(settings);
  }
}