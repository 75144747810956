import Tabs from '../../utils/tabs';

export default class Help {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;

    this._s = {
      charts: {}
    }
    
    radio('page/help').subscribe((d)  => {this.init(d);});
    radio('page/help/charts').subscribe((d)  => {this.charts(d);});
  }
  
  init(d) {
    this._d = d;

    this.tabs = new Tabs({
      $tab_pub : this._d.$tab_pub,
      $tab_sub : this._d.$tab_sub,
    });
  }

  charts() {
    this._d.$chart_sub.each((i,v) => {
      this.chart($(v));
    });

    radio('page/help/chart/update').subscribe((d)  => {this.chartUpdate(d);});
  }

  chart($el) {
    //Find chart entry (Assume there is a chart)
    var key = $el.attr('data-chart');
    this._s.charts[key] = this._d.chart_data[key];

    //Set up initial current selection
    this._s.charts[key] = this.chartApplySystem(this._s.charts[key]);

    //Apply html and bind
    $el.html(this._d.chart_template);
    rivets.bind($el,this._d.chart_data[key]);
  }

  chartUpdate(d) {
    var key = d.key;

    this._s.charts[key] = this.chartApplySystem(this._s.charts[key]);
  }

  chartApplySystem(chart) {
    var system = chart.system;

    chart.bands = _.map(chart.bands, (band,band_index) => {
      band.current = band[system];
      band.cups = _.map(band.cups, (cup, cup_index) => {
        cup.current = cup[system];
        return cup;
      });
      return band;
    });

    return chart;
  }
}

