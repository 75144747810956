export default class Get {
  constructor(parent) {
    this.req = parent.req;
  }

  page(options) {
    this.req({
      url : options.url,
      data : options.data,
      callback : options.callback,
      method: 'GET'
    });
  }

  page_contents(options) {
    this.page({
      url: options.url,
      data : options.data,
      callback: (data) => {
        var $html = $(data);
        var $contents = $html.find(options.selector);
        if(typeof(options.callback) == 'function') options.callback($contents);
      }
    })
  }
}