<template>
  <div class="overlay-cart__heading">
    <div class="overlay-cart__close" @click="$store.commit('overlay/SET_OVERLAY', '')">
      <span><i></i><i></i></span>
    </div>
    <h3 class="overlay-cart__title">
      Your cart ({{ count }})
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
@import "~styles/frontend/base";
.overlay-cart {
  &__heading {
    @include hx-style1();
    @include bm-m();
    text-align:center;
    margin-right:60px;
  }
  &__close {
    @include abs-position(20px,20px,18px,18px,right);
    @include helper-close-icon();
    cursor:pointer;
    z-index:11;
    display:none;
  }
  
  @include respond-to(xxs) {
    &__close {
      display:block;
    }
  }
}
</style>