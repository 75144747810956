
import ccV from 'card-validator';

export default {
  payment: [
    {
      name: "firstName",
      error: "Please enter your first name",
      validatePotential: (v) => {
        // console.log(v);
        return v != ""
      },
      validate: (v) => {
        return v != ""
      },
    },
    {
      name: "lastName",
      error: "Please enter your last name",
      validatePotential: (v) => {
        // console.log(v);
        return v != ""
      },
      validate: (v) => {
        return v != ""
      },
    },
    {
      name: "number",
      error: "Please check your creditcard number",
      validatePotential: (v) => {
        return ccV.number(v).isPotentiallyValid
      },
      validate: (v) => {
        return true;//this.u.ccV.number(v).isValid
      },
    },
    {
      name: "expiry",
      error: "Please check your expiry date",
      validatePotential: (v) => {
        return ccV.expirationDate(v).isPotentiallyValid
      },
      validate: (v) => {
        return ccV.expirationDate(v).isValid
      },
    },
    {
      name: "cvv",
      error: "Please enter your security code", 
      validatePotential: (v) => {
        return ccV.cvv(v).isPotentiallyValid
      },
      validate: (v) => {
        return ccV.cvv(v).isValid
      },
    },
  ]
};