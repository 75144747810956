export default class Cookies {
  get(c_name) {
    return jsCookie.get(c_name);
  }

  set(c_name, value, exhours) {
    jsCookie.set(c_name, value, { expires: exhours/24, path:'/' });
  }

  rm(c_name) {
    jsCookie.remove(c_name, {path: '/'});
  }
}