export default class Pub {
  constructor(utils) {
    this.u = utils;
    this.s = utils.m.state;

    //Get animation frame
    this.gdh = utils.helpers.getDocumentHeight;

    this._s = {
      $window : $(window),
      scroll : {
        top : {}
      },
      resize : {
        width : {},
        height : {}
      },
      document : {
        height : {}
      }
    };

    this.s.window = {};

    //Initialise state variables
    this.reset();

    //start loop
    this.loop();

    //force events to fire a few times
    $(window).on('load ready', (e) => {this.reset();});
    this.reset();

    //Fix so that switching tabs forces reset
    window.addEventListener("focus", (e) => {
      setTimeout(() => {
        this.reset();
      }, 100);
    });
  }

  reset() {
    this._s.scroll.top.old      = -1;
    this._s.resize.width.old    = -1;
    this._s.resize.height.old   = -1;
    this._s.document.height.old = -1;

    this._s.scroll.top.current      = 0;
    this._s.resize.width.current    = 0;
    this._s.resize.height.current   = 0;
    this._s.document.height.current = 0;

    this._s.scroll.top.direction      = 'none';
    this._s.resize.width.direction    = 'none';
    this._s.resize.height.direction   = 'none';
  }

  loop() {
    var $window = this._s.$window;

    //Existing variables
    var old_top    = this._s.scroll.top.old;
    var old_width  = this._s.resize.width.old;
    var old_height = this._s.resize.height.old;
    var old_document_height = this._s.document.height.old;

    //New variables
    var new_top    = window.pageYOffset;
    var new_width  = window.innerWidth;
    var new_height = window.innerHeight;
    var new_document_height = this.gdh();

    //Switches
    var scroll_changed = old_top != new_top;
    var resize_changed = old_width != new_width || old_height != new_height;
    var layout_changed = old_document_height != new_document_height;

    //Scroll events
    if(scroll_changed) {
      //Set variables
      this._s.scroll.top.current   = new_top;
      this._s.scroll.top.direction = (new_top > old_top) ? 'down' : 'up';

      //Set old variables
      this._s.scroll.top.old    = new_top;
    }

    //Resize events
    if(resize_changed) {
      //Set variables
      this._s.resize.width.current    = new_width;
      this._s.resize.height.current   = new_height;

      this._s.resize.width.direction  = (new_width > old_width)   ? 'more' : 'less';
      this._s.resize.height.direction = (new_height > old_height) ? 'more' : 'less';

      //Set old variables
      this._s.resize.width.old  = new_width;
      this._s.resize.height.old = new_height;
    }

    //Layout events
    if(resize_changed) {
      //Set variables
      this._s.document.height.current = new_document_height;

      //Set old variables
      this._s.document.height.old = new_document_height;
    }

    //Update app state object
    this.s.window.scroll_top  = this._s.scroll.top.current;
    this.s.window.width       = this._s.resize.width.current;
    this.s.window.height      = this._s.resize.height.current;
    this.s.window.doc_height  = this._s.document.height.current;

    // Publish events
    if(scroll_changed) this.scrollPub();
    if(resize_changed) this.resizePub();
    if(layout_changed) this.layoutPub();

    //Loop
    window.requestAnimationFrame((time) => {
      this.loop();
    });
  }

  scrollPub() {
    radio('window/scroll').broadcast({
      scroll_top : this._s.scroll.top.current,
      scroll_direction  : this._s.scroll.top.direction,
    });
  }

  resizePub() {
    radio('window/resize').broadcast({
      width  : this._s.resize.width.current,
      height : this._s.resize.height.current,
      width_direction  : this._s.resize.width.direction,
      height_direction : this._s.resize.height.direction,
    });
  }

  layoutPub() {
    radio('window/layout').broadcast({
      height  : this._s.document.height.current,
    });
  }
}
