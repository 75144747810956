<template>
  <form class="product-selection" @submit.prevent="$emit('submit')">
    <template v-if="variants.length > 1">
      <product-bra-selection
        v-if="isBra"
        :option-values="optionValues"
        :selected-variant="selectedVariant"
        :variants="variants"
        :warehouse="warehouse"
        @update:selected-size="onSelectedSize"
        @update:selected-variant="onUpdate"
      />
      <product-option-selection
        v-else
        :option-values="optionValues"
        :selected-variant="selectedVariant"
        :variants="variants"
        :warehouse="warehouse"
        @update:selected-size="onSelectedSize"
        @update:selected-variant="onUpdate"
      />
    </template>
    <div v-else class="single">One Size</div>
    <slot
      :disabled="disabled"
      :unavailable="unavailable"
      :unpurchasable="unpurchasable"
    />
  </form>
</template>

<script>
import { isBraSize } from '../../../utils/product-helpers'
import ProductBraSelection from './product-bra-selection.vue'
import ProductOptionSelection from './product-option-selection.vue'
import { variantHasOrderableStock } from '../../../utils/helpers'

export default {
  components: {
    ProductBraSelection,
    ProductOptionSelection,
  },

  props: {
    selectedVariant: {
      type: Object,
      default: null,
    },
    variants: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedSize: this.variants.length === 1 || '',
    }
  },

  computed: {
    disabled() {
      return !this.selectedSize || this.unavailable || this.unpurchasable
    },
    isBra() {
      return this.optionValues.every(isBraSize)
    },
    optionValues() {
      return this.variants
        .map((variant) => variant.option_values)
        .flat()
        .reverse()
    },
    unavailable() {
      return (
        this.selectedVariant &&
        !variantHasOrderableStock(this.warehouse)(this.selectedVariant)
      )
    },
    unpurchasable() {
      return !!this.selectedSize && !this.selectedVariant
    },
    warehouse() {
      return this.$root.warehouseCurrent
    },
  },

  methods: {
    onSelectedSize(selectedSize) {
      this.selectedSize = selectedSize
    },
    onUpdate(selectedVariant) {
      radio('product/stores').broadcast({ sku: selectedVariant?.sku })
      this.$emit('update', selectedVariant)
    },
  },
}
</script>

<style lang="scss" scoped>
.product-selection ::v-deep {
  legend {
    margin-bottom: 5px;
  }

  .options {
    display: flex;
    justify-content: center;

    &.unselected {
      color: var(--color-light);
    }
  }

  .selection {
    display: inline-block;
    text-transform: uppercase;
  }
}
</style>
