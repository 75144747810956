import Base from './base';

import AppS from './state/app'
import AppV from './view/app'
import AppM from './mutator/app'
import AppE from './event/app'

import CartV from './view/cart';

// import CheckoutB from './base';
import CheckoutS from './state/checkout'
import CheckoutV from './view/checkout'
import CheckoutM from './mutator/checkout'
import CheckoutE from './event/checkout'

import CheckoutCompleteE from './event/checkout_complete';

import CurrencyS from './state/currency';
import CurrencyV from './view/currency';
import CurrencyM from './mutator/currency';

import WarehouseS from './state/warehouse';
// import WarehouseV from './view/warehouse';
// import WarehouseM from './mutator/warehouse';
// import WarehouseE from './event/warehouse';

import LocationS from './state/location';
import LocationV from './view/location';
import LocationM from './mutator/location';
import LocationE from './event/location';

import MaternityChartS from './state/maternity_chart';
import MaternityChartV from './view/maternity_chart';
// import MaternityChartM from './mutator/maternity_chart';

import HeaderS from './state/header';
import HeaderV from './view/header';
import HeaderM from './mutator/header';

import PageHelpE from './event/page_help'

import PageHomeS from './state/page_home';
import PageHomeV from './view/page_home';
import PageHomeM from './mutator/page_home';
import PageHomeE from './event/page_home'


import PanelS from './state/panel';
// import PanelV from './view/panel';
import PanelM from './mutator/panel';

import ProductS from './state/product'
import ProductV from './view/product'
import ProductM from './mutator/product'
import ProductE from './event/product'

import SidebarCollectionS from './state/sidebar_collection';
import SidebarCollectionV from './view/sidebar_collection';
import SidebarCollectionE from './event/sidebar_collection';

import SizingS from './state/sizing';
import SizingV from './view/sizing';
import SizingM from './mutator/sizing';
import SizingE from './event/sizing';

import SizingChartS from './state/sizing_chart';
import SizingChartV from './view/sizing_chart';
// import SizingChartM from './mutator/sizing_chart';
import SizingChartE from './event/sizing_chart';

export default class Default extends Base {
  init() {
    this.s.app = AppS;
    this.v.app = AppV;
    this.m.app = AppM;
    this.e.app = new AppE(this.main);

    this.s.currency = CurrencyS;
    this.v.currency = CurrencyV;
    this.m.currency = CurrencyM;
    // this.e.currency = new CurrencyE(this.main);

    this.s.warehouse = WarehouseS;
    // this.v.warehouse = WarehouseV;
    // this.m.warehouse = WarehouseM;
    // this.e.warehouse = new WarehouseE(this.main);

    this.s.location = LocationS;
    this.v.location = LocationV;
    this.m.location = LocationM;
    this.e.location = new LocationE(this.main);

    this.s.header = HeaderS;
    this.v.header = HeaderV;
    this.m.header = HeaderM;

    this.s.panel = PanelS;
    // this.v.panel = PanelV;
    this.m.panel = PanelM;

    this.s.product = ProductS;
    this.v.product = ProductV;
    this.m.product = ProductM;
    this.e.product = new ProductE(this.main);

    this.s.sidebar_collection = SidebarCollectionS;
    this.v.sidebar_collection = SidebarCollectionV;
    // this.m.sidebar_collection = SidebarCollectionM;
    this.e.sidebar_collection = new SidebarCollectionE(this.main);

    // PAGES
    this.e.page_help = new PageHelpE(this.main);

    this.s.page_home = PageHomeS;
    this.v.page_home = PageHomeV;
    this.m.page_home = PageHomeM;
    this.e.page_help = new PageHomeE(this.main);

    // SIZING
    this.s.sizing = SizingS;
    this.v.sizing = SizingV;
    this.m.sizing = SizingM;
    this.e.sizing = new SizingE(this.main);

    this.s.sizing_chart = SizingChartS;
    this.v.sizing_chart = SizingChartV;
    // this.m.sizing_chart = SizingChartM;
    this.e.sizing_chart = new SizingChartE(this.main);

    this.s.maternity_chart = MaternityChartS;
    this.v.maternity_chart = MaternityChartV;
    // this.m.maternity_chart = MaternityChartM;


    // CHECKOUT
    this.v.cart = CartV;

    this.s.checkout = CheckoutS;
    this.v.checkout = CheckoutV;
    this.m.checkout = CheckoutM;
    this.e.checkout = new CheckoutE(this.main);

    this.e.checkout_complete = new CheckoutCompleteE(this.main);
  }
}