<template>
  <form class="cart" @submit.prevent="onSubmit">
    <div class="empty" v-if="$store.getters['cart/itemCount'] == 0">
      <div class="description">
        Your cart is empty, would you like to shop <a href="/">Lonely</a>?
      </div>
    </div>
    <div class="items" v-else="">
      <table>
        <thead>
          <tr>
            <th class="image">Product</th>
            <th class="details">&nbsp;</th>
            <th class="attributes">Quantity</th>
            <th class="price">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr class="item" v-for="(li, k) in cart.line_items" :key="'cart_item_'+k"> 
            <td class="image">
              <a v-bind:href="li.product.url">
                <img
                  v-bind:src="li.product.images[li.product.images.length - 1].small_url"
                  v-if="li.product.images.length &gt; 0"
                />
              </a>
            </td>
            <td class="details" data-label="Product">
              <a v-bind:href="li.product.url">
                <div class="title" v-text="li.product.name"></div>
                <div class="size" v-if="getSize(li)">
                  Size:
                  <span
                    v-text="getSize(li)"
                    v-if="getSize(li)"
                  ></span>
                </div>
              </a>
            </td>
            <td class="attributes" data-label="Quantity">
              Quantity: 
              <span class="qty" v-text="li.quantity"></span>
              <br />
              <span class="a" @click.prevent="$store.dispatch('cart/remove', {variant_id: li.variant_id})" >
                Remove
              </span>
            </td>
            <td class="price" data-label="Total">
              <price-display
                :prices="li.price"
                class="price line-item__price"
              ></price-display>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="notes">
        <textarea
          @change="$store.commit('cart/SET_CART_SPECIAL_INSTRUCTIONS', $event.target.value)"
          placeholder="Special Instructions"
          :value="$store.state.cart.specialInstructions"
        ></textarea>
      </div>
      <div class="extra">
        <ul class="total">
          <li v-for="(t, k) in formatTotals" :key="'cart_total'+k">
            <span class="key">
              <span v-text="t.key"> </span> 
            </span>:

            <price-display :prices="t.value" class="price"></price-display>
          </li>
        </ul>
        <div class="controls">
          <button class="button">
            Check Out
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import CartMixin from '~/mixins/cart';
import PriceDisplay from '~/components/prices/price-display';

export default {
  props: {
    cart: {
      type: Object,
    },
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch('cart/instructions');
    },
  },
  mixins: [
    CartMixin,
  ],
  components: {
    PriceDisplay,
  },
}
</script>

<style lang="scss">
  @import "~styles/frontend/base";
  @import "~styles/frontend/form/input.scss";
  @import "~styles/frontend/form/button.scss";
  
  .cart {
    @include clearfix();
    position: relative;
    @include bm-m();
    table {
      width: 100%;
      border-bottom: 1px solid $lightestgrey;
      @include bm-s();
      tr {
        th,
        td {
          text-align: left;
          padding: 20px 10px;
          vertical-align: middle;
          &.details {
            // @include spanX(15,5);
            h4 {
              @include hx-style1();
              @include bm-xs();
            }
          }
          &.attributes {
            span.a {
              @include helper-underline();
            }
          }
          &.price {
            text-align: right;
            padding-right: $gutter;
          }
          &.details,
          &.image,
          &.attributes,
          &.price {
            float: none;
            padding-top: $space-s;
            padding-bottom: $space-s;
          }
        }
      }
      thead {
        tr {
          border-bottom: 1px solid $lightestgrey;
          th.image {
            width: 148px;
          }
          th {
            @include hx();
            @include hx-xs();
            padding: 10px $gutter;
            padding: 0 0 10px;
            &.quantity {
              display: none;
            }
            &.image,
            &.details,
            &.controls,
            &.attributes,
            &.price {
              @include hx-style1();
              margin: 0;
              padding: 20px 10px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &.image,
            &.details,
            &.controls,
            &.attributes,
            &.price {
              @include cx();
              @include cx-xs();
              @include cx-darkestgrey();
              @include sans();
              padding: 20px 10px;
            }

            &.image {
              padding-left: $gutter;
            }
          }
          & + tr {
            border-top: 1px solid $lightestgrey;
          }
        }
      }
    }

    .empty {
      .description {
        @include cx();
        @include cx-s();
        @include bm-xl();
        text-align:center;
      }
    }
    .totals,
    .controls {
      text-align:right;
    }

    .controls {
      a.button,
      button {
        @include button();
        @include button-border();
      }
      button {
        margin-left:20px;
      }
      &.controls-empty {
        text-align:center;
      }
    }

    .extra {
      @include span(6);
      @include bm-xl();
      float: right;
      > ul {
        @include cx();
        @include cx-darkestgrey();
        @include sans();
        @include cx-xs();
        text-align: right;
        & + div {
          padding-top: 1em;
        }
      }
      .totals {
        @include clearfix();
        ul {
          float: right;
          span {
            display: inline-block;
            + span {
              padding-left: 10px;
            }
          }
        }
      }
    }

    .notes {
      @include span(6);
      @include bm-xl();
      textarea {
        @include input-textarea();
        @include input-text-border();
        // border-color:$lightestgrey;
        border: none;
        outline: 1px solid $lightestgrey;
        min-height: 100px;
        width: 100%;
      }
    }

    @include respond-to(xl) {
      .controls button {
        margin-right: calc(#{$gutter}/2);
      }
    }

    @include respond-to(s) {
      .cart {
        padding: 0 $gutter;
        table {
          margin: 0 0 40px;
          tbody {
            tr {
              th {
                display: none;
              }
              td {
                text-align: right;
                &.details,
                &.attributes,
                &.price {
                  min-width: 100%;
                  padding: 5px $gutter;
                }
                &.image {
                  display: none;
                }
              }
            }
          }
        }
        @include helper-table-responsive();
      }

      .extra,
      .notes {
        float: none;
        width: auto;
        margin-bottom: 20px;
      }
      .controls {
        padding-right: 0;
      }
    }
  }
</style>
