export default class Stores {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    radio('page/stores').subscribe((data)  => {this.stores(data);});
    radio('page/stores/select').subscribe((data)  => {this.select(data);});
  }

  stores(data) {
    this.$select_pub = data.$select_pub;
    this.$select_sub = data.$select_sub;
  }

  select(data) {
    var $pub  = data.$pub;
    var $sub  = data.$sub;
    var store = data.store;

    this.$select_pub.not($pub).removeClass('active');
    this.$select_sub.not($sub).removeClass('active');

    $sub.addClass('active');
    $pub.addClass('active');

    window.location.hash = store;
  }
}