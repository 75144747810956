<template>
  <div class="overlay-cart__items">
    <div class="overlay-cart__item" v-for="(li, k) in items" :key="'cart_line_item' + k">
      <div
        class="overlay-cart__item__remove"
        @click.prevent="$store.dispatch('cart/remove', {variant_id: li.variant_id})"
      />

      <div class="overlay-cart__item__image">
        <a :aria-label="li.variant.name" :href="li.product.url">
          <img
            :alt="li.variant.name" 
            :src="li.product.images[li.product.images.length - 1].product_cart_url"
            v-if="li.product.images.length > 0"
          />
        </a>
      </div>
      <div class="overlay-cart__item__details">
        <div class="overlay-cart__item__title" v-html="getTitle(li)"></div>
        <div class="overlay-cart__item__attributes">
          Quantity:
          <span v-text="li.quantity"></span>
          <span v-if="getSize(li)">
            <br/>
            Size:
            <span v-text="getSize(li)"></span>
          </span>
        </div>
        <price-display :prices="[{ price: li.price }]" class="price overlay-cart__item__price"></price-display>
      </div>
    </div>
  </div>
</template>

<script>
import PriceDisplay from '~/components/prices/price-display';

import CartMixin from '~/mixins/cart';

export default {
  props: {
    items: {
      type: Array,
      default: () => ({}),
    },
  },
  methods: {
    getTitle(li) {
      return `<a href="${li.product.url}">${li.product.name}</a>`;
    },
  },
  components: {
    PriceDisplay,
  },
  mixins: [
    CartMixin,
  ],
};
</script>


<style lang="scss">
@import "~styles/frontend/base";
@import "~styles/frontend/form/input";


.overlay-cart {
  &__items {
    @include clearfix();
  }

  &__item {
    @include clearfix();
    @include bm-xs();
    position:relative;

    &__remove {
      @include abs-position(5px,0,16px,16px,right);
      cursor:pointer;
      @include helper-close-icon();
      &:before,
      &:after {
        left:25%;width:50%;
        top:25%;
      }
    }

    &__image {
      display:block;
      float:left;
      width:100px;
      // height:100px;
      background-position:center;
      background-size:cover;
      background-color:#eee;
      margin-right:15px;
      position:relative;
      z-index:1;
    }

    &__details {
      margin-left:120px;
      @include cx(); 
      @include cx-xs();
      position:relative;
      padding-top:10px; 
    }


    &__title {
      padding-right: 20px;
    }

    &__price {
      @include abs-position(20px,0,auto,auto,right,bottom);
    }

    &__variants {
      select {
        @include input-select-style1();
        @include bm-xs();
        width:90%;
      }
    }

    &__added {
      display:none;
    }

    &__add,
    &__cancel  {
      @include appearance_none();
      display:inline;
      @include cx();
      @include cx-xs();
      & + button {
        margin-left:10px;
      }
    }
      
    &__add {
      border-bottom:1px solid $lightgrey;
    }
    &__cancel {
      @include cx-lightgrey();
    }

    + .overlay-cart__item {
      margin-top:20px;
    }

    @include respond-to('xxs') {
      display:flex;
      &__remove {
        top:6px;
        right:0px;
      }
      &__image {
        width: 80px;
        height: 80px;
        background:none;

        > * {
          display: block;
          width: 80px;
        }
      }
      &__details {
        flex: 1;
        padding-top:0;
      }
      &__title {
        padding-right:18px;
      }
      &__price {
        // position:static;
        text-align:right;
      }
    }
  }
}

</style>