<template>
  <overlay-wrap name="error">
    <div class="close" @click="emitClose">
    </div>
    <div class="inner">
      <h3 class="title">There was a problem</h3>
      <div class="message" v-html="message"></div>
      <div class="controls">
        <button @click="emitClose">Okay</button>
      </div>
    </div>
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex';
import OverlayWrap from '~/components/overlay/wrap';

export default {
  computed: {
    ...Vuex.mapState({
      overlay: state => state.overlay.current,
      message: state => state.overlay.message,
    })
  },
  methods: {
    emitClose() {
      this.$store.commit('overlay/SET_OVERLAY', '');
    }
  },
  components: {
    OverlayWrap,
  },
}
</script>

<style lang="scss">
  @import "~styles/frontend/base";
  @import "~styles/frontend/forms";

  .overlay.overlay--error {
    @include helper-link-underline();

    .inner {
      margin:auto;
      max-width:80%;
      padding:20px 40px;
      background:#fff;
      position:relative;
      z-index:20;
      width:520px;
      margin-bottom: 20px;
      .title {
        @include cx-m();
        text-align:center;
        padding-bottom:20px;
      }
      .message {
        .errors {
            text-align: center;
          h3 {
            // @include cx-m();
            padding-bottom: 20px;
          }
          ul {
            margin-bottom:1em;
          }
        }
      }

      .controls {
        text-align:center;
        margin-top: 2em;
        button {
          @include button-border();
          width: 100%;
          max-width: 200px;
        }
      }
    }
    @include respond-to(xs) {
      padding-top:100px;
      overflow-y:scroll;
    }
  }
</style>