export default class Help {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;

    if($('.container-page-help').length > 0) this.init();
  }
  init(is_lightbox = false) {
    this.$container = (is_lightbox) ? $('.lightbox-help') : $('.container.container-page-help');

    this._d = {
      $tab_pub : this.$container.find('.tab-pub [data-pub="tab"]'),
      $tab_sub : this.$container.find('.tab-sub [data-sub~="tab"]'),
      $chart_sub : this.$container.find('[data-sub~="sizing-chart"]'),
      chart_template : this.$container.find('#size_chart_template').html(),
      chart_data : window._data.size_charts,
      $system_pub: this.$container.find('[data-pub~="chart-system"]'),
    };
    
    radio('page/help').broadcast(this._d);

    if(this._d.$chart_sub.length > 0) {
      radio('page/help/charts').broadcast();

      //Update
      this.m.$body.on('click', this._d.$system_pub.selector, (e) => {
        var key = $(e.currentTarget).closest('.sizing-chart').attr('data-chart');
        radio('page/help/chart/update').broadcast({
          key:key
        });
      });
    }
  }
}