export default {
  system: 'metric',
  bra: {
    title: 'Bra Sizing',
    bands : [
        {
          metric:'8  (67 to 74)',
          imperial: '30  (26 38 to 29 1/8)',
          cups: [
            {cup: 'A',metric:'75.5 to 78.5', imperial: '29 3/4 to 30 15/16 '},
            {cup: 'B',metric:'78 to 81',     imperial: '30 11/16 to 31 7/8'},
            {cup: 'C',metric:'80.5 to 83.5', imperial: '31 11/16 to 31 7/8'},
            {cup: 'D',metric:'83 to 88.5',   imperial: '  32 11/16 to 33 7/8'},
            {cup: 'DD',metric:'85.5 to 88.5', imperial: '33 11/16 to 34 13/16'},
            {cup: 'E',metric:'88 to 91',     imperial: '34 5/8 to 35 13/16'},
            {cup: 'F',metric:'90.5 to 93.5', imperial: '35 5/8 to 36 13/16'},
            {cup: '',metric:'', imperial: ''},
          ]
        },
        {
          metric:'10  (73 to 80)',
          imperial: '32  (28 3/4 to 31 1/2)',
          cups: [
            {cup: 'A',metric:'81.5 to 84.5', imperial: '32 1/16 to 33 1/4'},
            {cup: 'B',metric:'84 to 87',     imperial: '33 1/16 to 34 1/4'},
            {cup: 'C',metric:'86.5 to 89.5', imperial: '34 1/16 to 35 1/4'},
            {cup: 'D',metric:'89 to 92',     imperial: '35 1/16 to 36 1/4'},
            {cup: 'DD',metric:'91.5 to 94.5', imperial: '36 to 37 3/16'},
            {cup: 'E',metric:'94 to 97',     imperial: '37 to 38 3/16'},
            {cup: 'F',metric:'96.5 to 99.5', imperial: '38 to 39 3/16'},
            {cup: 'G',metric:'99 to 102', imperial: '38 31/32 to 40 5/32'},
          ]
        },
        {
          metric:'12  (79 to 86)',
          imperial: '34  (31 1/8 to 33 7/8)',
          cups: [
            {cup: 'A',metric:'87.5 to 90.5 ', imperial: '34 7/16 to 35 5/8'},
            {cup: 'B',metric:'90 to 93',      imperial: '35 7/16 to 36 5/8'},
            {cup: 'C',metric:'92.5 to 95.5',  imperial: '36 7/16 to 37 5/8'},
            {cup: 'D',metric:'95 to 98',      imperial: '37 3/8 to 38 9/16'},
            {cup: 'DD',metric:'97.5 to 100.5', imperial: '38 3/8 to 39 9/16'},
            {cup: 'E',metric:'100 to 103',    imperial: '39 3/8 to 40 9/16'},
            {cup: 'F',metric:'102.5 to 105.5', imperial: '40 23/64 to 41 17/32'},
            {cup: '',metric:'', imperial: ''},
          ]
        },
        {
          metric:'14  (85 to 92)',
          imperial: '36  (33 7/16 to 36 1/4)',
          cups: [
            {cup: 'A',metric:'93.5 to 96.5',   imperial: '36 3/16 to 38'},
            {cup: 'B',metric:'96 to 99',       imperial: ' 37 3/16 to 39  '},
            {cup: 'C',metric:'98.5 to 101.5',  imperial: '38 3/4 to 39 15/16'},
            {cup: 'D',metric:'102 to 104',     imperial: ' 39 3/4 to 40 15/16'},
            {cup: 'DD',metric:'103.5 to 106.5', imperial: '40 3/4 to 41 15/16'},
            {cup: 'E',metric:'106 to 109', imperial: '41 47/64 to 42 29/32'},
            {cup: 'F',metric:'109 to 112', imperial: '43 to 44'},
            {cup: '',metric:'', imperial: ''},
          ]
        },
        {
          metric:'16  (91 to 98)',
          imperial: '38  (35 13/16 to 38 9/16)',
          cups: [
            {cup: '',metric:'', imperial: ''},
            {cup: 'B',metric:'102 to 105',     imperial: '40 3/16 to  41 5/16'},
            {cup: 'C',metric:'104.5 to 107.5', imperial: '41 1/8 to 42 5/16'},
            {cup: 'D',metric:'107 to 110',     imperial: '42 1/8 to 43 5/16'},
            {cup: 'DD',metric:'109.5 to 112.5', imperial: '43 7/64 to 44 19/64'},
            {cup: '',metric:'', imperial: ''},
            {cup: '',metric:'', imperial: ''},
            {cup: '',metric:'', imperial: ''},
          ]
        },
      ]
    },
    briefs: [
      {
        title: 'Natural Waist (Measurement taken around smallest part of waist)',
        sizes: [
          {size: 'XS',metric:'59 to 66', imperial: '23 1/4" to 26"'},
          {size: 'S',metric:'65 to 72', imperial: '25 9/16" to 28 3/8"'},
          {size: 'M',metric:'71 to 78', imperial: '27 15/16" to 30 11/16"'},
          {size: 'L',metric:'77 to 84', imperial: '30 5/16" to 33 1/16"'},
          {size: 'XL',metric:'83 to 90', imperial: '32 11/16" to 35 7/16"'},
          {size: '',metric:'', imperial: ''},
          {size: '',metric:'', imperial: ''},
          {size: '',metric:'', imperial: ''},
        ]
      },
      {
        title: 'Hip (measurement taken around fullest part of hip/bottom)',
        sizes: [
          {size: 'XS',metric:'87 to 94', imperial: '34 1/4" to 37"'},
          {size: 'S',metric:'93 to 100', imperial: '36 5/8" to 39 3/8"'},
          {size: 'M',metric:'  99 to 106', imperial: '39" to 41 3/4"'},
          {size: 'L',metric:'  105 to 112', imperial: '41 5/6" to 44 1/6"'},
          {size: 'XL',metric:'111 to 118', imperial: '43 11/16" to 46 7/16"'},
          {size: '',metric:'', imperial: ''},
          {size: '',metric:'', imperial: ''},
          {size: '',metric:'', imperial: ''},
        ]
      },
    ]
}