export default class Animate {
  scrollTo(new_options) {
    var options = {
      $el     : false,
      scrollTop: false,
      offset  : 0,
      time    : 500,
      callback : false
    }

    $.extend(true, options, new_options);

    if(options.$el !== false) {
      $('html, body').animate({
        scrollTop: options.$el.offset().top + options.offset
      }, options.time, () => {
        if(typeof(options.callback) === 'function') {
          options.callback();
        }
      });
    } else if(options.scrollTop !== false) {
      $('html, body').animate({
        scrollTop: options.scrollTop
      }, options.time, () => {
        if(typeof(options.callback) === 'function') {
          options.callback();
        }
      });
    }
  }
}