<template>
  <button
    :disabled="disabled"
    class="currency-toggle"
    @click.prevent="$emit('click')"
  >
    {{ locationName }}
  </button>
</template>

<script>
export default {
  props: {
    location: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    locationName() {
      switch (this.location) {
        case 'GB':
          return 'UK';
        case 'HK':
          return 'ROW';
        default:
          return this.location;
      }
    },
  },
};
</script>
