export default {
  incrementSlide: (state,interrupt = false) => {
    var slide_current = state.page_home.slide_current + 1;
    state.page_home.slide_current = (slide_current >= state.page_home.slides_raw.length) ? 0 : slide_current;
    if(interrupt) state.page_home.auto_slide = false;
  },
  scroll: (state) => {
  	//Scroll to 1 x page_height
  	var page_height = state.window.height;
  	$("body").velocity("scroll", { duration: 1600, easing: [0.86, 0, 0.07, 1], offset: page_height, mobileHA: false })
  }
}