<template>
  <div class="component-size-tabs">
    <div class="size-tabs-inner">
      <section class="tab-controls">
        <slot 
          name="header" 
          :current="currentTab"
          :update="updateCurrent">
        </slot>
      </section>
      <section class="tabs">
        <nav class="tab-block-nav">
          <ul class="block-headings">
            <li 
              class="block-heading"
              v-for="(block, blockIndex) in blockNavigation "
              :class="{ current: blockIndex === blockScrolled }"
              @click="scrollTo(block.offsetTop, 300)">
                <span class="block-heading-label">{{ block.heading }}</span>
            </li>
          </ul>
        </nav>
        <div class="tabs-inner">
          <slot 
            name="content"
            :current="currentTab"
            :unit="unit"
            :updateUnit="updateUnit">
          </slot>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: ["scroll"],
  data() {
    return {
      currentTab: "lingerie",
      unit: "metric",
      blockNavigation: [],
      blockScrolled: null
    };
  },
  watch: {
    scroll(newScroll) {
      this.setBlocksScrolled(newScroll);
    }
  },
  created() {},
  mounted() {
    this.setBlockNav();
    this.setBlocksScrolled(this.scroll);
  },
  computed: {},
  methods: {
    updateCurrent(newTab) {
      this.currentTab = newTab;
      Vue.nextTick(() => this.setBlockNav());
    },
    updateUnit(unit) {
      this.unit = unit;
    },
    setBlockNav() {
      const tabBlocks = this.$el.querySelectorAll(".tab-block");
      const navigationData = Array.prototype.slice
        .call(tabBlocks)
        .map(block => {
          const headingElement = block.querySelector(".tab-block-heading");
          if (!headingElement) return;
          const classList = block.classList.value.split(" ").join(".");
          const heading = headingElement.textContent;
          const offsetTop = block.offsetTop;
          return {
            heading,
            classList,
            offsetTop
          };
        })
        .filter(block => block);

      this.blockNavigation = navigationData;
    },
    setBlocksScrolled(scrollY) {
      let tops = this.blockNavigation.filter(
        block => block.offsetTop < scrollY
      ).length;

      this.blockScrolled = tops;
    },
    scrollTo(elementY, duration) {
      const startingY = window.pageYOffset;
      const diff = elementY - startingY;
      let start;

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    }
  },
}
</script>