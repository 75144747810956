export default class Default {
  constructor(main, spree) {
    this.u = main.utils;
    this.c = spree;

    //Default ajax settings
    this._defaults = spree._defaults;
  }
 
  countries(data) {
    this.c.ajax({
      type : 'GET',
      url: '/feed/countries.json',
      data: {},
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
    });
  }
 
  states(data) {
    this.c.ajax({
      type : 'GET',
      url: '/feed/states.json',
      data: (data.country_id) ? {country_id:data.country_id} : {},
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
    });
  }
 
  coupon(data) {
    this.c.ajax({
      url: '/feed/coupon.json',
      data: data.data,
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
      error : (d) => {
        if(typeof(data.error) == 'function') data.error(d);
      },
    });
  }
 
  order(data) {
    this.c.ajax({
      type : 'GET',
      url: '/feed/order.json',
      data: (data.country_id) ? {country_id:data.country_id} : {},
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
    });
  }
 
  address(data) {
    data.data['state'] = 'address';
    this.c.ajax({
      url: '/feed/checkout/address.json',
      data: data.data,
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
      error : (d) => {
        if(typeof(data.error) == 'function') data.error(d);
      },
    });
  }
 
  delivery(data) {
    data.data['state'] = 'delivery';
    this.c.ajax({
      url: '/feed/checkout/delivery.json',
      data: data.data,
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
      error : (d) => {
        if(typeof(data.error) == 'function') data.error(d);
      },
    });
  }
 
  payment(data) {
    data.data['state'] = 'payment';
    this.c.ajax({
      url: '/feed/checkout/payment.json',
      data: data.data,
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
      error : (d) => {
        if(typeof(data.error) == 'function') data.error(d);
      },
    });
  }
 
  confirm(data) {
    data.data['state'] = 'confirm';
    this.c.ajax({
      url: '/feed/checkout/confirm.json',
      data: data.data,
      callback : (d) => {
        if(typeof(data.success) == 'function') data.success(d);
      },
      error : (d) => {
        if(typeof(data.error) == 'function') data.error(d);
      },
    });
  }
}