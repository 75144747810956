<template>
  <span class="price-display">
    <price-format
      v-if="showOriginalPrice"
      :currency="displayCode"
      :price="originalDisplayPrice"
      :show-code="showCode"
      class="price-format--original"
    />

    <price-format
      :currency="displayCode"
      :price="displayPrice"
      :show-code="showCode"
    />
  </span>
</template>

<script>
import PriceFormat from '~/components/prices/price-format'
import currency from '~/mixins/currency'

const convertCurrency = (price, rate) => price * rate
const hasPriceProperty = (priceObject) =>
  Object.prototype.hasOwnProperty.call(priceObject, 'price')
const isNumber = (stringOrNumber) => !Number.isNaN(parseFloat(stringOrNumber))

export default {
  components: {
    PriceFormat,
  },

  mixins: [currency],

  props: {
    prices: {
      type: [Array, Number, String],
      required: true,
      validator(value) {
        if (typeof value === 'number' || typeof value === 'string') {
          return isNumber(value)
        }
        if (Array.isArray(value)) {
          return value.every(
            (priceItem) =>
              typeof priceItem === 'object' &&
              hasPriceProperty(priceItem) &&
              isNumber(priceItem.price)
          )
        }
        return false
      },
    },
    showCode: {
      type: Boolean,
      default: true,
    },
    useBaseRate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    displayCode() {
      return this.useBaseRate ? this.currencyBase : this.currencyDisplay
    },
    displayPrice() {
      return this.useBaseRate
        ? this.price
        : convertCurrency(this.price, this.currencyDisplayRate)
    },
    originalPrice() {
      return this.priceData && parseFloat(this.priceData.original_price)
    },
    originalDisplayPrice() {
      return this.useBaseRate
        ? this.originalPrice
        : convertCurrency(this.originalPrice, this.currencyDisplayRate)
    },
    price() {
      return this.priceData && parseFloat(this.priceData.price)
    },
    priceData() {
      if (typeof this.prices === 'number' || typeof this.prices === 'string') {
        return { price: this.prices }
      }

      console.log('priceData', {
        currencyDisplay: this.currencyDisplay,
        currencyBase: this.currencyBase,
        prices: this.prices,
      })

      return (
        this.prices.find((price) => price.currency === this.currencyDisplay) ||
        this.prices.find((price) => price.currency == this.currencyBase) ||
        this.prices[0]
      )
    },
    showOriginalPrice() {
      return this.originalPrice > this.price
    },
  },
}
</script>
