import ImageLoader from '~/components/common/image-loader';
import AudioPlayer from './audio-player';

const resizeDict = (alignment) => {
  switch (alignment) {
    case 'half-width':
      return 'w=900';
    case 'small-centred':
      return 'w=450';
    default:
      return '';
  }
};

export default {
  name: 'ContentfulMediaParser',

  functional: true,

  props: {
    media: {
      type: Object,
      required: true,
    },
    alignment: {
      type: String,
      default: '',
    },
    transforms: {
      type: String,
      default: '',
    },
    showCaption: {
      type: Boolean,
      default: false,
    },
  },

  render(h, ctx) {
    const { props, data } = ctx;
    const {
      media,
      alignment,
      transforms,
      showCaption,
    } = props;

    const { file, title, description } = media.fields;

    const isVideo = /\.mp4|webm$/.test(file.url);
    const isAudio = /\.mp3$/.test(file.url);


    let Component = '';
    let defaultTransforms = '';
    let defaultResizeTransforms = '';
    let width = null;
    let height = null;

    if (isVideo) {
      // Component = '';
    } else if (isAudio) {
      return (
        <AudioPlayer
          src={file.url}
          title={title}
          description={description}
        />
      );
    } else {
      Component = ImageLoader;
      defaultTransforms = 'fm=jpg&q=85';
      defaultResizeTransforms = resizeDict(alignment);
      height = file.details.image.height;
      width = file.details.image.width;
    }

    const transformString = [defaultTransforms, defaultResizeTransforms, transforms].join('&');
    const asset = {
      src: `${file.url}?${transformString}`,
      alt: title,
    };

    return (
      <figure class={`ctf-media ${data.class || ''}`}>
        <Component
          src={asset.src}
          alt={asset.alt || ''}
          height={height}
          width={width}
        />
        {!!description && showCaption
           && <figcaption class="ctf-media__caption">{description}</figcaption>
        }
      </figure>
    );
  },
};
