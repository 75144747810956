// import ImageLoader from '~/components/common/image-loader';
import ContentfulImage from '~/components/common/contentful-image';

export default {
  name: 'contentful-asset',

  functional: true,

  props: {
    description: {
      type: String,
      default: '',
    },
    file: {
      type: Object,
      default: () => ({
        contentType: '',
        details: {},
        fileName: '',
        url: '',
      }),
    },
    fit: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: null,
    },
  },

  render(h, { props }) {
    const { file } = props;

    switch (file.contentType) {
      case 'image/jpeg':
        return h(ContentfulImage, { props: { ...props } });
      default:
        return null;
    }
  },
};
