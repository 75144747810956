export default class Stores {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    if($('.container-page-stores').length > 0) this.init();
  }
  init() {
    var $select_pub = $('[data-pub~=select-store]');
    var $select_sub = $('[data-sub~=select-store]');

    radio('page/stores').broadcast({
      $select_pub:$select_pub,
      $select_sub:$select_sub,
    });

    $select_pub.on('click', (e) => {
      var $pub  = $(e.currentTarget);
      var store = $pub.attr('data-store');
      var $sub  = $select_sub.filter(`[data-store=${store}]`);

      radio('page/stores/select').broadcast({
        $pub:$pub,
        $sub:$sub,
        store:store
      });
    });

    //Select currently active hash if there is one
    var store = window.location.hash.slice(1);
    var $hash_pub = $select_pub.filter(`[data-store="${store}"]`);

    if($hash_pub.length > 0) {
      //Select that hash
      $hash_pub.trigger('click');
    } else {
      //Select first hash
      $select_pub.first().trigger('click');
    }
  }
}