<template>
  <section class="product-column">
    <div
      class="product-column__inner"
      :class="{ 'product-column__inner_sticky': !overflow }"
    >
      <slot />
    </div>
  </section>
</template>

<script>
import resize from '~/mixins/resize';

export default {
  mixins: [resize],

  data() {
    return {
      overflow: false,
    };
  },

  computed: {
    child() {
      return this.$children[0];
    },
  },

  methods: {
    onResize() {
      const { child } = this;
      if (!child) return;

      this.overflow = child.$el.offsetTop + child.$el.offsetHeight > window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
@import "~styles/frontend/base";

.product-column {
  @include cx-xs();

  position: absolute;
  top: -90px;
  bottom: 0;
  left: 0;
  width: calc(#{calcPercentage(12, 3)} + #{$gutter});
  max-width: 100%;
  padding: 0 $gutter;
  text-align: center;
  z-index: 50;

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: auto;

    &_sticky {
      position: sticky;
      top: 0;
    }

    > * {
      width: 100%;
      margin: auto;
      padding: 90px 0;
    }
  }

  &_purchase {
    right: 0;
    margin-left: auto;
  }

  @media (max-width: $breakpoint_m) {
    position: static;
    width: auto;

    &__inner {
      padding-top: 60px;

      &_sticky {
        position:static;
        min-height: 0;
      }

      > * {
        padding: 0;
      }
    }
  }
}
</style>
