import Base from '../base';

export default class Default extends Base {
  init() {
    if(!$('.element-sidebar-collection').is('*')) return;
    
    radio('vue/loaded').subscribe(() => {
      this.updateEl();
    });
  }

  updateEl() {
    this.el = {
      sidebar:    $('#sidebar_collection')[0],
      collection: $('#collection')[0]
    };  

    if(this.el.sidebar)  {
      radio('window/scroll').subscribe(() => {
        this.onScroll();
      });
    }
  }

  onScroll() {
    if(!this.el.sidebar) return;
    var sidebar_top       = 150;
    var collection_top    = 150;
    var sidebar_height    = this.el.sidebar.getBoundingClientRect().height;
    var collection_height = this.el.collection.getBoundingClientRect().height;
    var window_height     = this.s.window.height;
    var blocks            = this.el.collection.querySelectorAll('.block');
    var blocks_last       = Array.prototype.slice.call(blocks).slice(-1)[0];
    var last_top          = blocks_last.offsetTop;
    var scroll_top        = this.s.window.scroll_top;
    var sidebar_pos       = scroll_top - sidebar_top;
    var trigger_point     = (collection_height - collection_top) - (sidebar_height - sidebar_top);
    var state             = -1;

    // if (scroll_top < trigger_point || trigger_point < 0) {
    if (sidebar_pos + sidebar_top < last_top) {
      state = 1;
    } else {
      state = 2;
    }

    if (this.s.sidebar_collection.state == state) return;

    if (state == 1) {
      this.s.sidebar_collection.style = {
        position:'fixed',
        top:'auto'
      };
    } else {
      var offset_top = last_top + 150;
      this.s.sidebar_collection.style = {
        top: last_top + 150 + 'px',
        position:'absolute'
      };
    }

    this.s.sidebar_collection.state = state;
  }
} 