<template>
  <location-menu
    :locations="locations"
    class="mob-location-menu"
    @update="$emit('update', $event)"
  />
</template>

<script>
import LocationMenu from '~/components/header/location-menu';

export default {
  components: {
    LocationMenu,
  },

  props: {
    locations: {
      type: Array,
      default: () => [
        {
          iso: '',
          name: '',
        },
      ],
    },
  },

  data() {
    return {
      outsideClickHandler: null,
    };
  },

  mounted() {
    this.outsideClickHandler = (e) => {
      if (e.target.closest('.location-menu') || e.target.closest('.header-mobile__currency-toggle')) return;
      this.$emit('toggle');
    };

    document.addEventListener('click', this.outsideClickHandler);
  },

  destroyed() {
    document.removeEventListener('click', this.outsideClickHandler);
  },
};
</script>

<style lang="scss">
@import "~styles/frontend/variables";
@import "~styles/frontend/helpers";
@import "~styles/frontend/fonts";
@import "~styles/frontend/typography";

.mob-location-menu {
  @include cx-xs();
  @include sans();

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 $gutter;
  z-index: -10;
  background: #fff;
  border-bottom: 1px solid $lightestgrey;

  &.slide-down-enter-active, &.slide-down-leave-active {
    transition: transform .3s $easing;
  }

  &.slide-down-enter, &.slide-down-leave-to {
    transform: translateY(-100%);
  }

  .location-menu {
    &__location {
      position: relative;
    }
  }

  .location-menu__location + .location-menu__location {
    &:before {
      content: '/';
      position: absolute;
      top: 0;
      left: 0;
      color: $lightestgrey;
    }
  }

  .location-select {
    color: $lightgrey;
    position: relative;
    margin: 0 10px;
    line-height: 1.4;
    border-bottom: 1px solid transparent;

    &--active {
      color: $dark;
      border-color: $lightestgrey;
    }
  }
}
</style>
