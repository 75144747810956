export default class Home {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    if($('.container-page-home').length > 0) this.init();
  }
  init() {
    var $updates = $('#updates');

    radio('home').broadcast({ 
      $updates  : $updates,
    });
  }
}