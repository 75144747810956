import Tabs from '../../utils/tabs';

export default class Faq {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    radio('page/faq').subscribe((data)  => {this.faq(data);});
  }
  
  faq(data) {
    this.tabs = new Tabs({
      $tab_pub : data.$tab_pub,
      $tab_sub : data.$tab_sub,
    });
  }
}

