export default class Load {
  constructor() {
    this.$window = $(window);
  }
  
  pub() {
    this.$window.on('load', (e) => {
      this.onLoad(e);
    });
  }

  onLoad(e) {
    radio('window/load').broadcast({
      
    });
  }
}