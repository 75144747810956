import DiaryBlock from './diary-block';

export default {
  name: 'DiaryIndex',

  components: {
    DiaryBlock,
  },

  props: {
    entries: {
      type: Array,
      required: true,
    },
  },

  template: /* html */ `
    <div class="diary-index">
      <DiaryBlock
        v-for="entry in entries"
        :key="entry.slug"
        :entry="entry"
      />
    </div>
  `,
};
