import Base from '../base';

export default class Default extends Base {
  init() {

    if(this.d.body_id !== 'page-home') return;


    //Start timer for slideshow
    radio('vue/loaded').subscribe(() => {
      setTimeout(()=>{
        this.m.page_home.incrementSlide(this.s);
        setInterval(()=>{
          if(this.s.page_home.auto_slide) this.m.page_home.incrementSlide(this.s);
        },3000);
      }, 1000);
    });
  }
} 