import {
  SET_OVERLAY,
  SET_OVERLAY_MESSAGE,
} from './mutation-types';

const overlayState = () => ({
  current: '',
  message: '',
})


const mutations = {
  [SET_OVERLAY](state, overlay) {

    if(overlay == 'mmenu') {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }

    state.current = overlay;
  },
  [SET_OVERLAY_MESSAGE](state, message) {
    state.message = message;
  },
}



export default {
  namespaced: true,
  state: overlayState,
  mutations,
}
