<template>
  <label class="product-selection-input" :class="{ selected }">
    <input
      type="radio"
      :checked="selected"
      :name="name"
      :value="label"
      @select="$emit('select')"
      @input="$emit('input', $event.target.value)"
    />
    <span :selected="selected"
      ><slot>{{ label }}</slot></span
    >
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    selected() {
      return this.label === this.value
    },
  },
}
</script>

<style lang="scss" scoped>
.product-selection-input {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 5px 10px 5px;
  cursor: pointer;
}

.unavailable,
.unpurchasable {
  opacity: 0.4;
}

.unpurchasable {
  text-decoration: line-through;
}

input {
  position: absolute;
  opacity: 0;
}

span {
  display: block;
  width: 100%;
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
}

input:checked + span {
  border-color: currentColor;
}
</style>
