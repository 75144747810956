<template>
  <ul class="location-menu">
    <li
      v-for="location in locations"
      :key="location.iso"
      class="location-menu__location"
    >
      <location-select
        :iso="location.iso"
        :name="location.name"
        @click="$emit('update', location.iso)"
      />
    </li>
  </ul>
</template>

<script>
import LocationSelect from '~/components/header/location-select';

export default {
  components: {
    LocationSelect,
  },

  props: {
    locations: {
      type: Array,
      default: () => [
        {
          iso: '',
          name: '',
        },
      ],
    },
  },
};
</script>

<style lang="scss">
.location-menu {
  font-size: inherit;
}
</style>
