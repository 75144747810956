// TODO: Make this its own Vue instance.
// TODO: Use prop sync on toggles after above is done.

export default {
  name: 'mobile-menu',

  props: {
  },

  data() {
    return {
      activeSubmenu: '',
    };
  },

  methods: {
    updateActiveSubmenu(newSubmenu) {
      if (newSubmenu.active) {
        this.activeSubmenu = newSubmenu.name;
      }
    },
  },

  template: /* html */ `
    <div class="mobile-menu-component">
      <slot
        :active-submenu="activeSubmenu"
        :update-active-submenu="updateActiveSubmenu"
      ></slot>
    </div>
  `,
};
