<template>
  <div class="product-afterpay">
    or 4 payments of
    <price-display
      :prices="priceQuotients"
      :show-code="false"
    />
    with Afterpay <br>
    <button
      class="product-afterpay__button"
      @click="$emit('show')"
    >
      more info
    </button>
  </div>
</template>

<script>
import PriceDisplay from '~/components/prices/price-display';

export default {
  components: {
    PriceDisplay,
  },

  props: {
    prices: {
      type: Array,
      default: () => [],
    },
    gstNote: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 4,
    },
  },

  computed: {
    priceQuotients() {
      return this.prices.map((price) => ({
        currency: price.currency,
        price: price.price / this.count,
      }));
    },
  },
};
</script>

<style lang="scss">
.product-afterpay {
  font-size: 12px;
  margin-top: 1em;

  &__button {
    font-size: inherit;
    line-height: inherit;
    border-bottom: 1px solid #DDDDDC;

    &:focus {
      outline: none;
      border-color: currentColor;
    }
  }

  .product-price {
    display: inline-block;
  }
}
</style>
