import MediaParser from '../diary/contentful-media-parser';

export default {
  props: {
    blocks: {
      type: Array,
    },
  },
  computed: {
    getRows() {
      const blocks = this.blocks
        .map((v) => v.fields)
        .map((v) => ({
          image: v.image,
          caption: v.caption,
          link: v.link,
        }));

      return _.chunk(blocks, 2);
    },
  },
  methods: {
    getBlockClass(k, _k) {
      return `block-${k}_${_k}`;
    },
  },
  template: /* html */ `
    <section class="updates">
      <div class="blocks blocks-home">
        <div class="row" v-for="(row, k) in getRows" :key="'home_row_'+k">
          <div class="block" v-for="(block, _k) in row" :class="getBlockClass(k,_k)"  :key="'home_block_' + k + _k">
            <a class="cover-link" :href="block.link" :aria-label="block.image.title"></a>

            <div class="image">
              <MediaParser :media="block.image" transforms="w=1600" />
            </div>

            <div class="details">
              <div class="description" v-html="block.caption">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  `,
  components: {
    MediaParser,
  },
};
