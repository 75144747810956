import {
  SET_CART_DATA,
  SET_CART_RELATED_DATA,
  SET_CART_SPECIAL_INSTRUCTIONS,
  SET_CART_STATUS,
} from './mutation-types'

import cartApi from '~/../api/cart'

const cartState = () => ({
  cartData: false,
  relatedData: false,
  specialInstructions: '',
  status: '',
})

const actions = {
  async add({ dispatch, commit }, { variant_id, quantity }) {
    commit(SET_CART_STATUS, 'adding')
    await cartApi.add({ variant_id, quantity })
    dispatch('getRelated', { variant_id })

    await dispatch('refresh')
    commit(SET_CART_STATUS, 'added')

    setTimeout(() => {
      commit(SET_CART_STATUS, '')
    }, 2000)
  },

  async remove({ dispatch, commit }, { variant_id, quantity }) {
    commit(SET_CART_STATUS, 'removing')
    await cartApi.remove({ variant_id, quantity })

    await dispatch('refresh')
    commit(SET_CART_STATUS, '')
  },

  async getRelated({ commit }, { variant_id }) {
    const related = await cartApi.related({ variant_id })
    commit(SET_CART_RELATED_DATA, related)
  },

  async instructions({ state }) {
    await cartApi.instructions({
      special_instructions: state.specialInstructions,
    })

    // Should potentially live elsewhere
    window.location = '/checkout'
  },

  async refresh({ commit }) {
    const cartData = await cartApi.refresh()

    commit(SET_CART_DATA, cartData)
    commit(SET_CART_SPECIAL_INSTRUCTIONS, cartData.special_instructions)
  },
}

const mutations = {
  [SET_CART_DATA](state, cartData) {
    state.cartData = cartData
  },
  [SET_CART_RELATED_DATA](state, cartRelatedData) {
    state.cartRelatedData = cartRelatedData
  },
  [SET_CART_SPECIAL_INSTRUCTIONS](state, specialInstructions) {
    state.specialInstructions = specialInstructions
  },
  [SET_CART_STATUS](state, status) {
    state.status = status
  },
}

const getters = {
  itemCount(state) {
    if (!state.cartData) return 0
    return state.cartData.line_items.length
  },
  status(state) {
    return state.status
  },
}

export default {
  namespaced: true,
  state: cartState,
  mutations,
  actions,
  getters,
}
