import Base from '../base'

export default class Default extends Base {
  init() {
    if (this.d.body_id !== 'product-show') return

    radio('vue/loaded').subscribe((d) => {
      this.initVariant()
    })
    radio('vue/loaded').subscribe((d) => {
      this.stores(d)
    })

    radio('product/buy').subscribe((d) => {
      this.buy(d)
    })
    radio('product/stores').subscribe((d) => {
      this.stores(d)
    })
    radio('product/notification').subscribe((d) => {
      this.notification(d)
    })

    // radio('window/scroll').subscribe((d)  => {this.onScroll(d);});
    radio('window/resize').subscribe((d) => {
      this.onResize(d)
    })
    // radio('window/resize').subscribe((d) => {this.onScroll(d);});
    radio('window/layout').subscribe((d) => {
      this.onResize(d)
    })
  }

  initVariant() {
    //Select first variant (this could be first variant in stock)
    var variant = this.d.product.variants[0]

    //Set variant if there is only one option
    var is_unbuyable = this.v.product.hasTag(this.d, 'unbuyable')
    if (this.d.product.variants.length == 1)
      this.s.product.selected_variant = variant

    //Handle unbuyables (find in store button only)
    if (is_unbuyable) {
      if (this.d.product.variants.length == 1) {
        this.s.product.selected_variant = variant
      } else {
        this.s.product.selected_variant = this.d.product.master
      }
    }

    //Set options
    this.s.product.selected_options = this.formatOptions()

    //Initialise store
    if (this.s.product.selected_variant) {
      radio('product/stores').broadcast({
        sku: this.s.product.selected_variant.sku,
      })
    }

    //If there is no match products, select colours
    if (!this.d.product.match || this.d.product.match.length == 0)
      this.s.product.tab.related = 'colours'
  }

  async buy(d) {
    var variant_id = this.s.product.selected_variant?.id

    if (typeof variant_id != 'undefined') {
      await this.main.vm.$store.dispatch('cart/add', {
        variant_id,
        quantity: 1,
      })
      this.main.vm.$store.commit('overlay/SET_OVERLAY', 'cart')
    } else {
      if (this.v.product.formatSizing(this.s, this.v, this.d.product)) {
        //Sizing reminder
        this.s.product.reminder =
          typeof this.s.product.selected_sizes['Band'] == 'undefined'
            ? 'Band'
            : 'Cup'
      } else {
        //Get unselected option
        var raw_options = this.v.product.formatOptions(
          this.s,
          this.v,
          this.d.product
        )
        var selected_options = this.s.product.selected_options

        var options = _.filter(raw_options, (ov) => {
          return !selected_options[ov.type.name]
        })

        if (options.length > 0) {
          this.s.product.reminder = options[0].type.name
        }
      }
    }
  }

  formatOptions() {
    var options = {}
    var is_unbuyable = this.v.product.hasTag(this.d, 'unbuyable')
    var raw_options = this.v.product.formatOptions(
      this.s,
      this.v,
      this.d.product
    )

    _.each(raw_options, (ov) => {
      //Preselect if there is only one value
      if (ov.values.length > 1 && !is_unbuyable) return

      options[ov.type.name] = ov.values[0]
    })

    return options
  }

  notification(d) {
    var email = this.s.product.notification_email
    var name = this.d.product.name
    var selected_options = this.s.product.selected_options
    var selected_sizes = this.s.product.selected_sizes

    var error = false

    this.s.product.notification_sending = true
    this.s.product.notification_error = false

    var opts = false
    if (selected_sizes['Cup'] && selected_sizes['Band']) {
      opts = `${selected_sizes['Band']}/${selected_sizes['Cup']}`
    } else if (selected_options['Size']) {
      opts = selected_options['Size']['presentation']
    }

    // Notifiable only works on sized products
    if (!opts) {
      error = 'Select some options'
    }
    if (!email) {
      error = 'Please check your email address'
    }

    if (error) {
      this.s.product.notification_error = error
      this.s.product.notification_sending = false
      return
    }

    this.u.ajax.post.send({
      url: window._data.product_url + '/notification',
      data: {
        email: email,
        product: name,
        options: opts,
      },
      callback: (data) => {
        this.s.product.notification_sending = false
        if (data.success) {
          this.s.product.notification_sent = data.success
          this.s.product.notification_error = false
        }

        if (data.error) {
          this.s.product.notification_error = data.error
        }
      },
    })
  }

  stores(d) {
    this.s.product.stores = _.map(this.d.outlet_feed, (store, key) => {
      store.name = key
      store.stock = 'Not in Stock'
      store.stock_class = 'out-of-stock'
      return store
    })

    // Skip unless sku requested
    if (!d || !d.sku) return

    const showProductSize = (variant) => {
      const option = variant?.option_values.find(
        (v) => v.option_type_name == 'Size'
      )
      return option?.name || ''
    }

    this.u.ajax.post.send({
      url: window._data.product_url + '/stock',
      data: {
        sku: d.sku,
      },
      callback: (data) => {
        if (data.length > 0) {
          this.s.product.stores = _.chain(data)
            .map((v) => {
              var outlet_handle = v.outlet_name.toLowerCase().replace(' ', '-')
              var outlet_feed = this.d.outlet_feed[outlet_handle]

              if (!outlet_feed) return null

              return {
                name: v.outlet_name,
                size: showProductSize(this.s.product.selected_variant),
                stock: v.count > 0 ? 'In Stock' : 'Out of Stock',
                stock_class: v.count > 0 ? 'in-stock' : 'out-of-stock',
                address: outlet_feed.address,
                contact: outlet_feed.contact,
                hours: outlet_feed.hours,
                image: outlet_feed.image,
                map: outlet_feed.map,
              }
            })
            .sortBy('name')
            .compact()
            .value()

          // Preselect first store
          this.s.product.selected_store =
            this.s.product.stores.length > 0 ? this.s.product.stores[0] : false
        }
      },
    })
  }
  onResize(d) {
    if ($('[data-el="product-wrap"]').size == 0) return
    var c_bottom =
      $('[data-el="product-wrap"]').offset()?.top +
      $('[data-el="product-wrap"]').height()
    var d_height = $('[data-el="product-details"]').height()
    var p_height = $('[data-el="product-purchase"]').height()
    var content_height = Math.max(d_height, p_height)

    // Too tall if content > window height
    this.s.product.sticky.too_tall = content_height > d.height
    this.s.product.sticky.too_wide = false // d.width > 900;

    // Save footer top for scroll function
    this.s.product.sticky.product_bottom = c_bottom
  }
}
