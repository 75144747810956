import Base from '../base'

export default class Default extends Base {
  init() {
    //Update standards
    radio('conversion/unit').subscribe((d) => {
      this.setStandard(d)
    })

    //Load standards
    radio('vue/loaded').subscribe(() => {
      this.sendStandard()
    })
    radio('currency/update').subscribe(() => {
      this.sendStandard()
    })
  }

  sendStandard() {
    //Pick metric or imperial based on currency
    var imperial_locations = ['US', 'GB', 'CA']
    var location = this.s.location.current
    if (_.includes(imperial_locations, location)) {
      radio('conversion/unit').broadcast({ standard: 'imperial' })
    } else {
      radio('conversion/unit').broadcast({ standard: 'metric' })
    }
  }

  setStandard(d) {
    this.s.app.standard = d.standard
  }
}
