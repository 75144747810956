<template>
  <overlay-wrap name="login">
    <slot />
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex';
import OverlayWrap from '~/components/overlay/wrap';

export default {
  computed: {
    ...Vuex.mapState({
      overlay: state => state.overlay.current,
    }),
  },
  components: {
    OverlayWrap,
  },
}
</script>

<style lang="scss">
  @import "~styles/frontend/base";
  @import "~styles/frontend/forms";

  .overlay.overlay--login {
    @include form-login();
    @include helper-lightbox-close();
    form {
      width:300px;
      max-width:300px;
      legend {
        @include hx-m();
      }
      fieldset {
        &.remember {
          display:none;
        }
      }
    }
  }
</style>