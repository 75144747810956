export default {
  showProperty: (product_data, name) => {
    const property = _.find(
      product_data.product_properties,
      (prop) => prop.property_name == name
    )

    return property ? property.value : false
  },
  formatSlides: (product_data, key = 'desktop') => {
    const slides = _.map(product_data.images, (img) => ({
      url: img.product_gallery_url,
    }))

    return key == 'mobile' ? slides.slice(1) : slides
  },
  showImage: (product_data, key = 'product_url', idx = 0) =>
    product_data.images[idx] && product_data.images[idx][key],
  showTabClass: (state, key, val) =>
    state.product.tab[key] == val ? 'active' : '',
  getTabActive: (state, key, val) => state.product.tab[key] == val,
  formatOptions: (state, view, product_data) => {
    const options = _.chain(product_data.option_types)
      .map((ot, ot_idx) => {
        // Grab all options with current option type
        const option_values = _.chain(product_data.variants)
          .map((v) =>
            _.find(v.option_values, (ov) => ov.option_type_name == ot.name)
          )
          .uniqBy('id')
          .compact()
          .value()

        return {
          type: ot,
          values: option_values.reverse(),
        }
      })
      .filter((option) => {
        const { name } = option.values[0]
        // Filter in all options except size
        if (option.type.name !== 'Size') return true
        // Filter in all options that don't have letters
        if (!name.match(/[a-z]/i)) return true
        // Filter size options that dont start with a number
        return isNaN(name[0])
      })
      .value()

    return options
  },
  showOptionClass: (data, state, view, type, value) => {
    const classes = {}

    const compare_options = _.clone(state.product.selected_options)
    compare_options[type] = value

    // Attempt to find variant that the options match
    const selected_variant = _.find(data.product.variants, (v) => {
      // Format variant options
      const v_options = {}
      _.each(v.option_values, (ov) => {
        v_options[ov.option_type_name] = ov
      })
      app

      return _.isEqual(compare_options, v_options)
    })

    if (selected_variant) {
      const variant_state = view.product.variantState(state, selected_variant)
      if (variant_state.match(/buyable/g)) classes.available = true
    }

    classes.active = state.product.selected_options[type] == value

    return classes
  },
  formatSizing: (state, view, product_data) => {
    const sizeType = view.product.getSizeType // Function that gets bra or cup

    // Check if product is a bra by seeing if there is a variant
    // That has a NUMBER at the beginning of it's SIZE option
    const is_bra = _.find(product_data.variants, (v) =>
      _.find(
        v.option_values,
        (ov) =>
          ov.option_type_name === 'Size' &&
          !isNaN(ov.name[0]) &&
          ov.name.match(/[a-z]/i)
      )
    )

    // No sizing on this product
    if (!is_bra) return false

    // Get list of size options to split into bras and cups
    const size_options = _.map(
      product_data.variants,
      (v) => _.find(v.option_values, (ov) => ov.option_type_name == 'Size').name
    )

    // USD gets imperial sizing
    const { standard } = state.app

    const options = [
      {
        type: 'Band',
        values: _.uniq(
          size_options.map((option) => sizeType(option, 'Band', standard))
        ).sort((a, b) => a - b),
      },
      {
        type: 'Cup',
        values: _.chain(size_options)
          .map((o) => sizeType(o, 'Cup', standard))
          .uniq()
          .sort()
          .value(),
      },
    ]

    return options
  },
  showSizingClass: (data, state, view, type, value) => {
    const classes = {}
    const sizeType = view.product.getSizeType
    const { standard } = state.app
    // See if current value combination is selected
    // If cup, filter by available bands
    if (type == 'Cup') {
      const band = state.product.selected_sizes.Band
      // Is this cup available for this band?
      const cup = _.find(data.product.variants, (v) => {
        const option_values = _.filter(
          v.option_values,
          (v) => v.option_type_name == 'Size'
        )
        return _.find(
          option_values,
          (ov) =>
            // Cup exists if this variant has the same band and cup
            sizeType(ov.name, 'Band', standard) == band &&
            sizeType(ov.name, 'Cup', standard) == value
        )
      })

      if (cup) {
        if (cup.track_inventory == true && cup.total_on_hand == 0)
          classes.sold = true
        classes.available = true
      }
    } else {
      classes.available = true
    }

    classes.active = state.product.selected_sizes[type] == value

    return classes
  },
  getSizeType: (value, type, standard = 'metric') => {
    // Bra Sizes are in the format metric/imperial
    const standard_idx = standard !== 'imperial' ? 0 : 1
    const size = value.split('/')[standard_idx]

    // Band is number, cup is letter
    if (type === 'Band') {
      // Replace letters with nothing
      return size.replace(/[a-zA-Z]/g, '')
    }

    // Replace numbers with nothing
    return size.replace(/[0-9]/g, '')
  },
  showSelectedSize: (state, type = false) => {
    // Try band + cup
    if (type && state.product.selected_sizes[type]) {
      return state.product.selected_sizes[type]
    }
    if (state.product.selected_sizes.Band && state.product.selected_sizes.Cup) {
      // Convert Imperial back to metric

      return `${state.product.selected_sizes.Band}${state.product.selected_sizes.Cup}`
      // Else: Try option size
    }
    if (state.product.selected_options.Size) {
      return state.product.selected_options.Size.name
    }

    // Give up
    return ''
  },
  // showBuyMessage: (view, state) => {
  //   const variant_state = view.product.variantState(state)

  //   // Adding to cart
  //   let string = 'Add to Cart'
  //   if (window.app.vm.$store.getters['cart/status'] === 'adding')
  //     string = 'Adding to Cart'
  //   if (window.app.vm.$store.getters['cart/status'] === 'added')
  //     string = 'Added to Cart'

  //   // Out of stock/Notifiable
  //   if (variant_state === 'invalid') string = 'Add to Cart'
  //   if (variant_state === 'not_orderable') string = 'Sold Out'
  //   if (variant_state === 'unavailable') string = 'Unavailable'

  //   return string
  // },
  // showBuyClass: (view, state) => {
  //   const classes = {}
  //   classes[view.product.variantState(state)] = true
  //   return classes
  // },
  variantState: (state, variant = false) => {
    const selected_variant =
      variant !== false ? variant : state.product.selected_variant
    const { selected_sizes } = state.product
    // No valid combination of options selected
    if (!selected_variant) {
      if (selected_sizes.Band && selected_sizes.Cup) {
        return 'unavailable'
      }
      return 'invalid'
    }

    // Variant is not orderable
    const selected_location = state.warehouse.current
    const stock_item = selected_variant?.stock_items.find(
      (v) => v.stock_location == selected_location
    )
    if (!stock_item) return 'not_orderable' // No stock location available

    if (!stock_item.is_orderable) return 'not_orderable'
    if (stock_item.track_inventory && stock_item.count_on_hand <= 5)
      return 'buyable_low'

    return 'buyable'
  },
  hasTag: (data, tag) => data.product.tags.includes(tag),
  showStoreClass: (state) => {
    const currency = state.currency.current
    const { stores } = state.product
    const variant = state.product.selected_variant
      ? state.product.selected_variant.id
      : false

    if (currency !== 'NZD') return
    if (!variant) return 'unselected'

    return stores.some((store) => store.stock === 'In Stock') ? '' : 'no-stock'
  },
  hasStore: (state) => {
    const currency = state.currency.current

    return currency == 'NZD'
  },
  showStore: (store) =>
    `${store.name}: <span class="${store.class}">${store.message}</span>`,
  hasRelated: (data, key = false) => {
    if (key) {
      return data.product[key].length > 0
    }
    return data.product.match.length > 0 || data.product.colours.length > 0
  },
  showSizeRange: (data, view) => {
    const size_range = view.product.showProperty(data.product, 'size-range')
    return size_range || ''
  },
  showFixedClass: (state) => {
    const { stuck } = state.product.sticky
    const { too_tall } = state.product.sticky
    const { too_wide } = state.product.sticky

    return {
      stuck,
      unstuck: !stuck,
      'too-tall': too_tall,
      'too-wide': too_wide,
    }
  },
  showSizeSearch: (state, view) => {
    const size = view.product.showSelectedSize(state)
    const href = `/products?keywords=${size}`

    return `<a href="${href}">See our styles that come in ${size}</a>`
  },
  showOverlayStoreClass: (state, store) => ({
    active: state.product.selected_store == store,
  }),
  inTaxon: (data, state, view, handle) =>
    _.find(
      data.product.classifications,
      (v) => v.taxon.permalink.indexOf(handle) !== -1
    ),
  hasTag: (data, tag) => _.includes(data.product.tags, tag),
  showReminderClass: (state, key) => {
    const { reminder } = state.product
    return { error: reminder == key }
  },
  isRestockActive: (d, s, v) => {
    const has_tag = v.product.hasTag(d, 'restock')
    const state = v.product.variantState(s)
    const restock_states = ['unavailable', 'unorderable', 'not_orderable']

    return has_tag && _.includes(restock_states, state)
  },
  getSelectedVariantPrice: (d, s) => {
    const master_variant = d.product.master
    const { selected_variant } = s.product

    let prices = selected_variant
      ? selected_variant.prices
      : master_variant.prices

    if (prices[0] && typeof prices[0]['on_sale?'] === 'undefined') {
      prices = prices.map((v) => {
        const ret = v
        ret['on_sale?'] = v.original_price > v.price
        return ret
      })
    }

    return prices
  },
}
