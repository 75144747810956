export default class Tabs {
  constructor(options) {
    this.$tab_sub    = $(options.$tab_sub.selector);
    this.$tab_pub    = $(options.$tab_pub.selector);
    this.is_closable = (typeof(options.is_closable) !== 'undefined') ? options.is_closable : false;
    this.is_hash     = (typeof(options.is_hash) !== 'undefined') ? options.is_hash : true;


    //Click on tab pub
    this.$tab_pub.on('click', (e) => {
      var $trigger = $(e.currentTarget);
      var select   = $trigger.attr('data-tab');
      this.sub({
        $trigger:$trigger,
        select:select,
      })
    });

    //check against current hash
    if(this.is_hash) {
      radio('window/hash').subscribe((d) => {
        var hash = d.hash;
        var $trigger = this.$tab_pub.filter(`[data-tab=${hash}]`);
        if($trigger.length > 0 && $trigger.index() > 0) {
          var select   = $trigger.attr('data-tab');
          this.sub({
            $trigger:$trigger,
            select:select,
          });
        }
      });
    }
  }

  sub(data) {
    var $trigger = data.$trigger;
    var select   = data.select;
    var $sub     = this.$tab_sub.filter('[data-tab~="' + select + '"]');
    var is_selected = $sub.hasClass('active');

    if(!is_selected) {
      this.$tab_sub.removeClass('active');
      this.$tab_pub.removeClass('active');

      $sub.addClass('active');
      $trigger.addClass('active');

      //update window hash
      if(this.is_hash) window.location.hash = select;
    } else if(is_selected && this.is_closable) {
      this.$tab_sub.removeClass('active');
      this.$tab_pub.removeClass('active');
      if(this.is_hash) window.location.hash = '';
    }
  }

  select(select) {
    var $pub = this.$tab_pub.filter('[data-tab="' + select + '"]');
    $pub.trigger('click');
  }
}