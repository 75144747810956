<template>
  <overlay-wrap
    class="overlay-cart__wrap"
    transition="slideFromRight"
    name="cart"
  >
    <div
      class="overlay-cart__inner"
      :class="{ 'overlay-cart__inner--disabled': removing }"
    >
      <cart-heading :count="getItemCount" />
      <div class="overlay-cart__content">
        <cart-empty v-if="getItemCount == 0" />
        <cart-items v-else :items="cart.line_items" />
        <cart-related v-if="hasRelated" :related="related" />
      </div>
      <div class="overlay-cart__footer">
        <cart-totals :total="cart.item_total" />
        <cart-controls />
      </div>
    </div>
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex'
import { isEmpty } from 'lodash'
import OverlayWrap from '../wrap'
import CartHeading from './overlay-cart-heading'
import CartEmpty from './overlay-cart-empty'
import CartRelated from './overlay-cart-related'
import CartTotals from './overlay-cart-totals'
import CartControls from './overlay-cart-controls'
import CartItems from './overlay-cart-items'

export default {
  components: {
    CartHeading,
    CartEmpty,
    CartRelated,
    CartTotals,
    CartControls,
    CartItems,
    OverlayWrap,
  },

  props: {
    cart: {
      type: Object,
      default: () => ({}),
    },
    related: {
      type: [Object, Boolean],
      default: false,
    },
  },

  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
      cartStatus: (state) => state.cart.status,
    }),
    hasRelated() {
      if (typeof this.related === 'undefined') return false
      if (this.related === false) return false
      if (isEmpty(this.related)) return false

      return true
    },
    getItemCount() {
      return this.$store.getters['cart/itemCount']
    },
    removing() {
      return this.cartStatus === 'removing'
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/base';

.overlay-wrap--cart {
  z-index: 2147483640; // Blame livechat
}

.overlay.overlay--cart {
  position: absolute;
  right: 0;
  width: $cart_width;
  height: 100%;
  top: 0;
  z-index: 2147483640;
  background: rgba(255, 255, 255, 1);
  border-left: 1px solid $midgrey;
  display: flex;
  flex-direction: column;
  padding: 0;

  @include respond-to(xxs) {
    width: calc(100vw - 50px);
  }
}

.overlay-cart {
  &__inner {
    padding: 40px 0 40px 40px;
    height: 100%;
    position: relative;
    z-index: 10;
    transition: opacity 0.2s ease;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__content {
    position: absolute;
    top: 100px;
    left: 40px;
    right: 30px;
    bottom: 190px;
    overflow-y: auto;
  }

  &__footer {
    @include abs-position(0, 0, 100%, auto, left, bottom);
    padding: 10px 40px 50px;
    background: rgba(255, 255, 255, 1);
  }

  @include respond-to(xxs) {
    $cart_width_mob: calc(100vw - 50px);
    width: $cart_width_mob;
    right: calc(-1 * #{$cart_width_mob});

    &__content {
      left: 20px;
      right: 20px;
    }

    &__footer {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
