<template>
  <div class="summary">
    <h3 class="heading">Review your order</h3>
    <div class="cart" v-if="cart && cart.line_items">
      <table>
        <tbody>
          <tr v-for="item in cart.line_items" :key="item.id">
            <td>
              <span v-text="item.product.name"></span>
              <br />
              <span v-text="item.quantity"></span>
              <span class="multiply">x</span>
              <span v-if="getSize(item)" v-text="getSize(item)"></span>
            </td>
            <td class="price">
              <price-display
                :prices="[{ price: item.price }]"
                :use-base-rate="true"
              ></price-display>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="totals">
      <table>
        <tbody>
          <tr v-for="t in formatTotals" :key="t.key" :class="t.class">
            <td class="key">
              <span v-text="t.key"></span>
            </td>
            <td class="value">
              <price-display
                :prices="[{ price: t.value }]"
                :use-base-rate="true"
              ></price-display>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="hasNote" class="note">
      The store will convert and show the charge in the currency shown above at
      checkout.
    </div>
  </div>
</template>

<script>
import CartMixin from '~/mixins/cart'
import PriceDisplay from '~/components/prices/price-display'

export default {
  components: {
    PriceDisplay,
  },

  mixins: [CartMixin],

  props: {
    cart: {
      type: Object,
      required: true,
    },
    currency: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasNote() {
      const supported_currencies = ['NZD', 'AUD', 'USD']
      return supported_currencies.indexOf(this.currency) === -1
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/base';
.summary {
  @include cx();
  @include cx-xs();
  @include cx-darkgrey();
  @include sans();

  .heading {
    border-bottom: 1px solid $lightgrey;
    padding-bottom: 2px;
    margin-bottom: 10px;
  }

  .totals {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $lightgrey;
  }

  table {
    @include bm-xs();
    width: 100%;
    tr {
      td {
        width: 50%;
        + td {
          text-align: right;
        }
      }
      &.deductable {
        td {
          padding-top: 19px;
        }
      }
      &.deductable ~ .deductable {
        td {
          padding-top: 0px;
        }
      }
      &.grand-total {
        td {
          padding-top: 19px;
        }
        td.key {
          @include cx-uc();
        }
      }
    }
  }
  .note {
    @include cx-midgrey();
  }
}
</style>
