import slugify from 'slugify';
import showdown from 'showdown';
// import marked from 'marked';
import ContentfulMediaBlock from './contentful-media-block';
import ContentfulMediaParser from './contentful-media-parser';
// import VideoPlayer from './video-player';

const createClassName = (value) => slugify(value || '', { lower: true });

export default {
  name: 'contentful-entry-block-parser',

  functional: true,

  props: {
    entry: {
      type: Object,
      required: true,
    },
  },

  render(h, { props }) {
    const { entry } = props;
    const { fields } = entry;
    const {
      type = '',
      style = '',
      alignment = '',
    } = fields;

    const classList = [type, style, alignment].map((value) => createClassName(value));

    // TODO: Make component
    const converter = new showdown.Converter({ tables: true });

    if (type) {
      switch (type) {
        case 'Lead Paragraph':
          return h('p', { staticClass: 'ctf-lead-p', class: classList }, fields.text);
        case 'Block Quotation':
          return h('blockquote', { staticClass: 'ctf-bq', class: classList }, [h('p', { staticClass: 'ctf-bq__p' }, fields.text)]);
        case 'Gallery':
          return (
            <ContentfulMediaBlock
              entry={entry}
              class={classList}
            />
          );
        case 'Markdown':
          return h('div', { staticClass: 'ctf-md', class: classList, domProps: { innerHTML: converter.makeHtml(fields.text) } });
          // console.log(fields.text);
          // break;
        default:
          return null;
      }
    }
  },
};
