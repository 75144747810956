export default {
  formatSlides: (d,s) => {

    //Is mobile?
    var window_width  = s.window.width;
    var window_height = s.window.height;
    var is_mobile_old = s.page_home.is_mobile;
    var is_mobile = window_width < window_height;

    var slides_raw = (is_mobile) ? d.slides_mobile : d.slides_desktop;
    
    var reset_current = (is_mobile_old != -1 && is_mobile_old != is_mobile);
    var current       = (reset_current) ? 0 : s.page_home.slide_current;
    var slides_length = slides_raw.length;
    var cur           = slides_length - current;

    var slides = _.map(slides_raw, (sr, idx) => {
      return {
        video_url: sr.video_url,
        image_url: sr.image_url,
        position:  sr.position,
        has_video: (sr.video_url && sr.video_url != ''),
        slide_classes: {
          [`slide-${sr.position}`]:true
        },
        slide_styles: {
          opacity: (idx > current) ? 0 : 1
        },
        background_classes: {
          'background-image': `url('${sr.image_url}')`
        }
      }
    });

    s.page_home.slides_raw = slides_raw;
    s.page_home.is_mobile = is_mobile;

    return slides;
  }
}