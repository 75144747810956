<template>
  <section class="product-details">
    <slot />
  </section>
</template>

<script>
export default {

};
</script>

<style lang="scss">
@import "~styles/frontend/base";

.product-details {
  &__heading {
    @include bp-m();
  }

  &__title {
    @include hx();
    @include hx-m();
    @include sans2();
    @include bp-s();

    font-size:18px;
  }

  &__description,
  &__make {
    max-width: 100%;
    width: 260px;
    margin: 0 auto;
  }

  &__description {
    ul {
      margin-bottom: 1em;

      li {
        span {
          display:inline-block;
          width: 40px;
        }
      }
    }

    p {
      margin-bottom: 1em;
    }
  }

  &__make {
    @include helper-link-underline();

    padding-top: 1em;
  }

  &__sizing {
    p {
      margin-bottom:0;
    }
  }

  &__production-link {
    @include helper-link-arrow();
  }

  @media (max-width: $breakpoint_m) {
    width: 100%;

    &__description,
    &__make {
      display:none;
    }
  }
}

/* .gst-note {
  color: $lightgrey;
  margin-left: 3px;
} */
</style>
