import Vuex from 'vuex'
import Vue from 'vue/dist/vue'

import currency from './currency'
import overlay from './overlay'
import cart from './cart'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    currency,
    overlay,
    cart,
  },

  strict: debug,
})
