<template>
  <main class="home">
    <section class="landing">
      <div class="landing-background desktop">
        <contentful-image v-bind="fields.landscape_image.fields" />
      </div>
      <div class="landing-background mobile">
        <contentful-image v-bind="fields.portrait_image.fields" />
      </div>
      <a
        v-if="fields.url"
        :href="fields.url"
        :aria-label="fields.url_label || ''"
        class="home-landing__url"
      />
    </section>
    <!-- <HomeBlocks blocks="{fields.blocks}" /> -->
  </main>
</template>

<script>
// TODO: Landscape/Portrait instead of desktop/mobile
// TODO: Quality setting could be set in cloudinary
// import CloudinaryMediaParser from '~/components/cloudinary-media-parser';
import ContentfulImage from '~/components/common/contentful-image';
import HomeBlocks from '~/components/home-blocks';

export default {
  components: {
    ContentfulImage,
    HomeBlocks,
  },

  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/frontend/variables';

.home-landing {
  &__url {
    position: absolute;
    top: 70px;
    right: 0;
    bottom: 100px;
    left: 0;

    @media (max-width: 900px) {
      top: 0;
      bottom: 150px;
    }

    @media (max-width: 600px) {
      bottom: 0;
    }
  }
}
</style>
