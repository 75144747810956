<template>
  <div class="product-option-selection">
    <fieldset
      v-for="[optionName, optionValues] in Object.entries(groupedOptionValues)"
      :key="optionName"
      class="option"
    >
      <legend>
        Select {{ optionName }}:
        <span class="selection">{{ selectedValues[optionName] }}</span>
      </legend>
      <div class="options">
        <product-selection-input
          v-for="value in optionValues"
          :key="value.id"
          v-model="selectedValues[optionName]"
          :label="value.name"
          :name="optionName"
          :class="{
            unavailable: !isOptionValueAvailable(optionName, value.name),
          }"
        />
      </div>
    </fieldset>
    <product-selection-message
      :selected-size="selectedSize"
      :selected-variant="selectedVariant"
      :warehouse="warehouse"
    />
  </div>
</template>

<script>
import { variantHasOrderableStock } from '../../../utils/helpers'
import ProductSelectionInput from './product-selection-input.vue'
import ProductSelectionMessage from './product-selection-message.vue'

const variantIsInStock = (variant) =>
  variant.stock_items.some((item) => item.in_stock)

const variantHasOptionValue = (variant, optionName, optionValue) =>
  variant.option_values.some(
    (value) =>
      value.option_type_name === optionName && value.name === optionValue
  )

export default {
  components: {
    ProductSelectionInput,
    ProductSelectionMessage,
  },

  props: {
    optionValues: {
      type: Array,
      default: () => [],
    },
    selectedVariant: {
      type: Object,
      default: null,
    },
    variants: {
      type: Array,
      default: () => [],
    },
    warehouse: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedValues: this.optionValues.reduce(
        (values, value) => ((values[value.option_type_name] = ''), values),
        {}
      ),
    }
  },

  computed: {
    groupedOptionValues() {
      return this.optionValues.reduce((values, value) => {
        values[value.option_type_name] = [
          ...(values[value.option_type_name]
            ? values[value.option_type_name]
            : []),
          value,
        ]
        return values
      }, {})
    },
    selectedSize() {
      return this.selectedValues['Size']
    },
  },

  watch: {
    selectedSize: {
      handler(newSize) {
        this.$emit('update:selected-size', newSize)
      },
      immediate: true,
    },
    selectedValues: {
      handler(newValues) {
        if (!Object.values(newValues).every((value) => value)) return
        const match = this.variants.find((variant) =>
          Object.entries(newValues).every(([optionName, optionValue]) =>
            variantHasOptionValue(variant, optionName, optionValue)
          )
        )
        this.$emit('update:selected-variant', match)
      },
      deep: true,
    },
  },

  methods: {
    isOptionValueAvailable(optionName, optionValue) {
      return this.variants.some(
        (variant) =>
          variantHasOrderableStock(this.warehouse)(variant) &&
          variantHasOptionValue(variant, optionName, optionValue)
      )
    },
    variantIsInStock,
  },
}
</script>
