export default {
  data() {
    return {
      shouldLazyLoad: false,
      lazyRootMargin: "0px 0px 100% 0px",
    };
  },

  created() {
    if ('IntersectionObserver' in window) {
      this.lazyObserver = new IntersectionObserver(
        entries => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            this.shouldLazyLoad = true;
            this.lazyObserver.disconnect();
          }
        },
        {
          rootMargin: this.lazyRootMargin,
          threshold: 0.0
        }
      );
    } else {
      this.shouldLazyLoad = true;
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.lazyObserver) this.lazyObserver.observe(this.$el);
    });
  }
};
