import fetchJsonp from 'fetch-jsonp';
import qs from 'qs';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },

    heading: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'Email address',
    },

    note: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      active: true,
      email: '',
      message: '',
    };
  },

  methods: {
    async submit() {
      if (!this.email) {
        this.message = 'Please enter a valid email address';
        return;
      }
      try {
        const params = qs.stringify({ EMAIL: this.email, Submit: true });
        const res = await fetchJsonp(`${this.action}&${params}`, { jsonpCallbackFunction: 'cb' });
        const data = await res.json();
        if (!data.error) this.email = '';
        this.message = data.msg.replace(' to list Website Signups (Spree)', '');
      } catch (exception) {  }
    },

    clearMessage() {
      this.message = '';
    },
  },

  template: /* html */ `
    <div class="newsletter">
      <h2 class="newsletter__heading">
        {{ heading }}
      </h2>
      <div
        v-if="!!note"
        class="newsletter__cta"
        v-html="note"
      />
      <form
        class="newsletter__form"
        method="get"
        @submit.prevent="submit($event)"
      >
        <div class="newsletter__input-fields">
          <input
            v-model="email"
            class="newsletter__email"
            type="text"
            :placeholder="placeholder"
            @input="clearMessage"
          >
          <button
            aria-label="Submit Newsletter Signup"
            class="newsletter__submit"
            @click="submit()"
          >
            →
          </button>
        </div>
        <transition name="fade">
          <div
            v-if="!!message"
            class="newsletter__message"
            v-html="message"
          />
        </transition>
      </form>
    </div>
  `,
};

/* <style lang="scss" scoped>
  @import "~styles/tools/mixins";

  .newsletter {
    @include displaySans("s");
    padding-bottom: 30px;
  }
  .form {
    position: relative;
  }
  .message {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    & /deep/ a {
      @include hoverUnderline(true);
    }
  }
</style> */
