export default {
  tab: {
    details: 0,
    related: 'match',
    mobile: 'desc',
  },
  sticky: {
    too_tall: true,
    too_wide: true,
    product_bottom: -1,
    stuck: false,
  },
  selected_options: {},
  selected_sizes: {},
  selected_variant: null,
  stores: [],
  selected_store: {},
  reminder: '',
  notification_email: '',
  notification_error: false,
  notification_sent: false,
  notification_sending: false,
}
