export default class Stockist {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    radio('stockist').subscribe((data)        => {this.stockist(data);});
    radio('stockist/filter').subscribe((data) => {this.filter(data);});
  }
  
  stockist(data) {
    this.$content       = data.$content;
    this.$location_pubs = data.$location_pubs;
    this.$line_pubs     = data.$line_pubs;
    this.$filter_subs   = data.$filter_subs;

    //Create sub array for ease of searching
    this.filter_subs_data = [];
    this.$filter_subs.each((i,v) => {
      var $sub = $(v);
      this.filter_subs_data.push({
        $sub: $sub,
        lines: $sub.attr('data-lines').split(','),
        locations: $sub.attr('data-locations').split(','),
      });
    });
  }
  
  filter(data) {
    var type   = data.type;
    var $pub   = data.$pub;
    var id     = $pub.attr('data-id');

    //Update selected filters
    $pub.addClass('active');

    //Update selected filters by filter type
    if(type == 'line') {
      this.$line_pubs.not($pub).removeClass('active');
    } else {
      this.$location_pubs.not($pub).removeClass('active');
    }

    //Get active location and line
    var location_pub_id = this.$location_pubs.filter('.active').attr('data-id');
    var line_pub_id     = this.$line_pubs.filter('.active').attr('data-id');

    //Filter subs by ids
    var $active_subs = $($.grep(this.filter_subs_data,(sub) => {
      //Does location exist?
      return (this.in_list(sub.lines, line_pub_id) && this.in_list(sub.locations, location_pub_id));
    }).map((v) => {return v.$sub}))
      .map (function () {return this.toArray() } );

    //Update subs
    this.$filter_subs.not($active_subs).removeClass('active');
    $active_subs.addClass('active');

    //Hide/show blocks depending on filtered contents
    var $blocks = this.$content.find('.block');
    $blocks.each((i,v) => {
      var $block = $(v);
      if($block.find('.retailer.active').length > 0) {
        $block.show();
      } else {
        $block.hide();
      }
    });
  }

  in_list(list,item) {
    return ($.grep(list,(v) => {
      return v == item;
    }).length > 0)
  }
}