<template>
  <div class="t-page">
    <section class="t-page__heading">
      <h1
        class="t-page__title"
        :class="{
          't-page__title_hidden': hideTitle,
          't-page__title_caps': capitalizeTitle
        }"
      >
        {{ title }}
      </h1>
    </section>
    <section class="t-page__content">
      <ContentfulDocument
        :document="content"
      ></ContentfulDocument> 
    </section>
  </div>
</template>

<script>
import ContentfulDocument from '~/components/contentful-document';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    capitalizeTitle: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  components: {
    ContentfulDocument,
  },
}
</script>
