export default class Slideshow {
  constructor(lbox) {
    this.templates = lbox.templates;
    this.options = lbox.options;
    // Set up slideshow elements
    this.els = this.elements();

    // Set up slideshow state vars
    this.state = this.getState();

    // Mark as initialised
    this.state.initialised = true;

    return this;
  }

  getState() {
    return {
      initialised: false,
      wrap: this.options.wrap,
      box: {
        width: this.els.$box.width(),
      },
    };
  }

  remove() {
    const { els } = this;
    if (els.$wrap.length > 0) {
      els.$wrap.fadeOut(150, () => {
        els.$wrap.remove();
      });
    }
  }

  elements() {
    const { templates } = this;

    const $wrap = $(templates.lightbox({ options: this.options }));

    const els = {};
    els.$wrap = $wrap.appendTo($('body'));

    els.$close = els.$wrap.find('.close, [data-pub~="close"]');
    els.$box = els.$wrap.find('[data-sub~="lbox-box"]');

    return els;
  }
}
