// Pages
import Page from './pub/page';
import Stockist from './pub/stockist';

export default class Pub {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;

    // Global
    this.u.load.pub();
    this.u.hash.pub();

    // Grouped pages
    this.page = new Page(main, this.u);
    this.stockist = new Stockist(main, this.u);
  }
}