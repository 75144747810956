export default {
  // togglePanel: (state, panel, toggle = -1) => {
  //   const last_panel = state.panel.active_panel;
  //   // Flip panel on and off
  //   if (toggle === -1) {
  //     if (panel === state.panel.active_panel) {
  //       state.panel.active_panel = false;
  //     } else {
  //       state.panel.active_panel = panel;
  //     }
  //   } else {
  //     // If toggle is initialised..
  //     if (toggle == true) {
  //       state.panel.active_panel = panel;
  //     } else if (toggle == false) {
  //       state.panel.active_panel = false;
  //     }
  //   }

  //   // Class
  //   if (state.panel.active_panel) {
  //     state.panel.active_class = `${state.panel.active_panel}-active`;
  //     state.panel.leaving_class = '';
  //   } else {
  //     state.panel.active_class = '';
  //     if (last_panel) {
  //       state.panel.leaving_class = `${last_panel}-leaving`;
  //       setTimeout(() => {
  //         state.panel.leaving_class = '';
  //       }, 1000);
  //     }
  //   }
  // },
};
