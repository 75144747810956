import {
  SET_BASE_CURRENCY,
  SET_DISPLAY_CURRENCY,
  SET_DISPLAY_CURRENCY_DATA,
} from './mutation-types'

const currencyState = {
  base: '',
  display: '',
  displayData: {},
}

const mutations = {
  [SET_BASE_CURRENCY](state, newCode) {
    state.base = newCode
  },
  [SET_DISPLAY_CURRENCY_DATA](state, newData) {
    state.displayData = newData
  },
  [SET_DISPLAY_CURRENCY](state, newCode) {
    state.display = newCode
  },
}

const getters = {
  currencyHasTaxNote(state) {
    return ['AUD'].includes(state.display)
  },
  measurementStandard(state) {
    const imperialLocations = ['USD', 'GBP', 'CAD']
    return imperialLocations.includes(state.display) ? 'imperial' : 'metric'
  },
}

export default {
  namespaced: true,
  state: currencyState,
  mutations,
  getters,
}
