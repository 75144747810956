export default class Post {
  constructor(parent) {
    this.req = parent.req;
  }

  send(options) {
    this.req({
      url : options.url,
      data : options.data,
      callback : options.callback,
      method: 'POST'
    });
  }
}