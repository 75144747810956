import axios from 'axios';

const csrfToken = window._data.csrf_token || false;

async function request(overrides) {
  const response = await axios.request({
    method: 'get',
    dataType: 'json',
    responseType: 'json',
    baseURL: '',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    ...overrides,
  });
  
  return response.data;
}

async function get(data) {
  return request(data);
}

async function put(data) {
  return request({
    method: 'put',
    ...data,
  });
}

async function post(data) {
  return request({
    method: 'post',
    ...data,
  });
}

async function del(data) {
  return request({
    method: 'post',
    ...data,
  });
}

export default {
  request,
  post,
  put,
  get,
  del,
};
