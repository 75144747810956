<template>
  <div class="product-purchase__buy">
    <slot />
  </div>
</template>

<script>
import ProductAdd from './product-add.vue'

export default {
  components: {
    ProductAdd,
  },

  props: {},
}
</script>
