import Vue from 'vue'

import jQuery from 'jquery'
import rivets from 'rivets/dist/rivets'
import radio from 'radio/radio'
import moment from 'moment/moment'
import _ from 'lodash'
import jsCookie from 'js-cookie/src/js.cookie'

// Todo: Slowly move all pub/sub and features into vue
import Feat from './feat/feat'
import Pub from './pub'
import Sub from './sub'
import Utils from './utils'

import VueComponents from './vue/components'
import store from './vue/store'

global._ = _
global.radio = radio
global.moment = moment
global.rivets = rivets
global.jsCookie = jsCookie
global.$ = jQuery
// window.Vue = Vue;

// import * as Sentry from '@sentry/browser';
// import Instafeed from 'instafeed.js/instafeed';
// window.Sentry    = Sentry;
// window.Instafeed = Instafeed;

// Sentry.init({
//   dsn: 'https://b6e89d741ead4a18a12c85a9fe71a438@sentry.io/1321595',
//   integrations: [new Sentry.Integrations.Vue({ Vue })]
// });

export default class App {
  constructor() {
    const { _data: data } = window
    this.$body = $('body')
    this.$window = $(window)

    this.is_touch = 'ontouchend' in document
    this.is_mouse = 'onmousedown' in document

    // We want touch if available, but if both are then use mouse
    this.click_event = this.is_touch && !this.is_mouse ? 'touchend' : 'click'

    // Global vars
    this.data = data // Data provided on-page via script tags
    this.funcs = {} // General functions: Todo: Split these into view/mutator
    this.state = {} // State used on this page
    this.view = {} // Functions that display state
    this.event = {} // Event listeners/publishers
    this.mutator = {} // Functions that mutate state
    // this.store = {}; // State shared between pages

    this.u = new Utils(this)

    // Boot our features
    this.feat = new Feat(this)

    // Create Vue instance
    this.vm = new Vue({
      store,

      delimiters: ['{{', '}}'],

      components: {
        ...VueComponents,
      },

      data: {
        s: this.state,
        f: this.funcs,
        d: this.data,
        v: this.view,
        m: this.mutator,
        showAfterpayModal: false,
      },

      computed: {
        currencyBase() {
          return this.s.currency.store
        },
        currencyDisplayData() {
          return this.s.currency.currencies.find(
            (currency) => currency.code === this.currencyDisplay
          )
        },
        currencyDisplay() {
          return this.s.currency.current
        },
        cartData() {
          return this.d.cart
        },
        measurementStandard() {
          return this.s.app.standard
        },
        relatedData() {
          return this.d.related
        },
        warehouseCurrent() {
          return this.s.warehouse.current
        },
      },

      watch: {
        currencyBase: {
          handler(currencyBase) {
            this.updateBaseCurrency(currencyBase)
          },
          immediate: true,
        },
        currencyDisplay: {
          handler(currencyDisplay) {
            this.updateDisplayCurrency(currencyDisplay)
          },
          immediate: true,
        },
        cartData: {
          handler(cartData) {
            this.updateCartData(cartData)
          },
          immediate: true,
        },
      },

      mounted() {
        // this.updateCartData();
        // this.updateBaseCurrency();
        // this.updateDisplayCurrency();
        this.checkFlash()
      },

      methods: {
        radio(e, key, d) {
          e.preventDefault()
          radio(key).broadcast(d)
        },

        emit(path, d = {}) {
          radio(path).broadcast(d)
        },
        updateBaseCurrency(currencyBase) {
          this.$store.commit('currency/SET_BASE_CURRENCY', currencyBase)
        },
        updateCartData(cartData) {
          this.$store.commit('cart/SET_CART_DATA', cartData)
          this.$store.commit(
            'cart/SET_CART_SPECIAL_INSTRUCTIONS',
            cartData.special_instructions
          )
        },
        updateDisplayCurrency(currencyDisplay) {
          this.$store.commit('currency/SET_DISPLAY_CURRENCY', currencyDisplay)
          this.$store.commit(
            'currency/SET_DISPLAY_CURRENCY_DATA',
            this.currencyDisplayData
          )
        },
        checkFlash() {
          const flash_html = document
            .getElementById('flash_template')
            .innerHTML.trim()

          if (flash_html.length > 0) {
            this.$store.commit('overlay/SET_OVERLAY_MESSAGE', flash_html)
            this.$store.commit('overlay/SET_OVERLAY', 'flash')
          }
        },
      },
    })

    $(() => {
      this.vm.$mount('#app')

      // Start our action pub/sub
      // After vue so it doesn't interfere
      this.sub = new Sub(this, this.u)
      this.pub = new Pub(this, this.u)

      // Fire loaded event
      radio('vue/loaded').broadcast()
    })
  }
}

global.app = new App()
