import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      currencyBase: (state) => state.currency.base,
      currencyDisplay: (state) => state.currency.display,
      currencyDisplayRate: (state) => state.currency.displayData.rate,
      currencyDisplaySymbol: (state) => state.currency.displayData.symbol,
    }),
  },
};
