<template>
<div class="tabbed-content">
  <nav class="tab-controls">
    <h3>{{ named }}</h3>
    <ul>
      <li
        v-for="(select, selectIndex) in controls"
        :class="{ active: current === selectIndex }"
        @click="setCurrent(select, selectIndex)"
        :key="`tab_controls_${selectIndex}`"
      >
        {{ select.title }}
      </li>
    </ul>
  </nav>

  <section class="content tabs" :class="named + 's'">
    <transition-group :name="tabTransition">
      <div class="tab" 
          v-for="(tab, tabIndex) in tabs"
          v-show="current === tabIndex" 
          :class="named" 
          :key="`tab_${tabIndex}`">
        <div class="image" v-if="tab.image">
          <img :src="tab.image">
        </div>
        <h3 class="heading">
          {{ tab.title }}
        </h3>
        <div class="description" 
            v-html="formatBody(tab)" ></div>
        <div class="images">
          <div class="image"
              v-for="image in tab.gallery">
            <img :src="image" />
          </div>
        </div>
      </div>
    </transition-group>
  </section>

  </div>
</template>

<script>
export default {
  

  props: {
    named: String,
    tabs: Array,
    tabTransition: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      ctrl_heading: {
        type: String,
        default: false,
      }
    }
  },
  data() {
    return {
      current: 0,
      hash: "",
    };
  },
  created() {
    this.updateFromHash();
  },
  mounted() {
    window.addEventListener("hashchange", () => this.updateFromHash());
  },
  computed: {
    controls() {
      if (!this.tabs) return;
      return this.tabs.map(t => ({
        title: t.title,
        slug: t.title
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, "")
      }));
    }
  },
  methods: {
    setCurrent(tabName, index) {
      this.current = index;
      window.location.hash = this.controls[index].slug;
    },
    updateFromHash() {
      const hash = window.location.hash.slice(1);

      if (hash) {
        const hashIndex = _.findIndex(this.controls, c => c.slug === hash);
        if (hashIndex >= 0) this.current = hashIndex;
      }
    },
    formatBody(tab) {
      var body = tab.body;

      // Looks for attachment shortcodes where index is
      // a 1 based index of the attachment on the tab

      //Shortcode:
      // [attachment/{index}]

      var regex = /(.*)\[attachment\/([0-9])\](.*)/;

      while (body.match(regex)) {
        var attachment_index = parseInt(body.match(regex)[2]);
        var attachment = tab.attachments[attachment_index - 1];
        var link = !!attachment
          ? `<a href="${attachment.url}" target="_blank">here</a>`
          : "";

        body = body.replace(regex, `$1${link}$3`);
      }

      return body;
    }
  },
}
</script>