export default class Home {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    radio('home').subscribe((data)  => {this.init(data);});
  }
  
  init(data) {
    this.$updates = data.$updates;
  }
}