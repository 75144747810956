export default {
  name: 'HeaderComponent',

  data() {
    return {
      activeRegion: null,
      activeSubmenu: null,
      activeCategory: null,
    };
  },

  methods: {
    setActiveSubmenu(newSubmenu, newRegion) {
      this.activeSubmenu = newSubmenu;
      this.activeRegion = newRegion;
    },

    setActiveCategory(newCategory) {
      if (newCategory.active) {
        this.activeCategory = newCategory.name;
      }
    },
  },

  mounted() {
    document.addEventListener('click', (e) => {
      if (e.target.closest('.header__main-nav')) return;
      this.activeRegion = null;
      this.activeSubmenu = null;
      this.activeCategory = null;
    });
  },

  template: /* html */ `
    <div>
      <slot
        :headerState="{
          activeRegion:activeRegion,
          activeSubmenu:activeSubmenu,
          activeCategory:activeCategory
        }"
        :headerMethods="{
          setActiveSubmenu:setActiveSubmenu,
          setActiveCategory:setActiveCategory
        }"
      ></slot>
    </div>
  `,
};
