export default class Stockist {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;
    
    if($('.container-stockist-index').length > 0) {
      this.init();
      this.filter();
    }
  }
  
  init() {
    var $sidebar        = $('#sidebar');
    var $content        = $('#content');
    this.$location_pubs = $sidebar.find('[data-pub~=location]');
    this.$line_pubs     = $sidebar.find('[data-pub~=line]');
    this.$filter_subs   = $content.find('[data-sub~=filter]');

    radio('stockist').broadcast({ 
      $location_pubs:this.$location_pubs,
      $line_pubs:this.$line_pubs,
      $filter_subs:this.$filter_subs,
      $content:$content
    });

    //Initialise the selection
    radio('stockist/filter').broadcast({ 
      type:'location',
      $pub:this.$location_pubs.eq(0)
    });
  }

  filter() {
    //Location change
    $(this.$location_pubs).add(this.$line_pubs).on(this.m.click_event, (e) => {
      var $pub = $(e.currentTarget);
      radio('stockist/filter').broadcast({ 
        type:($pub.filter('[data-pub~=line]').length > 0) ? 'line' : 'location',
        $pub:$pub
      });
    });
  }
} 