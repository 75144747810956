export default class Faq {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;

    if($('.container-page-faq').length > 0) this.init();
  }
  init() {
    this.$container = $('.container.container-page-faq');
    
    radio('page/faq').broadcast({ 
      action : 'page',
      $tab_pub : this.$container.find('.tab-pub [data-pub=tab]'),
      $tab_sub : this.$container.find('.tab-sub [data-sub~=tab]'),
    });
  }
}