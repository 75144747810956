<template>
  <overlay-wrap name="location">
    <slot />
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex';
import OverlayWrap from '~/components/overlay/wrap';

export default {
  computed: {
    ...Vuex.mapState({
      overlay: state => state.overlay.current
    }),
  },
  components: {
    OverlayWrap,
  },
}
</script>

<style lang="scss">
  @import "~styles/frontend/base";
  @import "~styles/frontend/forms";

  .overlay.overlay--location {
    @include helper-lightbox-close();
    @include helper-panel-lightbox();
    .inner {
      max-width:500px;
      text-align:center;
      h3 {
        @include hx-style1();
        @include bm-s();
      }
      ul {
        @include horizontal-list();
        @include bp-m();
        li {
          @include helper-link-underline();
          @include cx();
          @include cx-s();
          @include cx-midgrey();
          &.active {
            border-bottom:1px solid #DDDDDC; 
          }
          cursor:pointer;
          + li {
            &:before {
              content:' / ';
            }
          }
        }
      }
    }
  }
</style>