<template>
  <image-loader
    :src="src"
    :alt="description"
    :height="height || file.details.image.height"
    :width="width || file.details.image.width"
  />
  <!-- <figure class="ctf-media">
    <figcaption
      v-if="description && showCaption"
      class="ctf-media__caption"
    >
      {{ description }}
    </figcaption>
  </figure> -->
</template>

<script>
import ImageLoader from '~/components/common/image-loader';

export default {
  name: 'ContentfulImage',

  props: {
    description: {
      type: String,
      default: '',
    },
    file: {
      type: Object,
      default: () => ({
        contentType: '',
        details: {},
        fileName: '',
        url: '',
      }),
    },
    fit: {
      type: String,
      default: null,
    },
    quality: {
      type: Number,
      default: 85,
    },
    height: {
      type: Number,
      default: null,
    },
    // showCaption: {
    //   type: Boolean,
    //   default: false,
    // },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      defaultTransforms: `fm=jpg&q=${this.quality}`,
    };
  },

  computed: { 
    src() {
      return `${this.file.url}?${this.fit ? `fit=${this.fit}&` : ''}${this.width ? `w=${this.width}&` : ''}${this.height ? `h=${this.height}&` : ''}${this.defaultTransforms}`;
    },
  },

  components: {
    ImageLoader,
  }
};
</script>