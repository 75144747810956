import Base from '../base';

export default class Default extends Base {
  init() {
    this.detectLocation();

    radio('location/set').subscribe((v) => { this.setLocation(v); });
  }

  async detectLocation() {
    // Set up location
    try {
      const storedLocation = JSON.parse(window.localStorage.getItem('storeLocation'));
      let location = null;
      try {
        if (!storedLocation) throw new Error("No location stored, fetching");
        location = JSON.parse(storedLocation);
      } catch(e) {
        location = await this.u.spree.location.get();
        window.localStorage.setItem('storeLocation', JSON.stringify(location));
      }

      this.s.location.current = location.location;
      this.s.warehouse.current = location.warehouse;
      this.s.currency.current = location.currency;
      this.s.currency.store = location.store_currency;
      // console.log('Detect location:', location);
    } catch (e) {
      console.log(e.responseJSON);
    }
  }

  async setLocation(location) {
    this.s.location.current = location;
    const data = { location };

    const store_currency = this.s.currency.store;

    try {
      const response = await this.u.spree.location.set({ data });

      window.localStorage.setItem('storeLocation', JSON.stringify(response));

      // If the store currency changes, reload the page
      if (store_currency !== response.store_currency) {
        window.location.reload();
      } else {
        this.s.location.current = response.location;
        this.s.warehouse.current = response.warehouse;
        this.s.currency.current = response.currency;
        this.s.currency.store = response.store_currency;

        // Reload cart
        this.main.vm.$store.dispatch('cart/refresh');
      }
    } catch (e) {
      console.log(e.responseJSON);
    }
  }
}
