export default {
  toggleTaxon: (state, taxon) => {
    state.header.mmenu.active_taxon = state.header.mmenu.active_taxon == taxon ? false : taxon;
  },
  toggleCurrency: (state, target = -1) => {
    if (target !== -1) {
      state.mmenu.location_active = target;
    } else {
      state.mmenu.location_active = !state.mmenu.location_active;
    }
  },
};
