export default {
  selected_size: -1,
  sizes: ['8A','8B','8C','8D','8DD','8E','10A','10B','10C','10D','10DD','10E','10F','10G','12A','12B','12C','12D','12DD','12E','12F','14A','14B','14C','14D','14DD','14E','16A','16B','16C','16D','16DD'],
  conversion: [
    {
      '8A': '10B',
      '8B': '10C',
      '8C': '10D',
      '8D': '10DD',
      '8DD': '10E',
      '8E': '10F',
      '10A': '12B',
      '10B': '12C',
      '10C': '12D',
      '10D': '12DD',
      '10DD': '12E',
      '10E': '12F',
      '10F': '',
      '10G': '',
      '12A': '14B',
      '12B': '14C',
      '12C': '14D',
      '12D': '14DD',
      '12DD': '14E',
      '12E': '',
      '12F': '',
      '14A': '16B',
      '14B': '16C',
      '14C': '16D',
      '14D': '16DD',
      '14DD': '',
      '14E': '',
      '16A': '',
      '16B': '',
      '16C': '',
      '16D': '',
      '16DD': ''

    },
    {
      '8A': '10B',
      '8B': '10C',
      '8C': '10D',
      '8D': '10DD',
      '8DD': '10E',
      '8E': '10F',
      '10A': '12B',
      '10B': '12C',
      '10C': '12D',
      '10D': '12DD',
      '10DD': '12E',
      '10E': '12F',
      '10F': '',
      '10G': '',
      '12A': '14B',
      '12B': '14C',
      '12C': '14D',
      '12D': '14DD',
      '12DD': '14E',
      '12E': '',
      '12F': '',
      '14A': '16B',
      '14B': '16C',
      '14C': '16D',
      '14D': '16DD',
      '14DD': '',
      '14E': '',
      '16A': '',
      '16B': '',
      '16C': '',
      '16D': '',
      '16DD': ''
    },
    {
      '8A': '12A',
      '8B': '12A',
      '8C': '12C',
      '8D': '12D',
      '8DD': '12DD',
      '8E': '12E',
      '10A': '14A',
      '10B': '14B',
      '10C': '14C',
      '10D': '14D',
      '10DD': '14DD',
      '10E': '14E',
      '10F': '',
      '10G': '',
      '12A': '',
      '12B': '16B',
      '12C': '16C',
      '12D': '16D',
      '12DD': '16DD',
      '12E': '',
      '12F': '',
      '14A': '',
      '14B': '',
      '14C': '',
      '14D': '',
      '14DD': '',
      '14E': '',
      '16A': '',
      '16B': '',
      '16C': '',
      '16D': '',
      '16DD': ''
    },
    {
      '8A': '12A',
      '8B': '12A',
      '8C': '12C',
      '8D': '12D',
      '8DD': '12DD',
      '8E': '12E',
      '10A': '14A',
      '10B': '14B',
      '10C': '14C',
      '10D': '14D',
      '10DD': '14DD',
      '10E': '14E',
      '10F': '',
      '10G': '',
      '12A': '',
      '12B': '16B',
      '12C': '16C',
      '12D': '16D',
      '12DD': '16DD',
      '12E': '',
      '12F': '',
      '14A': '',
      '14B': '',
      '14C': '',
      '14D': '',
      '14DD': '',
      '14E': '',
      '16A': '',
      '16B': '',
      '16C': '',
      '16D': '',
      '16DD': ''
    },
    {
      '8A': '10B',
      '8B': '10C',
      '8C': '10D',
      '8D': '10DD',
      '8DD': '10E',
      '8E': '10F',
      '10A': '12B',
      '10B': '12C',
      '10C': '12D',
      '10D': '12DD',
      '10DD': '12E',
      '10E': '12F',
      '10F': '',
      '10G': '',
      '12A': '14B',
      '12B': '14C',
      '12C': '14D',
      '12D': '14DD',
      '12DD': '14E',
      '12E': '',
      '12F': '',
      '14A': '16B',
      '14B': '16C',
      '14C': '16D',
      '14D': '16DD',
      '14DD': '',
      '14E': '',
      '16A': '',
      '16B': '',
      '16C': '',
      '16D': '',
      '16DD': ''
    },
  ]
}