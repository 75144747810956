export default {
  setTab: (state, key, val) => {
    state.product.tab[key] = val;
  },
  selectOption: (data, state, ov) => {
    //Update selected options
    state.product.selected_options[ov.option_type_name] = ov;

    var selected_options = state.product.selected_options;

    //Attempt to find variant that the options match
    var selected_variant = _.find(data.product.variants, (v) => {
      //Format variant options
      var v_options = {};
      _.each(v.option_values, (ov) => {
        v_options[ov.option_type_name] = ov;
      });

      return _.isEqual(selected_options, v_options);
    });

    //Update variant if one is found and not the same as old one
    state.product.selected_variant = selected_variant;

    //Remove reminder
    state.product.reminder = '';

    radio('product/stores').broadcast({sku: state.product.selected_variant.sku});
  },
  selectSize: (data,state,type, value) => {
    //Update selected options
    var selected_sizes = _.clone(state.product.selected_sizes);
    selected_sizes[type] = value;
    state.product.selected_sizes = [];
    state.product.selected_sizes = selected_sizes;

    //Remove reminder
    state.product.reminder = '';

    //Can't continue selection unless we have band AND cup
    if(!selected_sizes['Band'] || !selected_sizes['Cup']) return false;

    //Format the other currently selected options
    var other_selected_options = _.chain(state.product.selected_options)
      .pickBy((o,k) => {return k !== 'Size';})
      .map((o,k) => {
        return {type: k,value: o.name}
      }).sortBy('type').value();

    //Find a variant with a size that matches our string AND
    //All the other options currently selected
    var standard_idx = (state.app.standard !== 'imperial') ? 0 : 1;

    var matching_variant = _.chain(data.product.variants)
      .filter((v) => {
        //Get all variants that have a size matching our string
        var _size_name = _.find(v.option_values, (ov) => {
          return ov.option_type_name == 'Size';
        }).name.split('/')[standard_idx];

        return _size_name == `${selected_sizes['Band']}${selected_sizes['Cup']}`;
      })
      .find((v) => {
        //Match our variants against the other options
        var _ov_options = _.chain(v.option_values)
          .filter((ov) => {return (ov.option_type_name !== 'Size');})
          .map((ov) => {
            return {type: ov.option_type_name,value: ov.name}
          }).sortBy('type').value();

        return _.isEqual(_ov_options, other_selected_options);
      })
      .value();

    if(matching_variant) {
      //Update size option
      state.product.selected_variant = matching_variant;
      state.product.selected_options['Size'] = _.find(matching_variant.option_values, (ov) => {return ov.option_type_name == 'Size';});
    } else {
      state.product.selected_variant = -1;
    }

    radio('product/stores').broadcast({sku: state.product.selected_variant.sku});
  },
  selectStore: (state, store) => {
    state.product.selected_store = store;
  }
}