<template>
  <img
    :alt="alt"
    :src="canLoad ? src : placeholderSrc"
    :height="height"
    :width="width"
    class="image-loader"
    :class="{ loaded: loaded }"
    @load="setLoaded"
  >
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    canLoad: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    placeholderSrc() {
      return this.width && this.height
        ? `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}"%3E%3C/svg%3E`
        : '';
    },
  },

  methods: {
    setLoaded() {
      if (!this.canLoad || !this.src) return;
      this.loaded = true;
      this.$emit('loaded');
    },
  },
};
</script>

<style lang="scss">
.image-loader {
  height: auto;
}
</style>
