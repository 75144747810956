export default {
  next:(state,mutator) => {
    mutator.sizing.direction(state,mutator,'next');
  },
  prev:(state,mutator) => {
    mutator.sizing.direction(state,mutator,'prev');
  },
  direction:(state,mutator,direction) => {
    if(direction == 'next' && state.sizing.step < 3)  state.sizing.step++;
    if(direction == 'prev' && state.sizing.step > 0)  state.sizing.step--;

    //Reset next step when moving either way
    if(state.sizing.step == 1 && direction == 'next') state.sizing.band = false;
    if(state.sizing.step == 2 && direction == 'next') state.sizing.cup = false;

    //Reset cup when moving forward

    mutator.sizing.updateSteps(state);
  },
  toggleTip:(state,toggle) => {
    if(toggle == false) state.sizing.tip_active = false;
    if(toggle == true) state.sizing.tip_active = true;
  },
  updateSteps:(state) => {
    //Go to slide
    // state.sizing.$slider.goToSlide(state.sizing.step);
  }
}