<template>
  <label v-if="rate" class="checkout-delivery-rate">
    <!-- TODO: add the input -->
    <slot />
    <div class="name">
      <break-lines :lines="replacements" :text="rate.name" />
    </div>
    <price-format
      :currency="$store.state.currency.base"
      :price="rate.cost"
    ></price-format>
  </label>
</template>

<script>
// interface ShippingRate {
//   id: number
//   cost: string
//   name: string
// }

import BreakLines from '../break-lines.vue'
import PriceFormat from '../prices/price-format'

export default {
  components: { BreakLines, PriceFormat },

  props: {
    rate: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      replacements: [
        ['(', '\n('],
        [new RegExp(/,\s+(.+$)/), '<div>$1</div>'],
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout-delivery-rate {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.name {
  padding-right: 10px;
}
</style>

<style lang="scss">
@import '~styles/frontend/base.scss';

.checkout-delivery-rate {
  input {
    margin-top: 5px;
  }

  .break-lines > div {
    color: $midgrey;
  }
}
</style>
