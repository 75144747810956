<template>
  <div class="product-purchase">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '~styles/frontend/base';

.product-purchase {
  left: auto;
  right: 0;

  &__heading {
    @include bp-m();
  }

  &__title {
    @include hx();
    @include hx-m();
    @include sans2();
    @include bp-s();

    font-size: 18px;
  }

  &__description {
    display: none;
    width: 320px;
    margin: auto;
    padding-bottom: 30px;

    p {
      padding-bottom: 1em;
    }
  }

  &__buy {
    padding: 1em 0;
    max-width: 240px;
    margin: 0 auto;

    .message {
      padding-bottom: 20px;

      h3 {
        @include cx-xs();
        @include cx-uc();
        @include sans2();
      }

      p {
        @include cx-xs();

        color: $midgrey;

        span {
          @include helper-link-underline();

          a {
            @include helper-link-arrow();

            color: $midgrey;
          }
        }
      }

      form {
        transition: opacity 0.2s ease-out;

        fieldset {
          position: relative;

          input {
            @include placeholder($lightgrey);

            width: 100%;
            font-size: 11px;
            line-height: 1.8;
            border-bottom: 1px solid $lightestgrey;
          }

          button {
            @include icons();
            @include icons-helper();

            position: absolute;
            right: 0;
            bottom: 0%;
            font-size: 12px;
            color: $darkgrey;
            line-height: 18px;

            &:before {
              content: '';
            }
          }
        }

        &.sending {
          opacity: 0.6;
        }
      }
    }

    .info {
      @include helper-link-underline();

      text-align: center;

      span,
      a {
        color: $midgrey;
        display: inline-block;
        cursor: pointer;

        + span,
        + a {
          margin-left: 10px;
        }

        &.no-stock {
          color: $lightgrey;
        }

        &.unselected {
          color: $lightestgrey;
          pointer-events: none;

          &:hover {
            cursor: default;
            border: none;
          }
        }
      }

      .inner {
        padding-top: 20px;
        a {
          @include helper-link-arrow();
        }
        span {
          cursor: default;
          &:hover {
            border: none;
          }
          &.in-stock {
            color: $dark;
          }
        }
      }

      &.info-mobile {
        display: none;
      }
    }
  }

  &__add {
    margin-bottom: 15px;
    @include cx-xs();
    @include cx-uc();
    @include sans2();

    width: 100%;
    text-align: center;
    padding: 15px 20px;
    text-transform: uppercase;
    border: 1px solid $dark;

    &.invalid,
    &.not_orderable,
    &.unavailable,
    &:disabled {
      cursor: default;
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .note {
    @include helper-link-underline();

    padding-bottom: 40px;

    a {
      @include helper-underline();

      &:after {
        padding-left: 7px;
      }

      &:nth-of-type(1) {
        margin-right: 15px;
      }
    }
  }

  .single,
  .options {
    padding-bottom: 20px;
  }

  .single {
    span {
      @include helper-underline();
      cursor: default;
    }
  }

  .options {
    .option {
      h3 {
        margin-bottom: 1em;

        span.icon {
          display: none;
        }

        &.error {
          color: $red;
          span.icon {
            display: inline-block;
            vertical-align: top;
            width: 14px;
            height: 16px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      ul {
        @include horizontal-list();

        li {
          cursor: pointer;
          border-bottom: 1px solid transparent;
          transition: opacity 0.2s ease-out;
          opacity: 0.5;
          text-transform: uppercase;

          &.active,
          &:hover {
            border-bottom: 1px solid $darkgrey;
          }

          &.available {
            opacity: 1;
          }

          &.sold {
            text-decoration: line-through;
          }

          + li {
            margin-left: 25px;
          }
        }
      }

      &.size {
        h3 {
          .selected-size {
            text-transform: uppercase;
          }
        }
      }
      + .option {
        padding-top: 20px;
      }
    }
  }

  .stock {
    h3 {
      @include cx-xs();
      @include cx-uc();
      @include sans2();
    }

    ul {
      li {
        @include cx();
        @include cx-darkestgrey();
        @include cx-xs();

        span {
          display: inline-block;

          &.in-stock {
            @include cx-midgrey();
          }

          &.no-stock {
            @include cx-lightgrey();
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint_m) {
    &__description {
      display: block;
    }

    &__heading {
      display: none;
    }

    .info {
      padding-bottom: 40px;

      &.info-desktop {
        display: none;
      }

      &.info-mobile {
        display: block;
      }
    }
  }
}
</style>
