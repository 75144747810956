<template>
  <span class="price-format" v-if="currency">
    {{ priceFormat }}
  </span>
</template>

<script>
export default {
  props: {
    currency: {
      type: String,
      required: true,
    },
    price: {
      type: [Number, String],
      required: true,
    },
    showCode: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    priceFormat() {
      const numberFormat = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: this.currency,
        currencyDisplay: 'code',
      }).format(+this.price)

      return this.showCode ? numberFormat : numberFormat.replace(/[A-Z]+/g, '')
    },
  },
}
</script>

<style lang="scss">
.price-format {
  display: inline-block;
  &--original {
    text-decoration: line-through;
  }
  + .price-format {
    margin-left: 1ch;
  }
}
</style>
