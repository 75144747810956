export default {
  isLanding: (body_id, win) => {
    var height     = win.height;
    var scroll_top = win.scroll_top;

    var state = 1;
    if(scroll_top < height - 100 && body_id == 'page-home') {
      state = 2;
    }

    return (state == 2);
  },
}