<template>
  <div
    class="notification-banner"
    :style="{ '--height': `${height}px` }"
  >
    <transition name="banner">
      <div
        v-if="show"
        class="notification-banner__banner"
      >
        <p v-html="text" />
        <close-button @click="close" />
      </div>
    </transition>
  </div>
</template>

<script>
import CloseButton from './close-button';
import { passedStoredTimeout } from '../../utils/storage';

export default {
  components: {
    CloseButton,
  },

  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      height: 0,
      show: this.enabled && passedStoredTimeout('notificationBanner', 6.048e+8), // One week
    };
  },

  methods: {
    close() {
      localStorage.setItem('notificationBanner', Date.now());
      this.height = this.$el.offsetHeight;
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
@import '~styles/frontend/base';

.notification-banner {
  overflow: hidden;

  &__banner {
    position: relative;
    padding: 5px 40px;
    background: $banner;
    text-align: center;
    /* border-bottom: 1px solid $lightgrey; */
  }

  .close-button {
    position: absolute;
    top: 6px;
    right: 40px;
  }

  @media (max-width: $breakpoint_s) {
    &__banner {
      padding-left: 25px;
      padding-right: 45px;
    }

    .close-button {
      right: 25px;
    }
  }
}

.banner-enter-active,
.banner-leave-active {
  transition: margin 0.4s ease;
}

.banner-enter,
.banner-leave-to {
  margin-top: calc(-1 * var(--height));
}
</style>
