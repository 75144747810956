<template>
  <div class="product-bra-selection">
    <fieldset class="option">
      <legend>
        Select Band: <span class="selection">{{ selectedBand }}</span>
      </legend>
      <div class="options">
        <product-selection-input
          v-for="band in bands"
          :key="JSON.stringify(band)"
          v-model="selectedBand"
          :label="band[0]"
          name="band"
          :class="{ unavailable: !bandAvailable(band) }"
          >{{ band[standardIndex] }}</product-selection-input
        >
      </div>
    </fieldset>
    <fieldset class="option">
      <legend>
        Select Cup: <span class="selection">{{ selectedCup }}</span>
      </legend>
      <div class="options" :class="{ unselected: !selectedCup }">
        <product-selection-input
          v-for="cup in cups"
          :key="JSON.stringify(cup)"
          v-model="selectedCup"
          :label="cup[0]"
          name="cup"
          :class="{
            unavailable: !cupAvailable([selectedBand], cup),
            unpurchasable: !cupPurchasable(cup),
          }"
          >{{ cup[standardIndex] }}</product-selection-input
        >
      </div>
    </fieldset>
    <slot :selected-size="selectedSize" />
    <product-selection-message
      :selected-variant="selectedVariant"
      :selected-size="selectedSize"
      :unpurchasable="selectedBand && selectedCup && !selectedVariant"
      :warehouse="warehouse"
    />
  </div>
</template>

<script>
import { uniqueSets, variantHasOrderableStock } from '../../../utils/helpers'
import ProductSelectionInput from './product-selection-input.vue'
import ProductSelectionMessage from './product-selection-message.vue'
import useStandard from '../../mixins/useStandard'

const allNums = /(\d+)/g
const allAlphas = /([a-zA-Z]+)/g

const metricSizes = (optionValue) => {
  const [size] = optionValue.name.split('/')
  const [optionBand] = size.match(allNums)
  const [optionCup] = size.match(allAlphas)
  return { optionBand, optionCup, size }
}

export default {
  components: {
    ProductSelectionInput,
    ProductSelectionMessage,
  },

  mixins: [useStandard],

  props: {
    optionValues: {
      type: Array,
      default: () => [],
    },
    selectedVariant: {
      type: Object,
      default: null,
    },
    variants: {
      type: Array,
      default: () => [],
    },
    warehouse: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedBand: '',
      selectedCup: '',
    }
  },

  computed: {
    availableVariants() {
      return this.variants.filter(variantHasOrderableStock(this.warehouse))
    },
    bands() {
      const values = this.mapValues(allNums)
      return uniqueSets(values).sort((a, b) => a - b)
    },
    cups() {
      const values = this.mapValues(allAlphas)
      return uniqueSets(values).sort()
    },
    cupsMatchingBand() {
      return this.selectedBand
        ? this.cups.filter(
            ([cup]) =>
              !!this.findVariantByMetricSize(`${this.selectedBand}${cup}`)
          )
        : this.cups
    },
    selectedSize() {
      return (
        this.selectedBand &&
        this.selectedCup &&
        this.selectedBand + this.selectedCup
      )
    },
  },

  watch: {
    selectedSize(selectedSize) {
      this.$emit('update:selected-size', selectedSize)
      if (!selectedSize) return
      const match = this.findVariantByMetricSize(selectedSize)
      this.$emit('update:selected-variant', match)
    },
    standard() {
      this.clear()
    },
  },

  methods: {
    clear() {
      this.selectedBand = ''
      this.selectedCup = ''
    },
    bandAvailable([band]) {
      return !!this.availableVariants.find((variant) =>
        variant.option_values.some((optionValue) => {
          const { optionBand } = metricSizes(optionValue)
          return band === optionBand
        })
      )
    },
    cupAvailable([band], [cup]) {
      return !!this.availableVariants.find((variant) =>
        variant.option_values.some((optionValue) => {
          const { optionBand, optionCup } = metricSizes(optionValue)
          if (band) {
            return band === optionBand && cup === optionCup
          }
          return cup === optionCup
        })
      )
    },
    cupPurchasable([cup]) {
      return this.selectedBand
        ? !!this.findVariantByMetricSize(this.selectedBand + cup)
        : !!this.findVariantByMetricCup(cup)
    },
    findVariantByMetricCup(cup) {
      return this.variants.find((variant) =>
        variant.option_values.some(
          (optionValue) => cup === metricSizes(optionValue).optionCup
        )
      )
    },
    findVariantByMetricSize(metricSize) {
      return this.variants.find((variant) =>
        variant.option_values.some(
          (optionValue) => metricSize === metricSizes(optionValue).size
        )
      )
    },
    splitSizeNameByStandard(sizeName) {
      return sizeName.split('/')[this.standardIndex]
    },
    mapValues(regexp) {
      return this.optionValues.map((value) => value.name.match(regexp))
    },
  },
}
</script>

<style lang="scss" scoped></style>
