<template>
  <component
    :is="component"
    type="submit"
    class="ProductAdd"
    :class="{
      adding,
      available,
      preorder,
      error,
      unpurchasable,
    }"
  >
    <transition :name="textTransitionName" :mode="textTransitionMode">
      <span :key="text">{{ text }}</span>
    </transition>
  </component>
</template>

<script>
export default {
  props: {
    adding: {
      type: Boolean,
      default: false,
    },
    added: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: true,
    },
    component: {
      type: [String, Object],
      default: 'button',
    },
    preorder: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    poa: {
      type: Boolean,
      default: false,
    },
    textTransitionName: {
      type: String,
      default: 'fade',
    },
    textTransitionMode: {
      type: String,
      default: 'out-in',
    },
    unpurchasable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    text() {
      if (this.adding) {
        return 'Adding'
      }
      if (this.added) {
        return 'Added to Cart'
      }
      if (this.unpurchasable) {
        return 'Unavailable'
      }
      if (!this.available) {
        return 'Sold Out'
      }
      if (this.error) {
        return 'Error'
      }
      if (this.poa) {
        return 'Enquire'
      }
      return 'Add to Cart'
    },
  },
}
</script>
