<template>
  <div class="product-afterpay-modal">
    <button
      class="product-afterpay-modal__close-background"
      aria-label="Close"
      @click="$emit('close')"
    />
    <button
      aria-label="Close"
      class="product-afterpay-modal__close-button"
      @click="$emit('close')"
    >
      Close
    </button>
    <div class="product-afterpay-modal__container">
      <div class="product-afterpay-modal__inner">
        <a
          class="product-afterpay-modal__link"
          href="https://www.afterpay.com/purchase-payment-agreement"
          target="_blank"
        >
          <img
            src="https://static.afterpay.com/lightbox-desktop.png"
            class="product-afterpay-modal__image product-afterpay-modal__image_desktop"
          >
          <img
            src="https://static.afterpay.com/lightbox-mobile.png"
            class="product-afterpay-modal__image product-afterpay-modal__image_mobile"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.style.overflow = 'hidden';
  },

  destroyed() {
    document.body.style.overflow = '';
  },
};
</script>

<style lang="scss">
@import "~styles/frontend/variables";

.product-afterpay-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  padding: 40px;

  &:after {
    content: '';
    background: rgba(250, 250, 250, 0.9);
  }

  &:after,
  &__container,
  &__close-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__close-background {
    z-index: 10;
  }

  &__close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 20px;
    z-index: 10;
    font-size: $font-xs;
  }

  &__container {
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow: scroll;
    pointer-events: none;
  }

  &__inner {
    margin: auto;
    padding: 60px 25px;
  }

  &__link {
    margin: auto;
    pointer-events: auto;
  }

  &__image {
    &_mobile {
      display: none;
    }
  }

  @media (max-width: $breakpoint-s) {
    &__container {
      overflow: auto;
      pointer-events: auto;
    }
    
    &__close-button {
      top: 5px;
      right: 5px;
      z-index: 100;
    }

    &__image {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: initial;
      }
    }
  }
}
</style>
