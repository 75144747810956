import _ from 'lodash'

export default {
  computed: {
    formatTotals() {
      // Totals: Subtotal, Tax, Shipping, Voucher Adjustments, Gift Card Payment, Grand Total

      // Destructure assignment
      const {
        item_total = '',
        ship_total = '',
        gift_card_total = '',
        outstanding_balance = '',
        adjustments = [],
        promotions = [],
        shipment_promotions = [],
      } = this.cart

      // Taxes
      let formatted_taxes = []
      if (adjustments.length > 0) {
        formatted_taxes = _.chain(adjustments)
          .filter((v) => v.source_type === 'Spree::TaxRate' && v.amount > 0)
          .groupBy('label')
          .map((adjs, key) => ({
            key,
            value: _.sumBy(adjs, (adj) => parseFloat(adj.amount)),
            class: 'tax',
          }))
          .value()
      }

      const formatted_promotions = promotions
        .concat(
          shipment_promotions.reduce(
            (acc, shipment) => acc.concat(shipment),
            []
          )
        )
        .map((promo) => ({
          key: promo.label,
          value: promo.amount,
          class: 'deductable',
        }))

      // Subtotals
      let totals = [
        { key: 'Subtotal', value: item_total },
        { key: 'Shipping Total', value: ship_total },
        ...formatted_taxes,
        ...formatted_promotions,
        {
          key: 'Gift Card Payment',
          value: `-${gift_card_total}`,
          class: 'deductable',
        },
      ]

      totals = _.filter(totals, (v) => v.value > 0 || v.value < 0)

      // Add grand total
      totals.push({
        key: 'Grand Total',
        value: outstanding_balance,
        class: 'grand-total',
      })

      return totals
    },
  },
  methods: {
    getSize: (li) => {
      const option = li.variant.option_values.find(
        (ov) => ov.option_type_name === 'Size'
      )

      return option ? option.name : false
    },
  },
}
