<template>
  <transition name="overlayFade">
    <div
      v-if="wrapOverlay === name"
      class="overlay-wrap"
      :class="getWrapClass"
      @click.self="$store.commit('overlay/SET_OVERLAY', '')"
    >
      <transition :name="transition">
        <div
          v-if="innerOverlay === name"
          class="overlay"
          :class="getOverlayClass"
        >
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';

export default {
  props: {
    transition: {
      type: String,
      default: 'fade',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerOverlay: '',
      wrapOverlay: '',
    };
  },
  watch: {
    overlay: {
      handler(overlay) {
        if (overlay === '') {
          this.innerOverlay = '';
          setTimeout(() => { this.wrapOverlay = ''; }, 600);
        } else {
          this.wrapOverlay = overlay;
          setTimeout(() => { this.innerOverlay = overlay; }, 400);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...Vuex.mapState({
      overlay: state => state.overlay.current,
    }),
    getOverlayClass() {
      return `overlay--${this.name}`;
    },
    getWrapClass() {
      return `overlay-wrap--${this.name}`;
    },
  },
}
</script>

<style lang="scss">
  .overlay-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(244,239,232,0.92);
    z-index: 999999;
    // opacity:0;
    display: flex;
  }

  .overlay {
    display: block;
    margin: auto;
    width: auto;
    height: auto;
    position: relative;
    padding: 20px;
    border: none;
    background: #fff;
  }
</style>