<template>
  <span
    class="amount"
    v-html="showAmount()"
  />
</template>

<script>
export default {
  name: 'Amount',
  props: ['data'],
  data: () => ({
    currency: '',
  }),
  methods: {
    getCurrencyState() {
      return _.clone(window.app.state.currency);
    },
    getCurrency() {
      const { data } = this;
      const currency_state = this.getCurrencyState();

      // Allow using the store currency - for checkout
      if (data.use_store_currency) { currency_state.code = currency_state.base; }

      // Get currency from list
      const currency = _.find(currency_state.currencies, (v) => v.code === currency_state.code);

      return currency;
    },
    showAmount() {
      const { data } = this;
      const currency = this.getCurrency();
      const currency_state = this.getCurrencyState();
      let current_amount = 0;
      let original_amount = 0;

      if (!currency) return 'NA/C';

      // Single currency price
      if (data.amount) current_amount = parseFloat(data.amount);
      if (data.original_price) original_amount = parseFloat(data.original_amount);

      const find_current_amount = (data.amounts) ? data.amounts.find(
        (a) => a.currency === currency_state.base,
      ) : false;

      if (find_current_amount) {
        current_amount = parseFloat(find_current_amount.price);

        if (find_current_amount['on_sale?']) {
          original_amount = parseFloat(find_current_amount.original_price);
        }
      }

      // Convert the amount
      const converted_current_amount = this.convert(
        current_amount,
        currency.rate,
      );

      const formatted_amount = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: currency.code,
        currencyDisplay: 'code',
      }).format(converted_current_amount);

      // Format original price (for sales)
      let formatted_original = false;
      if (original_amount > 0) {
        const converted_original_amount = this.convert(
          original_amount,
          currency.rate,
        );
        formatted_original = new Intl.NumberFormat('en-EN', {
          style: 'currency',
          currency: currency.code,
          currencyDisplay: 'code',
        }).format(converted_original_amount);
      }
      return formatted_original
        ? `<s>${formatted_original}</s> ${formatted_amount}`
        : `${formatted_amount}`;
    },
    convert: (amount, rate = 1) => {
      if (isNaN(amount)) return 0;

      return parseFloat(amount) * rate;
    },
  },
};
</script>
