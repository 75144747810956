export default class Hash {
  constructor() {
    this.$window = $(window);
  }
  
  pub() {
    this.$window.on('load', (e) => {
      this.onHash(e);
    });
  }

  onHash(e) {
    //Get current hash
    var hash = false;
    if(window.location.hash) {
      hash = window.location.hash.substring(1);
      radio('window/hash').broadcast({
        hash:hash
      });
    }
  }
}