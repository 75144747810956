<template>
  <overlay-wrap name="search">
    <div class="logo">
      <slot name="logo" />
    </div>
    <button
      class="close"
      @click.prevent="$store.commit('overlay/SET_OVERLAY', '')"
    >
      Close
    </button>
    <search-form v-if="overlay === 'search'" />
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex';
import OverlayWrap from '~/components/overlay/wrap';
import SearchForm from '~/components/search-form';

export default {
  components: {
    OverlayWrap,
    SearchForm
  },

  computed: {
    ...Vuex.mapState({
      overlay: state => state.overlay.current
    })
  }
}
</script>

<style lang="scss">
@import "~styles/frontend/base";

.overlay.overlay--search {
  @include helper-lightbox-close();

  width:100%;
  height:100%;

  .logo {
    margin-top:0;
    text-align:center;

    img {
      height:50px;
      width:auto;
    }
  }
}
</style>