import Base from '../base';
import Tabs from '../../utils/tabs';

export default class Default extends Base {
  init() {

    if(this.d.body_id !== 'page-help') return;


    this.el = {
      $tab_pub : $('.container-page-help').find('.tab-pub [data-pub="tab"]'),
      $tab_sub : $('.container-page-help').find('.tab-sub [data-sub~="tab"]'),
    }

    radio('vue/loaded').subscribe(() => {
      this.tabs = new Tabs({
        $tab_pub : this.el.$tab_pub,
        $tab_sub : this.el.$tab_sub,
      });
    });
  }
} 