<template>
  <div class="product-blocks">
    <ProductBlock
      v-for="p in products"
      :key="'product_' + p.id"
      :product="p"
    />
  </div>
</template>

<script>
import ProductBlock from '~/components/blocks/product';

export default {
  name: 'ProductBlocks',
  props: {
    products: {
      type: Array,
      default: () => ([]),
    },
  },

  components: {
    ProductBlock,
  },
};

</script>

<style lang="scss">
  // @import "~styles/frontend/base";
  .product-blocks {
    display: flex;
    flex-wrap: wrap;
  }
</style>