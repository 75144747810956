// Pages
import Page from './sub/page';
import Stockist from './sub/stockist';

export default class Sub {
  constructor(main, utils) {
    this.m = main;
    this.u = utils;

    // Group pages
    this.page = new Page(main, this.u);
    this.stockist = new Stockist(main, this.u);
  }
}