export default {
  computed: {
    standard() {
      return this.$store.getters['currency/measurementStandard']
    },
    standardIndex() {
      switch (this.standard) {
        case 'metric':
          return 0
        case 'imperial':
          return 1
        default:
          return 0
      }
    },
  },
}
