export const isBraSize = (optionValue) => (
  // 1-2 digits, 1-2 letters, slash, repeat
  /^\d{1,2}[a-zA-Z]{1,2}\/\d{1,2}[a-zA-Z]{1,2}$/.test(optionValue?.name)
);

export function formatOptionValue(value, type, standard = 'metric') {
  // Bra Sizes are in the format metric/imperial
  const standardIndex = (standard !== 'imperial') ? 0 : 1;
  const size = value.split('/')[standardIndex];

  // Band is number, cup is letter
  if (type === 'Band') {
    // Replace letters with nothing
    return size.replace(/[a-zA-Z]/g, '');
  }

  // Replace numbers with nothing
  return size.replace(/[0-9]/g, '');
}

export const optionValueByType = (typeName) => (value) => (
  value.option_type_name === typeName
);
