<template>
  <div class="search-form">
    <form action="/products" accept-charset="UTF-8" method="get">
      <input name="utf8" type="hidden" value="✓">
      <label
        for="keywords"
        class="search-form__label"
      >
        Search:
      </label>
      <input
        v-model="query"
        ref="input"
        type="search"
        name="keywords"
        id="keywords"
        required="required"
        placeholder="Size or style"
        class="search-form__input"
      >
    </form>
    <div class="suggestions">
      <h3 class="suggestions__title">Suggestions:</h3>
      <ul>
        <li
          v-for="suggestion in suggestions"
          :key="suggestion"
          class="suggestions__suggestion"
        >
          <button
            @click="onSuggestion(suggestion)"
            class="suggestions__button"
          >
            {{ suggestion }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      query: '',
      suggestions: [
        '10b',
        '12dd',
        'Underwire',
        'Brief',
        'Black',
        'Bonnie'
      ]
    }
  }, 

  methods: {
    onSuggestion(suggestion) {
      this.query = suggestion
    },
  },

  mounted() {
    this.$refs.input.focus()
  }
}
</script>

<style lang="scss">
@import "~styles/frontend/base";

.search-form {
  padding-top: 80px;
  text-align: center;

  &__label {
    @include hx-style1();
    @include bp-xs();
    
    display: block;
    text-align: center;
  }

  &__input {
    @include cx();
    @include cx-xxl();
    @include cx-dark();
    @include placeholder($lightgrey);
    @include sans2();
    @include appearance-none();

    text-align: center;

    &:focus {
      outline-style: none;
    }

    width: 320px;
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.suggestions {
  @include cx();
  @include cx-xs();

  &__title  {
    @include bp-s();
  }

  &__suggestion + &__suggestion {
    margin-top: 5px;
  }

  &__button {
    font-size: inherit;
  }
}
</style>