<template>
  <overlay-wrap name="product-stores">
    <slot />
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex'
import OverlayWrap from '~/components/overlay/wrap'

export default {
  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
    }),
  },
  components: {
    OverlayWrap,
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/base';
@import '~styles/frontend/forms';

.overlay.overlay--product-stores {
  @include helper-lightbox-close();
  // @include helper-panel-lightbox();
  @include helper-table-help();
  justify-content: flex-start;
  align-items: flex-start;

  .inner {
    @include helper-link-underline();
    @include cx-xs();
    width: 520px;
    width: 680px;
    margin: auto;
    padding-bottom: 40px;
    table {
      border: 0;
      tr {
        th,
        td {
          text-align: left;
          padding: 10px 0;
        }
        th {
          border-bottom: 1px solid $lightestgrey;
          padding-top: 0;
        }
        + tr {
          border-bottom: 1px solid $lightestgrey;
        }
      }
      thead {
        tr {
          th {
            @include sans2();
            @include cx-xxs();
          }
        }
      }
    }
    h1 {
      padding: 0 10px 40px;
    }
    .stores {
      @include spanX(12, 8, 10px);
      padding-right: 40px;
      table {
        thead {
          tr {
            th {
              @include sans2();
              @include cx-uc();
            }
          }
        }
        tbody {
          tr {
            cursor: pointer;
            td {
              &.store_name {
                text-transform: capitalize;
              }
              &.in-stock {
              }
              &.out-of-stock {
                color: $lightgrey;
              }
            }
            &.active,
            &:hover {
              border-bottom: 1px solid $lightgrey;
            }
            + tr {
              border-top: 1px solid $lightestgrey;
            }
          }
        }
      }
    }
    .maps {
      @include spanX(12, 4, 10px);
      padding-left: 20px;
      .link {
        padding-top: 10px;
        @include helper-link-underline();
        a {
          @include helper-link-arrow();
        }
      }
    }
    .location {
      @include clearfix();
      .name {
        @include sans2();
        @include cx-uc();
        @include cx-xs();
        padding-bottom: 10px;
        padding-left: 10px;
      }
      .contact {
        > div {
          @include spanX(12, 4, 10px);
          + div {
            padding-left: 20px;
          }
        }
      }
    }
    @include respond-to(xs) {
      padding-top: 100px;
      overflow-y: scroll;
      max-width: 100%;
      padding: 20px 30px;

      .stores,
      .maps {
        width: 50%;
      }
      .stores {
        padding-right: 20px;
      }
      .location {
        .contact {
          > .address,
          > .contact {
            width: 50%;
          }
          > .hours {
            float: none;
            width: 100%;
            padding: 20px calc(#{$gutter}/2) 0;
          }
        }
      }
      table {
        tr {
          td {
            padding: 2px 0;
            &:first-child {
              margin-top: 5px;
            }
          }
        }
      }
    }
    @include respond-to(xxs) {
      width: calc(100vw - 100px);
      .stores,
      .maps {
        width: 100%;
        float: none;
      }
      .maps {
        padding: 0 calc(#{$gutter}/2) 30px;
      }
      .location {
        .contact {
          > .address,
          > .contact,
          > .hours {
            float: none;
            width: 100%;
            padding: 10px calc(#{$gutter}/2) 0;
          }
        }
      }
    }
  }
}
</style>