<template>
  <overlay-wrap
    class="overlay-mmenu__wrap"
    transition="slideFromLeft"
    name="mmenu"
  >
    <slot />
  </overlay-wrap>
</template>

<script>
import Vuex from 'vuex';

import OverlayWrap from './wrap';

export default {
  components: {
    OverlayWrap,
  },
};
</script>

<style lang="scss">
@import "~styles/frontend/base";

.overlay-wrap--mmenu {
  // z-index: 200;
}

.overlay.overlay--mmenu {
  position: absolute;
  left: 0;width: $mmenu_width;
  top: 0;bottom: 0;
  background: rgba(255,255,255,1);
  border-right: 1px solid $darkgrey;
  padding: 0;
  overflow-y: auto;

  @include respond-to(xxs) {
    width: calc(100vw - 50px);
  }


  form {
    @include placeholder($darkgrey);

    position:relative;

    input {
      @include typography('body-m');

      height: 45px;
      border-bottom: 1px solid $lightgrey;
      width: 100%;
      padding: 10px 50px 10px 20px;
      margin:0;

      &::placeholder {
        @include sans2;
        @include cx-xxs;
        @include cx-uc;
      }
    }

    button {
      @include appearance-none;
      @include icons;
      @include icons-helper;

      position:absolute;
      right: 20px;
      top: 8px;
      height: 30px;
      width: 20px;
      font-size: 12px;
      line-height: 20px;
      vertical-align: middle;
      color: $lightgrey;

      &:hover {
        color: $darkgrey;
      }
    }
  }

  &__main,
  &__sub {
    @include typography('body-m');

    padding: 30px 20px;
    border-bottom: 1px solid $lightgrey;

    .togglable {
      &__button {
        outline: none;
      }
    }
  }

  &__taxon-children,
  &__submenu-children {
    padding-bottom: 5px;
  }

  &__taxon {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__taxon-children {
    .menu-item {
      margin-top: 1em;
    }
  }

  /* &__link:not(:last-child), */
  &__submenu {
    margin-bottom: 0.75em;
  }

  &__submenu-children {
    .menu-item {
      margin-top: 1em;

      &__link {
        color: $midgrey;
      }
    }
  }

  &__button {
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
  }

  &__button-text {
    &_toggle {
      position: relative;
      padding-right: 15px;

      &:after {
        content: '+';
        position: absolute;
        // top: 0;
        right: 0;
      }

      &_active {
        &:after {
          content: '-';
        }
      }
    }

    &_menu {
      @include sans2;
      @include cx-xxs;
      @include cx-uc;

    }
  }

  // @include respond-to(xxs) {
  //   width:75vw;
  //   left:-75vw;
  // }
}
</style>
