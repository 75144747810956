<template>
  <div
    class="product-block-gallery"
    :class="{ 'product-block-gallery--ready': loadedCount > 1 }"
  >
    <div class="product-block-gallery__image product-block-gallery__image--cover">
      <image-loader
        :alt="product.name"
        :src="productImage"
        :width="width"
        :height="height"
        :can-load="shouldLazyLoad"
        @loaded="onLoaded"
      />
    </div>
    <div
      v-if="!related"
      class="product-block-gallery__image product-block-gallery__image--hover"
    >
      <image-loader
        :alt="product.name"
        :src="productImageAlt"
        :width="width"
        :height="height"
        :can-load="shouldLazyLoad && loadedCount > 0"
        @loaded="onLoaded"
      />
    </div>
  </div>
</template>

<script>
import LazyLoad from '~/mixins/lazy-load';
import ImageLoader from '~/components/common/image-loader';

export default {

  components: {
    ImageLoader,
  },

  mixins: [
    LazyLoad,
  ],

  props: {
    product: {
      type: Object,
      required: true,
    },
    related: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadedCount: 0,
      width: 42.15,
      height: 100,
    };
  },

  computed: {
    productImage() {
      const { images } = this.product;
      let format = 'block';
      let index = 0;

      if (!images.length) return null;

      if (this.related) {
        format = 'related';
        index = images.length - 1;
      }

      return images[index][`product_${format}_url`];
    },

    productImageAlt() {
      const { images } = this.product;

      if (this.related) return null;

      return images[images.length - 1].product_block_url;
    },
  },

  methods: {
    onLoaded() {
      this.loadedCount += 1;
    },
  },
};
</script>

<style lang="scss">
@import "~styles/frontend/base";

.product-block-gallery {
  position:relative;
  padding-top: 142.15%;
  overflow: hidden;

  &__image {
    @include transition(opacity 0.2s ease-out);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      display: block;
      width: 100%;

      @supports (object-fit: cover) {
        height: 100%;
        object-fit: cover;
      }
    }

    &--hover {
      opacity:0;
    }
  }
}
</style>
