<template>
  <p v-if="value" class="shipping-note">
    Complimentary shipping on all orders over {{ value }}
  </p>
</template>

<script>
export default {
  props: {
    currency: {
      type: String,
      required: true,
    },
  },

  computed: {
    value() {
      switch (this.currency) {
        case 'NZD':
          return `$250 NZD`
        case 'AUD':
          return `$250 AUD`
        case 'USD':
          return `$250 USD to USA`
        case 'CAD':
          return `$300 USD to Canada`
        case 'GBP':
          return `$300 USD to UK`
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~styles/frontend/base.scss';

.shipping-note {
  @include cx-xs();
  @include sans();

  text-transform: initial;
  margin-top: 10px;
}
</style>
