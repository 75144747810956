<template>
  <div class="overlay-cart__set">
    <div class="overlay-cart__related">
      <h4>Would you like the set?</h4>
      <div class="item">
        <div class="image">
          <img :src="related.image" />
        </div>

        <div class="details">
          <div class="overlay-cart__related__title" v-text="related.name"></div>
          <price-display class="overlay-cart__related__price" :prices="[{ price: related.price }]" />

          <div class="overlay-cart__related__controls">
            <div class="variants" v-if="related.options">
              <div class="selector">
                <select v-model="related_id">
                  <option value="-1">Select a variant</option>
                  <option :value="v.id" v-for="v in related.options" v-text="v.text"></option>
                </select>
              </div>
            </div>
            <button class="add" @click.prevent="addRelated()">Add to Cart</button>
            <button class="cancel" @click.prevent="close()">No thanks</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceDisplay from '~/components/prices/price-display';

export default {
  props: {
    related: {
      type: Object,
    },
    cart: {
      type: Object,
    },
  },
  data() {
    return {
      related_id: -1,
    };
  },
  methods: {
    addRelated() {
      console.log('TODO - write add related to cart code')
    },
    close() {
      console.log('TODO - write close related code')
    },
  },
  components: {
    PriceDisplay,
  },
};
</script>


<style lang="scss">
@import "~styles/frontend/base";
.overlay-cart {
  &__related {
    padding-top:10px;
    h4 {
      @include hx();
      @include hx-xs();
      @include sans();
      @include bm-m();
      text-align:center;
      padding-top:10px;
    }
    &__title {
      margin:0;
    }
    &__price {
      position:static;
      @include bm-xs();
    }
    &__controls {
      padding-left:110px;
    }

    @include respond-to('xxs') {
      &__controls {
        padding-left:0;
        padding-top:10px;
        clear:left;
        select {
          max-width:100%;
        }
      }
    }
  }
}
</style>