import Ajax from './utils/ajax';
import Helpers from './utils/helpers/helpers';
import Cookies from './utils/helpers/cookies';
import Animate from './utils/helpers/animate';
import Fixes from './utils/helpers/fixes';
import DisableScroll from './utils/helpers/disableScroll';
import Preload from './utils/preload';
import Window from './utils/window/pub';
import Load from './utils/window/load';
import Hash from './utils/window/hash';
import Lbox from './utils/lbox/lbox';
import Lightbox from './utils/lbox/legacy';
import CheckoutValidations from './utils/helpers/checkoutValidations';
import Stripe from './utils/helpers/stripe';

// Lightbox used in:
// Modals:
//  * Account (Login, flashes)
import Spree from './utils/spree/spree';

//External
import ccValidator from 'card-validator';

export default class Utils {
  constructor(main) {
    this.m = main;

    this.fixes   = new Fixes(this);
    this.helpers = new Helpers(this);

    this.preload        = new Preload(this);
    this.window         = new Window(this);
    this.load           = new Load(this);
    this.hash           = new Hash(this);
    this.disable_scroll = new DisableScroll(this);
    // this.newsletter     = new Newsletter(this);
    this.lightbox       = new Lightbox(this);
    this.lbox           = new Lbox(this);
    this.ajax           = new Ajax(this);
    this.cookies        = new Cookies(this);
    this.animate        = new Animate(this);
    

    this.spree = new Spree(this);
    this.stripe = Stripe;

    //Validations
    this.ccV = ccValidator;
    this.checkout_validations = CheckoutValidations;
  }
}  