import ContentfulMediaParser from './contentful-media-parser';
// import VideoPlayer from './video-player';

export default {
  name: 'contentful-media-block',

  functional: true,

  props: {
    entry: {
      type: Object,
      required: true,
    },
  },

  render(h, { props, data }) {
    const { entry } = props;
    const { fields } = entry;

    const componentClass = 'ctf-media-block';
    const classList = data.class.join(' ');

    return (
      <div class={`${componentClass} ${classList}`}>
        {fields.media
          .map((media) => (
            <ContentfulMediaParser
              media={media}
              showCaption={true}
            />
          ))}
        {fields.caption && <figcaption>{fields.caption}</figcaption>}
      </div>
    );
  },
};
