<template>
  <div v-if="overlay == 'cookie'" class="overlay-cookies">
    <div class="wrapper">
      <div class="close" @click="closeOverlay()">
        <span><i /><i /></span>
      </div>
      <div class="inner">
        <p>
          We use cookies on our website to give you the best service possible
          and improve site quality. Before continuing to browse our website,
          please read and agree to our
          <a href="/privacy">privacy and cookie policy</a> by clicking
          <a href="#" @click="closeOverlay()">accept.</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Vuex from 'vuex'

export default {
  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
    }),
  },
  data() {
    return {
      eu: false,
    }
  },
  mounted() {
    const isAccepted = this.checkAccepted()
    if (!isAccepted) this.$store.commit('overlay/SET_OVERLAY', 'cookie')
  },
  methods: {
    checkAccepted() {
      if (JSON.parse(localStorage.getItem('cookies_accepted'))) {
        if (!this.checkExpiration()) return true
      }
      return false
    },

    checkExpiration() {
      const dateNow = Date.now()
      const yearMs = 1000 * 60 * 60 * 24 * 365
      const dateAccepted = JSON.parse(localStorage.getItem('cookies_time'))

      if (dateNow - dateAccepted >= yearMs) {
        localStorage.setItem('cookies_accepted', false)
        localStorage.setItem('cookies_accepted', false)
        localStorage.removeItem('cookies_time')
        return true
      }
      return false
    },

    closeOverlay() {
      localStorage.setItem('cookies_accepted', true)
      localStorage.setItem('cookies_time', Date.now())
      this.$store.commit('overlay/SET_OVERLAY', '')
      this.accepted = true
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/base';

.overlay-cookies {
  @include cx-xs();

  order: 2;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  background: $banner;
  color: $dark;

  .wrapper {
    height: 100%;
    margin: 0 $inset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .close {
      padding: 10px;
      align-self: center;
      padding: 20px;
      transform: translate(20px, -10px);
      cursor: pointer;
      span {
        display: block;
        width: 15px;
        position: relative;
        i {
          width: 100%;
          height: 1px;
          background: #000;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          &:last-child {
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
    .inner {
      order: -1;
      max-width: 920px;
      padding: 10px 0;
      padding-right: 120px;
    }
  }
  a,
  span.accept {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -2px;
      border-bottom: 1px solid $dark;
    }
  }
  span.accept {
    &:after {
      bottom: 0;
    }
  }

  @media screen and (max-width: 720px) {
    /* position: static; */
    /* bottom: auto; */
    /* top: 45px; */
    .wrapper {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      margin: 0;
      padding-top: 15px;

      .inner {
        padding: 0 40px 20px 25px;
      }

      .close {
        align-self: auto;
        padding-top: 10px;
        padding-right: 25px;
        transform: none;
      }
    }
  }
}
</style>
