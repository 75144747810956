// TODO: Break renderers into separate components

import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import ContentfulEntryBlockParser from '../diary/contentful-entry-block-parser'
// import MediaParser from '../diary/contentful-media-parser';
// import ContentfulAsset from '~/components/common/contentful-asset';

// TODO: Loop over modifiers?
const CtfHeading = (variant) => ({ content }, key, h, next) =>
  h(
    'h2',
    { key, staticClass: `ctf-doc__h ctf-doc__h--${variant}` },
    next(content, key, h, next)
  )

export default {
  name: 'ContentfulDocument',

  components: {
    ContentfulEntryBlockParser,
    RichTextRenderer,
  },

  data() {
    return {
      renderNodes: {
        [BLOCKS.EMBEDDED_ASSET]: (node, key, h) => console.log('node', node),
        // [BLOCKS.EMBEDDED_ASSET]: ({ data }, key, h) => h(MediaParser, { key, props: { media: { fields: data.fields.target }, transforms: 'w=2000' } }),
        [BLOCKS.EMBEDDED_ENTRY]: ({ data }, key, h) =>
          h(ContentfulEntryBlockParser, {
            key,
            props: { entry: data.target, showCaption: true },
          }),
        [BLOCKS.HEADING_2]: CtfHeading('l--centred'),
        [BLOCKS.HEADING_3]: CtfHeading('m--centred'),
        [BLOCKS.HEADING_4]: CtfHeading('s'),
        [BLOCKS.HEADING_5]: CtfHeading('s--lowercase'),
        [BLOCKS.PARAGRAPH]: ({ content }, key, h, next) =>
          h(
            'p',
            { key, staticClass: 'ctf-doc__p' },
            next(content, key, h, next)
          ),
        [BLOCKS.QUOTE]: ({ content }, key, h, next) =>
          h(
            'blockquote',
            { key, staticClass: 'ctf-doc__bq' },
            next(content, key, h, next)
          ),
        [BLOCKS.UL_LIST]: ({ content }, key, h, next) =>
          h(
            'ul',
            { key, staticClass: 'ctf-doc__ul' },
            next(content, key, h, next)
          ),
        [BLOCKS.OL_LIST]: ({ content }, key, h, next) =>
          h(
            'ol',
            { key, staticClass: 'ctf-doc__ol' },
            next(content, key, h, next)
          ),
        [BLOCKS.LIST_ITEM]: ({ content }, key, h, next) =>
          h(
            'li',
            { key, staticClass: 'ctf-doc__li' },
            next(content, key, h, next)
          ),
        [INLINES.ASSET_HYPERLINK]: (node, key, h, next) => (
          <a
            href={node.data.target.fields.file.url}
            target="_blank"
            class="ctf-doc__asset"
          >
            {next(node.content, key, h, next)}
          </a>
        ),
      },
      renderMarks: {
        [MARKS.CODE]: (text, key, h) => h('em', { key }, text),
      },
    }
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  template: /* html */ `
    <div class="ctf-doc">
      <RichTextRenderer
        :document="document"
        :nodeRenderers="renderNodes"
        :markRenderers="renderMarks"
      />
    </div>
  `,
}
