export default {
  accept:false,
  loading:false,
  state: "",
  country_list: [],
  shipping_as_billing: true,
  address_shipping: {
    // Format: [Value, Error (, Computed)]
    first_name: ['',''],
    last_name: ['',''],
    address1: ['',''],
    address2: ['',''],
    city: ['',''],
    country: ['',''],
    region: ['',''],
    zip: ['',''],
    phone: ['',''],
    region_list: [],
    id: [null]
  },
  address_billing: {
    // Format: [Value, Error (, Computed)]
    first_name: ['',''],
    last_name: ['',''],
    address1: ['',''],
    address2: ['',''],
    city: ['',''],
    country: ['',''],
    region: ['',''],
    zip: ['',''],
    phone: ['',''],
    region_list: [],
    id: [null]
  },
  giftcard: ['',''],
  shipping_rates: {},
  payment_methods: {},  
  giftwrapped: false,
  selected_payment_type: false,
  coupon_code: ['',''],
  payment: {
    form: {
      firstName: {val: "", err: ""},
      lastName: {val: "", err: ""},
      number: {val: "", err: ""},
      expiry: {val: "", err: "", valCalc: ""},
      cvv: {val: "", err: ""},
      month: {valCalc: ""},
      year: {valCalc: ""},
    },
    card_type: "",
    has_errors: false,
    errors_html: '',
    is_processing: false
  },
  t: {
    bill_address: "Billing Address",
    ship_address: "Shipping Address"
  }
}