import Base from '../base';

export default class Default extends Base {
  init() {
    if(this.d.body_id !== 'sizing-index') return;
    
    radio('vue/loaded').subscribe(() => { this.setup(); });
    radio('conversion/unit').subscribe(() => { this.updateSystem(); });
  }

  setup() {
    this.s.sizing.$slider = $('[data-el="step-slider"]');

    // Preload images:
    // this.s.sizing.cover_images.forEach(url => {
    //   const img = new Image();
    //   img.src = `assets/${url}`;
    // });
  }

  updateSystem(d) {
    this.s.sizing.system = 'imperial';
  }
} 