import Get from './ajax/get';
import Post from './ajax/post';

export default class Ajax {
  constructor(utils) {
    this.get  = new Get(this);
    this.post = new Post(this);
    
    this.csrf_token = utils.m.data.csrf_token;
  }

  req(opts) {
    var defaults = {
      headers : {
        //'X-API-Token' : utils._api_key
        'X-CSRF-Token' : window._data.csrf_token
      },
      dataType : 'json',
      contentType: 'application/json',
      error : (d) => {
        if(typeof(opts.error) == 'function') opts.error(d);
        // var json = $.parseJSON(d.responseText);
        // for(var key in json.errors) {
        //   _.each(json.errors[key], (v,i) => {
        //     var name = _.capitalize(key);
        //     toastr.error(v,name, {timeOut: 20000})
        //   });
        // }
      },
      success : (data) => {
        if(typeof(opts.callback) == 'function') opts.callback(data);
      }
    };

    var settings = $.extend(true, defaults, {
      url : opts.url,
      data : opts.data,
      method: (typeof(opts.method) == 'string') ? opts.method : 'GET',
      dataType : (typeof(opts.dataType) == 'string') ? opts.dataType : 'JSON'
    });


    settings.data = JSON.stringify(settings.data);

    //Call url
    $.ajax(settings); 
  }

  submit(path, params={}, method='post'){
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    //CSRF & Response Format
    params.format = 'html';
    params.authenticity_token = window._data.csrf_token;

    for(var key in params) {
        if(params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
  }
}