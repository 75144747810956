
import ContentfulDocument from '~/components/contentful-document';

export default {
  name: 'diary-article',

  props: {
    entry: {
      type: Object,
      required: true,
    },
  },

  mounted() {
  },

  template: /* html */ `
    <div class="article">
      <contentful-document :document="entry.fields.text_block"></contentful-document>
    </div>
  `,
  components: {
    ContentfulDocument
  }
};
