import request from './request';

const { get: apiGet, post: apiPost } = request;

async function get() {
  return apiGet({
    url: '/api/v1/cart',
  });
}

async function add({ variant_id, quantity = 1 }) {
  return apiPost({
    url: '/cart/add.json',
    data: {
      variant_id,
      quantity,
    },
  });
}

async function remove({ variant_id, quantity = 1 }) {
  return apiPost({
    url: '/cart/remove.json',
    data: {
      variant_id,
      quantity,
    },
  });
}

async function related({ variant_id }) {
  return apiPost({
    url: '/cart/related.json',
    data: {
      variant_id,
    },
  });
}

async function refresh() {
  return apiGet({
    url: '/feed/cart.json',
  });
}

async function instructions({ special_instructions }) {
  return apiPost({
    url: '/cart/message.json',
    data: {
      special_instructions,
    },
  });
}


export default {
  get,
  add,
  remove,
  related,
  refresh,
  instructions,
};
