export default {
  getToken: (stripe_key, data) => {
    Stripe.setPublishableKey(stripe_key);

    var params = {
      name: `${data.firstName.val} ${data.lastName.val}`,
      number: data.number.val,
      cvc: data.cvv.val,
      exp_month: data.month.valCalc,
      exp_year: data.year.valCalc,
    }

    return new Promise((resolve, reject) =>{
      Stripe.card.createToken(params, (status, result) => {
        
        if(status == 200) {
          resolve({
            status: status,
            result: result
          })
        } else {
          reject({
            status: status,
            result: result
          })
        }
      });
    });
  },
};