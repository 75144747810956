import Base from '../base';

export default class Default extends Base {
  init() {
    radio('conversion/unit').subscribe(() => { this.updateSystem(); });
  }

  updateSystem(d) {
    this.s.sizing_chart.system = 'imperial';
  }
} 