// ARRAY

export const filterDistinct = (x, i, array) => array.indexOf(x) === i

export const findById = (collection) => (id) =>
  collection.find((item) => item.id === id)

export const sortAscending = (a, b) => a - b

export const sortDescending = (a, b) => b - a

export const uniqueSets = (array) => {
  const set = new Set(array.map(JSON.stringify))
  return Array.from(set).map(JSON.parse)
}

// PRODUCT VARIANTS

export const orderableStock = (warehouse) => (stockItem) =>
  stockItem.is_orderable && stockItem.stock_location === warehouse

export const variantHasOrderableStock = (warehouse) => (variant) =>
  variant.stock_items.some(orderableStock(warehouse))
