// General
import ContentfulDocument from '~/components/contentful-document'
import ImageLoader from '~/components/common/image-loader'
import Newsletter from '~/components/newsletter'
import NotificationBanner from '~/components/notification-banner'
import Togglable from '~/components/togglable'
import PriceFormat from '~/components/prices/price-format'

// Checkout
import CheckoutDeliveryRate from '~/components/checkout/checkout-delivery-rate'
import CheckoutDutiesConfirmation from '~/components/checkout/checkout-duties-confirmation'
import CheckoutTextInput from '~/components/checkout/checkout-text-input'

// Menus
import HeaderComponent from '~/components/header'
import MobileMenu from '~/components/mobile-menu'
import MobileLocationMenu from '~/components/header/mobile-location-menu'
import LocationMenu from '~/components/header/location-menu'
import LocationSelect from '~/components/header/location-select'
import CurrencyToggle from '~/components/header/currency-toggle'

// Content
import DiaryIndex from '~/diary/diary-index'
import DiaryArticle from '~/diary/diary-article'
import PageTabs from '~/components/page-tabs'
import SizeTabs from '~/components/size-tabs'

// Checkout
import OrderSummary from '~/components/order/summary'
import Cart from '~/components/cart'

// Overlay
import OverlayLogin from '~/components/overlay/login'
import OverlayCookie from '~/components/overlay/cookie'
import OverlayError from '~/components/overlay/error'
import OverlayFlash from '~/components/overlay/flash'
import OverlayLocation from '~/components/overlay/location'
import OverlaySearch from '~/components/overlay/search'
import OverlayCart from '~/components/overlay/overlay-cart'
import OverlayProductStores from '~/components/overlay/product-stores'
import OverlayMmenu from '~/components/overlay/mmenu'

// Product
import ProductAdd from '~/components/product/product-add'
import ProductAfterpayModal from '~/components/product/product-afterpay-modal'
import ProductBuy from '~/components/product/product-buy'
import ProductDetails from '~/components/product/product-details'
import ProductBlocks from '~/components/blocks/products'
import ProductBlock from '~/components/blocks/product'
import ProductColumn from '~/components/product/product-column'
import ProductAfterpay from '~/components/prices/product-afterpay'
import ProductPrice from '~/components/prices/product-price'
import ProductPurchase from '~/components/product/product-purchase'
import ProductSelection from '~/components/product/product-selection'

// Pages
import PageHome from '~/components/pages/page-home'
import PageGeneric from '~/components/pages/page-generic'
import PageStores from '~/components/pages/page-stores'

// Pricing
import PriceDisplay from '~/components/prices/price-display'
import Amount from '~/components/prices/amount'
import GiftPrice from '~/components/prices/gift-price'

export default {
  CheckoutDeliveryRate,
  CheckoutDutiesConfirmation,
  CheckoutTextInput,
  CurrencyToggle,
  LocationMenu,
  LocationSelect,
  MobileLocationMenu,
  ContentfulDocument,
  ImageLoader,
  MobileMenu,
  HeaderComponent,
  Newsletter,
  Togglable,
  PageTabs,
  SizeTabs,
  OrderSummary,
  Cart,
  DiaryIndex,
  DiaryArticle,
  PriceDisplay,
  Amount,
  GiftPrice,
  NotificationBanner,
  OverlayLogin,
  OverlayCookie,
  OverlayError,
  OverlayFlash,
  OverlayLocation,
  OverlaySearch,
  OverlayCart,
  OverlayProductStores,
  OverlayMmenu,
  PriceFormat,
  ProductAdd,
  ProductAfterpay,
  ProductAfterpayModal,
  ProductBuy,
  ProductColumn,
  ProductDetails,
  ProductBlock,
  ProductBlocks,
  ProductPrice,
  ProductPurchase,
  ProductSelection,
  PageHome,
  PageGeneric,
  PageStores,
}
