<template>
  <div class="input input-required input-half">
    <label>First Name</label>
    <input
      type="text"
      :required="required"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <!-- for="<%= name % -->
    <!-- id="<%= name %>_last_name" -->
    <!-- name="<%= name)) %>_first_name" -->
    <!-- class="input" -->
    <!-- :class="{ 'has-error': <%= vue_address_state %>.first_name[1].length }" -->
    <!-- @input="<%= vue_address_state %>.first_name[1] = []" -->
    <!-- <div
      v-if="<%= vue_address_state %>.first_name[1]"
      class="error-msg"
      v-text="<%= vue_address_state)) %>.first_name[1][0]"
    ></div> -->
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
