<template>
  <div class="diary-block">
    <p class="diary-block__date">
      {{ dateString }}
    </p>
    <div class="diary-block__gallery">
      <contentful-asset
        v-bind="entry.fields.featured_image.fields"
        fit="fill"
        :height="1200"
        :width="800"
      />
    </div>
    <h2 class="diary-block__title">
      {{ entry.fields.title }}
    </h2>
    <!-- {/* <p class="diary-block__category">
      TODO
    </p> */} -->
    <p class="diary-block__excerpt">
      {{ entry.fields.excerpt }}
    </p>
    <a
      :href="`/diary/${entry.fields.slug}`"
      class="diary-block__cover-link"
    >
      <span class="diary-block__cover-link-text">
        Read more
      </span>
    </a>
  </div>
</template>

<script>
import ContentfulAsset from '~/components/common/contentful-asset';

export default {
  components: {
    ContentfulAsset,
  },

  props: {
    entry: {
      type: Object,
      required: true,
    },
  },

  computed: {
    dateString() {
      const date = new Date(this.entry.fields.publish_date);
      return `${date.getDate()}.${date.getMonth()}.${String(date.getFullYear()).substr(-2)}`;
    },
  },
};
</script>

<style lang="scss">
.diary-block {
  position: relative;
  padding: 30px 50px 40px;
  font-size: 13px;
  text-align: center;
  border: 1px solid transparent;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: inherit;
  }

  &__date,
  &__title,
  &__category {
    margin-bottom: 15px;
  }

  &__title {
    font-size: 26px;
    text-transform: uppercase;
  }

  .diary-block__excerpt {
    // @include justify-center();
  }

  &__gallery {
    position: relative;
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
  }

  .entry-featured-media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__cover-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    border-color: currentColor;

    &__date {
      order: 2;
    }

    &__gallery {
      order: 1;
    }

    &__excerpt {
      order: 3;
    }
    // &:hover {
    //   border-color: transparent;
    // }
  }
}
</style>
