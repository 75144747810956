<template>
  <div>
    <section class="hero">
      <div class="description">
        <RichTextRenderer :document="content" />
      </div>
    </section>
    <page-tabs named="store" :tabs="page.tabs" />
  </div>
</template>

<script>
import RichTextRenderer from '../contentful/contentful-rich-text'
import PageTabs from '~/components/page-tabs'

export default {
  components: {
    PageTabs,
    RichTextRenderer,
  },

  props: ['content'],

  data() {
    return {
      page: {
        body: /* html */ `<div>
        We offer full service fittings that can be adapted to a level of comfort and privacy that suits individual needs. Appointments are welcomed before and after hours for individuals and groups. As not everything on <a href="//lonelylabel.com">lonelylabel.com</a> is available in our stores, please contact us prior to your visit and our teams can ensure we source the items you need.
        </div>`,
        tabs: [
          {
            title: 'Ponsonby',
            body: /* html */ `
              <div>
                130 Ponsonby Rd (Entrance on Mackelvie St), Auckland, New Zealand<br>&nbsp;
                Tel +64 27 376 1575, <a href="mailto:ponsonby@lonelylabel.com">ponsonby@lonelylabel.com</a><br>
                Open 10am - 5pm
              </div>`,
            image: '/static/stores/4-6738-PCL-35.jpg',
            gallery: [
              '/static/stores/1-6738-PCL-12.jpg',
              '/static/stores/2-6738-PCL-17.jpg',
              '/static/stores/3-6738-PCL-19.jpg',
              '/static/stores/5-6739-PCL-08.jpg',
            ],
            attachments: [],
          },
          {
            title: 'Wellington',
            body: /* html */ `
            <div>104 Victoria Street, Wellington, New Zealand
              <br>Tel +64 27 699 1566, <a href="mailto:wellington@lonelylabel.com">wellington@lonelylabel.com</a><br>
              Open 10am - 5pm
            </div>`,
            image: '/static/stores/wellington-1.jpg',
            gallery: [
              '/static/stores/wellington-2.jpg',
              '/static/stores/wellington-3.jpg',
            ],
          },
          {
            title: 'Melrose',
            body: /* html */ `
              <div>
                 Our Lonely Melrose Boutique is now Closed
              </div>`,
            image: '/static/stores/melrose/1.jpg',
            gallery: [
              '/static/stores/melrose/2.jpg',
              '/static/stores/melrose/3.jpg',
              '/static/stores/melrose/4.jpg',
              '/static/stores/melrose/5.jpg',
              '/static/stores/melrose/6.jpg',
              '/static/stores/melrose/7.jpg',
            ],
            attachments: [],
          },
        ],
      },
    }
  },
}
</script>
