<template>
  <div
    v-if="product"
    class="product-block"
    :class="{ 'product-block_related': related }"
  >
    <a
      class="product-block__link"
      :href="productUrl"
      :aria-label="'Shop ' + product.name"
    />

    <Gallery
      class="product-block__gallery"
      :product="product"
      :related="related"
    />

    <div class="product-block__details">
      <div class="product-block__title" v-text="product.name" />
      <div v-if="product.is_gift_card" class="product-block__price">
        <!-- <GiftPrice :prices="25" /> -->
        <!-- <GiftPrice :prices="1000" /> -->
      </div>
      <div v-else>
        <ProductPrice
          class="product-block__price"
          :prices="product.master.prices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from '~/components/blocks/product/gallery'
import ProductPrice from '~/components/prices/product-price'

export default {
  name: 'ProductBlock',

  components: {
    Gallery,
    ProductPrice,
  },

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    related: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    productUrl() {
      return this.product.taxon_url ? this.product.taxon_url : this.product.url
    },
  },

  methods: {
    wrapCssUrl(url) {
      return `url(${url})`
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/base';

.product-block {
  width: calc(50% - #{$gutter * 2});
  margin: 0 $gutter 30px;
  position: relative;

  @include respond-to(xs) {
    @include inline-span(12);
  }

  &_related {
    margin-bottom: 30px !important;
  }

  &__link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer;
  }

  &__image,
  &__gallery {
    margin-bottom: 20px;
  }

  &__details {
    @include transition(opacity 0.2s ease-out);
    @include hx();
    @include hx-xs();
    @include hx-darkestgrey();
    text-align: center;
  }

  &__price {
    @include transition(opacity 0.2s ease-out);
    opacity: 0;

    .price-format--original {
      @include cx-lightgrey();
      text-decoration: line-through;
    }

    @include respond-to(xs) {
      opacity: 1;
    }
  }

  @media (pointer: fine) {
    &:hover {
      .product-block__price {
        opacity: 1;
      }

      .product-block-gallery--ready {
        :last-child,
        :only-child {
          opacity: 1;
        }
      }
    }
  }
}
</style>
