export default {
  showClass: state => {
    return [state.panel.active_class, state.panel.leaving_class];
  },

  formatAttachmentShortcodes(body, attachments) {
    var regex = /(.*)\[attachment\/([0-9])\](.*)/;

    while (body.match(regex)) {
      var attachment_index = parseInt(body.match(regex)[2]);
      var attachment = attachments[attachment_index - 1];
      var link = !!attachment
        ? `<a href="${attachment.url}" target="_blank">here</a>`
        : "";

      body = body.replace(regex, `$1${link}$3`);
    }

    return body;
  }
};
