export default {
  name: 'togglable',

  props: {
    name: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
    },
    initialState: {
      type: Boolean,
      default: false,
    },
    activeSibling: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      active: this.initialState,
      contentHeight: 0,
    };
  },

  computed: {
    wrapperHeightStyle() {
      return this.contentHeight > 0
        ? `${this.contentHeight}px`
        : '';
    },
  },

  watch: {
    active(isActive) {
      this.$emit('toggle', {
        name: this.name,
        active: isActive,
      });
      // Use a method like this to manage the siblings:
      // setActiveCategory(newCategory) {
      //   if (newCategory.active) {
      //     this.activeCategory = newCategory.name;
      //   }
      // }
    },

    activeSibling(sibling) {
      if (sibling !== this.name) this.active = false;
    },
  },

  methods: {
    toggleActive() {
      this.active = !this.active;
    },

    setContentHeight(el) {
      this.contentHeight = this.active
        ? el.offsetHeight
        : 0;
    },
  },

  template: /* html */ `
    <component
      :is="tag"
      aria-haspopup="true"
      :aria-expanded="active ? 'true' : 'false'"
      class="togglable"
      :class="{ 'togglable--active': active }"
    >
      <button
        class="togglable__button"
        @click="toggleActive"
      >
        <slot
          name="button"
          :active="active"
        ></slot>
      </button>
      <div
        class="togglable__wrapper"
        :style="{ height: wrapperHeightStyle }"
      >
        <transition
          name="togglable"
          @enter="setContentHeight" 
          @leave="setContentHeight" 
          >
          <div
            class="togglable__content"
            v-show="active"
          >
            <slot></slot>
          </div>
        </transition>
      </div>
    </component>
  `,
};
