import _ from 'lodash'

export default {
  showStepClass: (state, step) => {
    return state.cart.state === step ? '' : 'disabled-step'
  },
  getStates: (data, state, name) => {
    const country_id = state.checkout[`address_${name}`].country[0]
    const country = _.find(data.countries, { id: country_id })

    if (country && country.states_required && country.states_required == true) {
      const states = _.filter(data.states, { country_id: country.id })

      if (states) {
        return _.map(states, (v) => {
          return {
            value: v.id,
            text: v.name,
          }
        })
      }
    }

    return []
  },
  formatShippingRates: (d) => {
    const r = [...(d.order.shipments || [])].map((v) => {
      const manifest = v.manifest.map((_v) => {
        return {
          quantity: _v.quantity,
          name: _v.variant.name,
          options: _v.variant.options_text,
        }
      })

      const rates = v.shipping_rates.map((_v) => {
        return {
          cost: _v.cost,
          id: _v.id,
          name: _v.name,
        }
      })

      return {
        shipment_id: v.id,
        location: v.stock_location,
        rates,
        manifest,
      }
    })
    return r
  },
  formatPaymentMethods(d, s, v) {
    const order_warehouse = d.order.warehouse
    const order_currency = d.order.currency
    return _.filter(d.order.payment_methods, (pm) => {
      let valid = true

      // Only show giftcard for NZD
      if (pm.method_type == 'giftcard' && s.currency.current != 'NZD')
        valid = false

      // Todo: This but in backend

      // Apply warehouse preferences
      if (pm.warehouse_preference != '') {
        const warehouses = pm.warehouse_preference.split(',')
        const warehouse_match = _.find(warehouses, (w) => {
          return w.trim() === order_warehouse
        })

        if (!warehouse_match) valid = false
      }

      // Apply currency preferences
      if (pm.currency_preference != '') {
        const currencies = pm.currency_preference.split(',')
        const currency_match = _.find(currencies, (c) => {
          return c.trim() === order_currency
        })

        if (!currency_match) valid = false
      }

      return valid
    })
  },
  isPaymentType: (s, type = '') => {
    const payment_type = s.checkout.selected_payment_type

    return payment_type === type
  },
  canSubmitPayment: (s) => {
    const payment_type = s.checkout.selected_payment_type

    return payment_type !== 'paypal' && payment_type !== 'afterpay'
  },
  showSpinnerClass: (s, type = '') => {
    const { loading } = s.checkout
    const current_state = s.checkout.state

    return {
      loading: type === current_state && loading,
    }
  },
  showSpinner: (s, type = '') => {
    const { loading } = s.checkout
    const current_state = s.checkout.state

    return type !== current_state || (type === current_state && loading)
  },
  sortCountriesAlphabetically(countries) {
    return countries.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
    })
  },
  showSpinnerHtml: () => {
    return `
    <div class="progress-inner">
    <div class="indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
    </div>`
  },
  showPopularCountries(countries) {
    return countries.filter(
      (country) =>
        country.iso === 'NZ' || country.iso === 'AU' || country.iso === 'US'
    )
  },
}
