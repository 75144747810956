export default {
  formatBraChart: (state,system) => {
    var chart = state.sizing_chart.bra;

    var new_chart = _.map(chart.bands, (band_raw,band_index) => {
      var band = {
        measurement: band_raw[system],
        cups   : _.map(band_raw.cups, (cup_raw, cup_index) => {
          return {
            cup: cup_raw.cup,
            measurement: cup_raw[system]
          };
        })
      };
      return band;
    });
    return new_chart;
  },
  formatBriefChart: (state,system) => {
    var chart = state.sizing_chart.briefs;

    var new_chart = _.map(chart, (brief_raw,band_index) => {
      return {
        title: brief_raw.title,
        measurement: brief_raw[system],
        sizes   : _.map(brief_raw.sizes, (size_raw, cup_index) => {
          return {
            size: size_raw.size,
            measurement: size_raw[system]
          };
        })
      };;
    });
    return new_chart;
  }
}