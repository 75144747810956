<template>
  <div class="overlay-cart__total">
    Total –
    <price-display :prices="[{ price: total }]" class="cart__total" />
    <div class="overlay-cart__note">
      <p v-if="currencyDisplay === 'AUD'" class="overlay-cart__gst">
        Prices include 10% AUS GST
      </p>
    </div>
    <free-shipping-note :currency="currencyDisplay" />
  </div>
</template>

<script>
import PriceDisplay from '~/components/prices/price-display'
import currency from '../../../mixins/currency'
import FreeShippingNote from '../../free-shipping-note.vue'

export default {
  components: {
    FreeShippingNote,
    PriceDisplay,
  },

  mixins: [currency],

  props: {
    total: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/base';

.overlay-cart {
  &__total {
    @include hx-style1();

    margin-bottom: 30px;
    text-align: center;

    .note {
      @include cx-xs();
      @include sans();

      text-transform: none;
      color: $lightgrey;
      margin: 0 auto;
      letter-spacing: 0;
      line-height: 1.4;
    }
  }
}
</style>
